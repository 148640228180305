import { useReactiveVar } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { isLoggingInVar, clientVar, isAdvisorVar } from '~/apollo';
import useAuth0 from '~/shared/hooks/useAuth0';
import { useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';

interface Props {
  children: JSX.Element;
  isAdvisorRoute?: boolean;
}

const AuthRouteHandler = ({ children, isAdvisorRoute }: Props) => {
  const isLoggingIn = useReactiveVar(isLoggingInVar);
  const client = useReactiveVar(clientVar);
  const isAdvisor = isAdvisorVar();
  const { handleLoggedInUser } = useAuth0();

  useEffect(() => {
    if (!client) {
      handleLoggedInUser();
    }
  }, []);

  if (isLoggingIn) {
    return <LoadingSpinner />;
  }

  if (!client) {
    return <Navigate to={'/'} />;
  }

  if (isAdvisorRoute && !isAdvisor) {
    return <Navigate to={'/'} />;
  }

  return children;
};

export default AuthRouteHandler;
