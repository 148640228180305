import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  Typography,
  styled,
  Box,
  Stack,
  Button,
  DialogActions,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import {
  activeGrantModalVar,
  isAdvisorVar,
  selectedNonProfitVar,
} from '~/apollo';
import { CARD_IMAGES } from '~/shared/constants';
import { theme } from '~/shared/styles/theme';
import Employees from './Employees';
import Age from './Age';
import AnnualBudget from './AnnualBudget';
import Categories from './Categories';
import CloseIcon from '~/assets/icons/CloseIcon';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NonProfitDetailModal = ({ isOpen, onClose }: Props) => {
  const selectedNonProfit = useReactiveVar(selectedNonProfitVar);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isAdvisor = useReactiveVar(isAdvisorVar);

  const isClient = !isAdvisor;

  if (!selectedNonProfit) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isSmall}>
      <TopImage
        src={
          CARD_IMAGES[selectedNonProfit.cardImage.subjectCode][
            selectedNonProfit.cardImage.index
          ]
        }
      />
      <CloseAction onClick={onClose}>
        <CloseIcon />
      </CloseAction>
      {selectedNonProfit.imageUrl && (
        <NonProfitImage
          src={selectedNonProfit.imageUrl}
          alt={selectedNonProfit.title}
        />
      )}
      <Title textAlign="center">{selectedNonProfit.title}</Title>
      <Description>
        <Typography textAlign="center" fontSize="15px" lineHeight={1}>
          {selectedNonProfit.description}
        </Typography>
      </Description>
      {selectedNonProfit.link ? (
        <Box mx="auto" mb={theme.spacing(4.5)}>
          <ViewWebsiteLink
            href={selectedNonProfit.link}
            target="_blank"
            rel="noreferrer"
          >
            View Website
          </ViewWebsiteLink>
        </Box>
      ) : (
        <Box mx="auto" mb={theme.spacing(4.5)}>
          <ViewWebsiteLink sx={{ fontSize: '12px' }}>
            No Website Provided
          </ViewWebsiteLink>
        </Box>
      )}
      {isClient && (
        <Box px={theme.spacing(isSmall ? 12 : 34)} pb={theme.spacing(6)}>
          <Button
            variant="main"
            onClick={() => activeGrantModalVar('nonProfit')}
          >
            Make a Grant
          </Button>
        </Box>
      )}
      <BottomContainer py={7} px={9}>
        <Stack
          direction={isSmall ? 'column' : 'row'}
          width={isSmall ? '50%' : '100%'}
          justifyContent="space-between"
          mb={1}
        >
          <Employees />
          <Age />
          {!isSmall && <AnnualBudget />}
        </Stack>
        <Stack
          direction={isSmall ? 'column' : 'row'}
          width={isSmall ? '50%' : '100%'}
        >
          {isSmall && <AnnualBudget />}
          <Categories />
        </Stack>
      </BottomContainer>
    </Dialog>
  );
};

export default NonProfitDetailModal;

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    width: '465px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: 0,
    },
  },
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  paddingBottom: theme.spacing(1),
}));

const Description = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 13),
  marginBottom: theme.spacing(3),
  overflow: 'auto',
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.custom.lightGray,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}));

const ViewWebsiteLink = styled('a')({
  color: 'inherit',
  fontWeight: 500,
  fontSize: '14px',
});

const TopImage = styled('img')(({ theme }) => ({
  height: '126px',
  objectFit: 'cover',
  marginBottom: theme.spacing(5.5),
}));

const NonProfitImage = styled('img')({
  maxWidth: '174px',
  maxHeight: '64px',
  margin: '0 auto',
});

const CloseAction = styled(DialogActions)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  cursor: 'pointer',
}));
