import { gql } from '../gen';

export const GET_CLIENTS_QUERY = gql(/* GraphQL */ `
  query GetClients($input: AdvisorClientsInput!) {
    getClients(input: $input) {
      clients {
        id
        externalClientId
        firstName
        lastName
        balanceMajorUnits
        email
        DAFAccounts {
          id
          accountName
          externalAccountId
          favorites {
            id
            ein
          }
          totalBalanceMajorUnits
          totalGrantsMajorUnits
          totalGrantsYTDMajorUnits
        }
      }
      matchingClientCount
    }
  }
`);

export const GET_CLIENTS_TOTALS_QUERY = gql(/* GraphQL */ `
  query GetClientsTotals($input: GetClientsTotalsInput!) {
    getClientsTotals(input: $input) {
      totalAccountsBalanceMajorUnits
      totalAccountsGrantsMajorUnits
      totalAccountsGrantsYTDMajorUnits
      numberOfClients
    }
  }
`);

export const GET_CLIENT_DASHBOARD_QUERY = gql(/* GraphQL */ `
  query GetClientDashboard($input: GetClientDashboardInput!) {
    getClientDashboard(input: $input) {
      accountBalanceMajorUnits
      grantsTotalMajorUnits
      grantsYTDMajorUnits
      favoritesCount
      topFavoriteCategories
      recurringGrantsCount
      grantChartData {
        label
        value
      }
    }
  }
`);
