import { useQuery, useReactiveVar } from '@apollo/client';
import { Grid, Stack, styled } from '@mui/material';
import { activeDAFIdVar } from '~/apollo';
import { GET_CLIENT_DASHBOARD_QUERY } from '~/graphql/queries/clients';
import WelcomeSection from './components/sections/WelcomeSection';
import AccountSection from './components/sections/AccountSection';
import StatsSection from './components/sections/StatsSection';
import CategoriesCard from './components/cards/Categories/CategoriesCard';
import DashboardSkeleton from './components/DashboardSkeleton';

const Dashboard = () => {
  const activeDAFId = useReactiveVar(activeDAFIdVar);

  if (!activeDAFId) {
    return null;
  }

  const { data, loading, error } = useQuery(GET_CLIENT_DASHBOARD_QUERY, {
    variables: {
      input: {
        dafAccountId: activeDAFId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <DashboardSkeleton />;
  }

  //TODO: (Ticket: BRI-273) Handle error state
  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        <WelcomeSection />
        <AccountSection data={data} />
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={8} display="flex">
          <StatsSection data={data} />
        </Grid>

        <Grid item xs={12} md={4}>
          <CategoriesCard data={data} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 6.5, 6.5, 6.5),
  backgroundColor: theme.palette.custom.lightGray,
  gap: theme.spacing(7.5),
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));
