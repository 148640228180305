import { CardContent, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { Maybe } from '~/graphql/gen/graphql';

interface Props {
  title: string;
  imageUrl?: Maybe<string>;
}

const Content = ({ title, imageUrl }: Props) => {
  const [imgErrored, setImgErrored] = useState(false);
  return (
    <Container>
      <ImageContainer>
        {!imgErrored && imageUrl && (
          <Image
            src={imageUrl}
            alt={title}
            onError={() => setImgErrored(true)}
          />
        )}
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
    </Container>
  );
};

export default Content;

const Container = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: theme.spacing(3),
}));

const TitleContainer = styled(Stack)({
  flexGrow: 1,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center',
  paddingY: '0',
  lineHeight: '14px',
  alignSelf: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.custom.darkTeal,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

const ImageContainer = styled(Stack)(({ theme }) => ({
  maxHeight: '72px',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '45px',
  },
}));

const Image = styled('img')(({ theme }) => ({
  maxWidth: '174px',
  maxHeight: '72px',
  display: 'block',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '45px',
    maxWidth: '135px',
  },
}));
