const CaretDownIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 18A8.5 8.5 0 0 0 18 9.5 8.5 8.5 0 1 0 9.5 18z"
        stroke="#3B2640"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.325 8.225 9.5 12.05 5.675 8.225"
        stroke="#3B2640"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CaretDownIcon;
