import { Box, Stack, Typography, styled } from '@mui/material';
import { theme } from '~/shared/styles/theme';

interface Props {
  totalBalance: string;
}

const BalanceCircle = ({ totalBalance }: Props) => {
  return (
    <Container>
      {totalBalance && (
        <Stack>
          <Typography
            variant="h6"
            align="center"
            fontWeight="bold"
            color={theme.palette.custom.darkTeal}
            mb={2}
          >
            {totalBalance}
          </Typography>
          <Typography
            fontSize="14px"
            color={theme.palette.custom.darkTeal}
            textAlign="center"
            px={6}
            lineHeight={1}
          >
            Total Account Balance
          </Typography>
        </Stack>
      )}
    </Container>
  );
};

export default BalanceCircle;

const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  height: 200,
  borderRadius: '50%',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
