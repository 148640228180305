import { Stack } from '@mui/material';
import { CATEGORIES } from '~/shared/constants';
import Categories from './components/Categories';
import NonProfitsList from './components/NonProfitsList';

const NonProfits = () => {
  return (
    <Stack>
      <Categories categories={CATEGORIES} />
      <NonProfitsList />
    </Stack>
  );
};

export default NonProfits;
