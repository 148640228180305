import { forwardRef } from 'react';
import { CSSProperties } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  label?: string;
  error?: Record<string, unknown>;
  name: string;
  isDisabled?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  requirements?: RegisterOptions;
  value?: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  options: { value: string; label: string }[];
};

const SelectInput = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      error,
      name,
      margin,
      width,
      fullWidth,
      variant,
      isDisabled,
      options,
      value,
      onChange,
    },
    ref,
  ) => {
    const errorMessage =
      typeof error?.message === 'string' ? error.message : '';

    return (
      <FormControl
        variant={variant}
        sx={{ width, margin }}
        error={Boolean(error)}
        fullWidth={fullWidth}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          inputRef={ref}
          name={name}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          label={label}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {errorMessage && (
          <Typography color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  },
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
