import {
  Button,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tealBlobVertical } from '~/assets/images';
import { ROUTES } from '../constants';

const NotFound = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <Container>
      <TealBlob src={tealBlobVertical} />
      <Title variant="h2">
        Oops!{isMobile && <br />} This page doesn’t exist.
      </Title>
      <Copy lineHeight={1}>
        It looks like you’ve reached a page that isn’t available. You can
        refresh and try again or return to the dashboard.
      </Copy>
      <DashboardButton
        variant={'main'}
        onClick={() => navigate(ROUTES.DASHBOARD, { replace: true })}
      >
        Go to Dashboard
      </DashboardButton>
    </Container>
  );
};

export default NotFound;

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  background: theme.palette.custom.lightGray,
  paddingLeft: theme.spacing(13),
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 123px)',
    paddingTop: theme.spacing(13),
    paddingLeft: theme.spacing(0),
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 117.5px)',
    paddingTop: theme.spacing(10),
  },
}));

const TealBlob = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0),
  right: theme.spacing(0),
  width: 417,
  zIndex: theme.zIndex.min,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    maxHeight: '50vh',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginLeft: 0,
    fontSize: 24,
    textAlign: 'center',
  },
}));

const Copy = styled(Typography)(({ theme }) => ({
  width: 350,
  lineHeight: 1,
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    maxWidth: 320,
    width: '90%',
    marginLeft: 0,
    textAlign: 'center',
  },
}));

const DashboardButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  maxWidth: 124,
  color: theme.palette.custom.teal,
  background: theme.palette.common.white,
  border: 'none',
  [theme.breakpoints.down('md')]: {
    maxWidth: 190,
    padding: theme.spacing(2, 3),
  },
  '&:hover': {
    background: theme.palette.common.white,
  },
}));
