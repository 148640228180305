import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  variants: [
    {
      props: { variant: 'main' },
      style: ({ theme }) => {
        return {
          textTransform: 'none',
          width: '100%',
          fontSize: '11px',
          fontWeight: 'bold',
          borderRadius: '100px',
          padding: theme.spacing(3.5, 7),
          backgroundColor: theme.palette.custom.purple,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.custom.purpleHover,
          },
          '&:hover > *': {
            opacity: 1, // Ensure child elements maintain their opacity on hover
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2.5, 4),
          },
        };
      },
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => {
        return {
          textTransform: 'none',
          color: theme.palette.custom.teal,
          backgroundColor: theme.palette.common.white,
          border: `2px solid ${theme.palette.custom.teal}`,
          borderRadius: theme.shape.customBorderRadius['100'],
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '12px',
          '&:hover': {
            border: `2px solid ${theme.palette.custom.teal}`,
            boxShadow: 'none',
          },

          '&:disabled': {
            cursor: 'not-allowed',
          },
        };
      },
    },
    {
      props: { variant: 'tableFilter' },
      style: ({ theme }) => {
        return {
          textTransform: 'none',
          color: theme.palette.common.black,
        };
      },
    },
    {
      props: { variant: 'text' },
      style: ({ theme }) => {
        return {
          fontSize: 12,
          color: theme.palette.common.black,
          textDecoration: 'underline',
          textTransform: 'none',
          padding: 0,
        };
      },
    },
  ],
};
