import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Card from './Card/Card';
import SearchBar from '~/shared/components/SearchBar';
import { useReactiveVar } from '@apollo/client';
import {
  nonProfitsIndexVar,
  nonProfitsVar,
  selectedNonProfitVar,
  pageNumberInputVar,
  pagesRemainingVar,
  searchTermVar,
  subjectCodesVar,
  isLoadingNonProfitsVar,
  activeDAFIdVar,
  nonProfitsCoordsVar,
} from '~/apollo';
import NonProfitDetailModal from './NonProfitDetailModal/NonProfitDetailModal';
import { NonProfit, NonProfitsInput } from '~/graphql/gen/graphql';
import { NON_PROFITS_INCREMENT, SnackbarVariant } from '~/shared/constants';
import useSnackbar from '~/shared/hooks/useSnackbar';
import BackendService from '~/services/backend.service';
import SkeletonCard from './Card/SkeletonCard';
import {
  GrayContainer,
  LoadMoreProgress,
} from '~/shared/components/StyledComponents';
import useScrollObserver from '~/shared/hooks/useScrollObserver';
import LocationFilter from './LocationFilter';

const NonProfitsList = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const nonProfitsLoading = useReactiveVar(isLoadingNonProfitsVar);
  const nonProfits = useReactiveVar(nonProfitsVar);
  const subjectCodes = useReactiveVar(subjectCodesVar);
  const searchTerm = useReactiveVar(searchTermVar);
  const pageNumberInput = useReactiveVar(pageNumberInputVar);
  const nonProfitsIndex = useReactiveVar(nonProfitsIndexVar);
  const pagesRemaining = useReactiveVar(pagesRemainingVar);
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const { createSnackNotice } = useSnackbar();
  const { spacing } = useTheme();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isUnder390 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(390),
  );

  const handleGetNonProfits = async ({
    input,
    isLoadingMore,
  }: {
    input: NonProfitsInput;
    isLoadingMore: boolean;
  }) => {
    try {
      if (!isLoadingMore) {
        isLoadingNonProfitsVar(true);
      } else {
        setIsLoadingMore(true);
      }

      return await BackendService.getNonProfits(input);
    } catch (error) {
      createSnackNotice(
        'An error occurred. Check connection and try again in a few moments.',
        SnackbarVariant.Error,
      );
    } finally {
      isLoadingNonProfitsVar(false);
      setIsLoadingMore(false);
    }
    return null;
  };

  const loadMoreNonProfits = useCallback(async () => {
    if (nonProfitsIndex + NON_PROFITS_INCREMENT < nonProfits.length) {
      return nonProfitsIndexVar(nonProfitsIndex + NON_PROFITS_INCREMENT);
    }
    if (pagesRemaining === 0) {
      return nonProfitsIndexVar(nonProfits.length);
    }

    const newPageNumber = pageNumberInput + 1;
    const nonProfitsData = await handleGetNonProfits({
      input: {
        searchTerm,
        subjectCodes,
        pageNumber: newPageNumber,
        dafAccountId: activeDAFId,
        coordsString: nonProfitsCoordsVar(),
      },
      isLoadingMore: true,
    });

    if (!nonProfitsData) {
      return;
    }

    nonProfitsIndexVar(nonProfitsIndex + NON_PROFITS_INCREMENT);
    nonProfitsVar([...nonProfits, ...nonProfitsData.nonProfits]);
    pagesRemainingVar(nonProfitsData.pagesRemaining);
    pageNumberInputVar(newPageNumber);
  }, [
    searchTerm,
    nonProfitsIndex,
    subjectCodes,
    pageNumberInput,
    nonProfits,
    pagesRemaining,
    activeDAFId,
  ]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    selectedNonProfitVar(null);
  };

  const handleCardClick = (nonProfit: NonProfit) => {
    selectedNonProfitVar(nonProfit);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Clear search term on mount
    searchTermVar(null);

    const loadInitialNonProfits = async () => {
      // Reset non-profits query state
      searchTermVar(null);
      subjectCodesVar([]);
      pageNumberInputVar(1);
      const nonProfitsData = await handleGetNonProfits({
        input: {
          searchTerm: null,
          subjectCodes: null,
          pageNumber: 1,
          dafAccountId: activeDAFId,
          coordsString: nonProfitsCoordsVar(),
        },
        isLoadingMore: false,
      });
      if (!nonProfitsData) {
        return;
      }

      nonProfitsVar(nonProfitsData.nonProfits);
      pagesRemainingVar(nonProfitsData.pagesRemaining);
    };
    loadInitialNonProfits();
  }, [activeDAFId]);

  const handleSearch = async (input: NonProfitsInput) => {
    try {
      isLoadingNonProfitsVar(true);

      const nonProfitsData = await BackendService.getNonProfits(input);

      pagesRemainingVar(nonProfitsData.pagesRemaining);
      nonProfitsVar(nonProfitsData.nonProfits ?? []);
    } catch (error) {
      createSnackNotice(
        'An error occurred. Check connection and try again in a few moments.',
        SnackbarVariant.Error,
      );
    } finally {
      isLoadingNonProfitsVar(false);
    }
  };

  const handleClear = async () => {
    searchTermVar(null);
    nonProfitsIndexVar(NON_PROFITS_INCREMENT);
    await handleSearch({
      searchTerm: null,
      pageNumber: 1,
      dafAccountId: activeDAFId,
      coordsString: nonProfitsCoordsVar(),
    });
  };

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        return;
      }

      nonProfitsIndexVar(NON_PROFITS_INCREMENT);
      await handleSearch({
        searchTerm,
        subjectCodes,
        pageNumber: 1,
        dafAccountId: activeDAFId,
        coordsString: nonProfitsCoordsVar(),
      });
      pageNumberInputVar(1);
    },
    [searchTerm],
  );

  const canLoadMore =
    !isLoadingMore &&
    !nonProfitsLoading &&
    pagesRemaining > 0 &&
    nonProfits.length > 0;

  useScrollObserver({
    targetRef: loadMoreRef,
    onIntersect: loadMoreNonProfits,
    options: {
      root: null,
      rootMargin: '2px',
      threshold: 1.0,
      enabled: canLoadMore,
    },
    dependencies: [canLoadMore],
  });

  return (
    <GrayContainer>
      <AlignmentContainer>
        <TopContainer>
          <SearchBar
            handleClear={handleClear}
            handleKeyDown={handleKeyDown}
            searchTerm={searchTerm || ''}
            setSearchTerm={searchTermVar}
          />
          <LocationFilter handleSearch={handleSearch} />
        </TopContainer>
        {nonProfitsLoading && (
          <Grid container spacing={isSmall ? 3 : 5} mb={spacing(5)}>
            {Array.from({ length: NON_PROFITS_INCREMENT }).map((_, index) => (
              <Grid item key={index} xs={true}>
                <SkeletonCard />
              </Grid>
            ))}
          </Grid>
        )}
        {!nonProfitsLoading && nonProfits.length > 0 && (
          <Grid container spacing={isSmall ? 3 : 5} mb={spacing(5)}>
            {nonProfits.slice(0, nonProfitsIndex).map((nonProfit) => {
              return (
                <Grid
                  item
                  key={nonProfit.EIN}
                  xs={isUnder390 ? 12 : 6}
                  sm={true}
                >
                  <Card onCardClick={handleCardClick} nonProfit={nonProfit} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {!nonProfitsLoading && nonProfits.length === 0 && (
          <EmptyContainer>
            <Typography variant="h1" fontSize={18} mb={spacing(2)}>
              No Results Found
            </Typography>
            <EmptyCopy>
              We couldn&apos;t find any nonprofits that match your search
              criteria. Please try again.
            </EmptyCopy>
          </EmptyContainer>
        )}
        <Box ref={loadMoreRef}>{isLoadingMore && <LoadMoreProgress />}</Box>
      </AlignmentContainer>
      <NonProfitDetailModal isOpen={isModalOpen} onClose={handleModalClose} />
    </GrayContainer>
  );
};

export default NonProfitsList;

const AlignmentContainer = styled(Stack)({
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
});

const TopContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
}));

const EmptyContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing(15),
}));

const EmptyCopy = styled(Typography)({
  width: 256,
  fontSize: 12,
  textAlign: 'center',
  lineHeight: 1,
});
