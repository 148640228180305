import { Grid, Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';
import { Link } from 'react-router-dom';
import CardButton from '../../CardButton';

const EmptyState = () => {
  return (
    <Grid item xs={12}>
      <DashboardCard
        sx={{
          border: `1px solid ${theme.palette.custom.darkTeal}`,
          color: theme.palette.custom.darkTeal,
          backgroundColor: 'transparent',
          borderRadius: '10px',
          minHeight: { md: '190px', xs: '256px' },
        }}
      >
        <SectionHeading
          sx={{
            color: theme.palette.custom.desaturatedTeal,
            margin: 0,
          }}
        >
          Recurring Grants
        </SectionHeading>
        <Stack
          alignItems="center"
          justifyContent="center"
          flex={1}
          height="100%"
        >
          <Typography variant="h2" textAlign="center" mb={theme.spacing(4)}>
            Set up recurring grants to keep supporting your chosen causes!
          </Typography>
          <Link to="/non-profits">
            <CardButton
              text="View Nonprofits"
              sx={{ fontSize: '11px', backgroundColor: 'transparent' }}
            />
          </Link>
        </Stack>
      </DashboardCard>
    </Grid>
  );
};

export default EmptyState;
