import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { selectedNonProfitVar } from '~/apollo';
import CategoryIcon from '~/assets/icons/CategoryIcon';
import { getTopLevelCategories } from '~/shared/utils';

const Categories = () => {
  const selectedNonProfit = useReactiveVar(selectedNonProfitVar);

  if (!selectedNonProfit) {
    return null;
  }

  const labels = getTopLevelCategories(selectedNonProfit.categories)
    .map((category) => category?.label)
    .join(', ');

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <CategoryIcon />
      <Stack>
        <Typography fontSize="10px" fontWeight="bold">
          Categories:
        </Typography>
        <Typography textTransform={'capitalize'} fontSize="10px">
          {labels}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Categories;
