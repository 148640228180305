import { Auth0Client } from '@auth0/auth0-spa-js';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '~/shared/config';

export default new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: AUTH0_AUDIENCE,
    redirect_uri: `${window.location.origin}/dashboard`,
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
});
