export const typography = {
  fontFamily: "'Gotham', Times, 'Times New Roman', serif",
  h1: { fontSize: 28, fontWeight: 800 },
  h2: { fontSize: 24, fontWeight: 800 },
  h3: { fontSize: 20, fontWeight: 800 },
  body1: {},
  body2: {},
  times: { fontFamily: 'Times', fontSize: 14, fontStyle: 'italic' },
  legalLink: {
    fontSize: 8,
    textDecoration: 'underline',
    fontWeight: 500,
  },
  copyRight: {
    fontSize: 8,
    fontWeight: 400,
  },
  clearSearch: {
    fontSize: 8,
    fontWeight: 800,
  },
  snackbarBody: {
    fontSize: 10,
    fontWeight: 500,
  },
};
