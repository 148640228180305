import type { RouteObject } from 'react-router-dom';
import ErrorPage from '~/shared/components/ErrorPage';
import LoginPage from '~/modules/auth/LoginPage';
import InvestmentOverview from '~/modules/investmentOverview/InvestmentOverview';
import NotFound from '~/shared/components/NotFound';
import Layout from './shared/components/Layout';
import NonProfits from '~/modules/nonProfits/NonProfits';
import { ROUTES } from '~/shared/constants';
import AuthRouteHandler from '~/shared/components/AuthRouteHandler';
import Grants from '~/modules/grants/Grants';
import Transactions from '~/modules/transactions/Transactions';
import Dashboard from '~/modules/dashboard/Dashboard';
import Clients from '~/modules/advisor/Clients';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: (
          <AuthRouteHandler>
            <Dashboard />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.INVESTMENT_OVERVIEW,
        element: (
          <AuthRouteHandler>
            <InvestmentOverview />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.NON_PROFITS,
        element: (
          <AuthRouteHandler>
            <NonProfits />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.GRANTS,
        element: (
          <AuthRouteHandler>
            <Grants />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.TRANSACTIONS,
        element: (
          <AuthRouteHandler>
            <Transactions />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.TAX_DOCUMENTS,
        element: (
          <AuthRouteHandler>
            <p>Tax Documents</p>
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.DASHBOARD,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <p>Advisor Dashboard</p>
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.CLIENTS,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <Clients />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.NON_PROFITS,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <NonProfits />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.CLIENT_OVERVIEW,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <InvestmentOverview />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.CLIENT_GRANTS,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <Grants />
          </AuthRouteHandler>
        ),
      },
      {
        path: ROUTES.ADVISOR.CLIENT_TRANSACTIONS,
        element: (
          // TODO: BRI-289 Add AuthRouteHandler after it is updated
          <AuthRouteHandler isAdvisorRoute>
            <Transactions />
          </AuthRouteHandler>
        ),
      },
      {
        path: '*',
        element: (
          <AuthRouteHandler>
            <NotFound />
          </AuthRouteHandler>
        ),
      },
    ],
  },
];

export { routes };
