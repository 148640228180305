import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  messageInfoVar,
  snackbarIsOpenVar,
  snackbarMessagesVar,
} from '~/apollo';
import { SnackbarVariant } from '~/shared/constants';

const useSnackbar = () => {
  const snackbarMessages = useReactiveVar(snackbarMessagesVar);
  const messageInfo = useReactiveVar(messageInfoVar);
  const snackbarIsOpen = useReactiveVar(snackbarIsOpenVar);

  useEffect(() => {
    if (snackbarMessages.length && !messageInfo) {
      // Set a new message when we don't have an active one
      messageInfoVar(snackbarMessages[0]);
      snackbarMessagesVar(snackbarMessages.slice(1));
      snackbarIsOpenVar(true);
    } else if (snackbarMessages.length && messageInfo && snackbarIsOpen) {
      // Close an active message when a new one is added
      snackbarIsOpenVar(false);
    }
  }, [snackbarMessages.length, messageInfo, snackbarIsOpen]);

  const createSnackNotice = (message: string, variant: SnackbarVariant) => {
    const newMessageInfo = {
      message,
      key: new Date().getTime(),
      variant: variant ?? SnackbarVariant.Success,
    };
    snackbarMessagesVar([...snackbarMessages, newMessageInfo]);
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    snackbarIsOpenVar(false);
  };

  const handleExited = () => {
    messageInfoVar(undefined);
  };

  return {
    createSnackNotice,
    handleClose,
    handleExited,
    snackbarIsOpen,
    messageInfo,
  };
};

export default useSnackbar;
