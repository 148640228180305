import {
  Typography,
  styled,
  Button,
  useTheme,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {
  activeGrantModalVar,
  activeDAFIdVar,
  selectedClientVar,
} from '~/apollo';
import { PeriodEnum } from '~/graphql/gen/graphql';
import type {
  CreateGrantInput,
  AdvisorNonProfit,
  DafAccount,
} from '~/graphql/gen/graphql';
import CurrencyInput from '~/shared/components/Forms/CurrencyInput';
import SelectInput from '~/shared/components/Forms/SelectInput';
import DatePickerInput from '~/shared/components/Forms/DatePicker';
import { CREATE_GRANT } from '~/graphql/mutations/grants';
import useSnackbar from '~/shared/hooks/useSnackbar';
import DataDogService from '~/services/datadog.service';
import { isPeriodEnum, getMinorUnitsFromString } from '~/shared/utils';
import { SnackbarVariant } from '~/shared/constants';
import TextInput from '~/shared/components/Forms/TextInput';
import AdvisorNonProfitAutoComplete from './AutoComplete';
import Dialog from '~/shared/components/Dialog';

export type FormInputs = {
  nonProfit: AdvisorNonProfit | null;
  dafAccount: DafAccount | null;
  amount: string;
  frequency: CreateGrantInput['period'];
  startDate?: Date;
};

const GrantModal = () => {
  const [inputValue, setInputValue] = useState('');
  const [advisorNonProfits, setAdvisorNonProfits] = useState<
    AdvisorNonProfit[]
  >([]);
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const activeGrantModal = useReactiveVar(activeGrantModalVar);
  const selectedClient = useReactiveVar(selectedClientVar);
  const { createSnackNotice } = useSnackbar();
  const [grantComplete, setGrantComplete] = useState(false);
  const { spacing, palette } = useTheme();
  const methods = useForm<FormInputs>({
    defaultValues: {
      nonProfit: null,
      dafAccount: null,
      amount: '',
      frequency: PeriodEnum.OneTime,
      startDate: new Date(),
    },
  });
  const activeDAFAccount = selectedClient?.DAFAccounts.find(
    (account) => account.id === activeDAFId,
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = methods;

  const [createGrant, { loading }] = useMutation(CREATE_GRANT, {
    onCompleted: () => {
      createSnackNotice('GRANT HAS BEEN SENT', SnackbarVariant.Success);
      setGrantComplete(true);
    },
    update: (cache) => {
      // Invalidate to require network call new chart data
      cache.evict({ fieldName: 'getGrantChartData' });
      cache.gc();
    },
    onError: (error) => {
      createSnackNotice(
        'GRANT FAILED TO SEND, PLEASE TRY AGAIN',
        SnackbarVariant.Error,
      );
      DataDogService.logError(error);
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (!data.nonProfit) {
      // DAF account should always be selected here
      createSnackNotice(
        'Please be sure to have a nonprofit selected',
        SnackbarVariant.Error,
      );
      return;
    }

    if (!activeDAFId) {
      // DAF account should always be selected here
      createSnackNotice(
        'Please be sure to have a DAF account selected',
        SnackbarVariant.Error,
      );

      return;
    }

    const createGrantInput: CreateGrantInput = {
      address1: data.nonProfit.addressLine1 || '',
      address2: data.nonProfit.addressLine2 || '',
      city: data.nonProfit.city || '',
      country: data.nonProfit.country || '',
      state: data.nonProfit.state || '',
      zip: data.nonProfit.zip || '',
      ein: data.nonProfit.EIN,
      nonProfitName: data.nonProfit.title,
      // Convert dollars to cents and remove commas
      amountMinorUnits: getMinorUnitsFromString(data.amount),
      currencyCodeISO4217: 'USD',
      period: data.frequency,
      startDate: data.startDate || undefined,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dafAccountId: activeDAFId,
      categories: data.nonProfit.categories.map((category) => ({
        subjectCode: category.subjectCode,
        label: category.label,
      })),
    };

    createGrant({ variables: { input: createGrantInput } });
  };

  const amount = watch('amount');
  const frequency = watch('frequency');
  const startDate = watch('startDate');

  const handleFrequency = (e: SelectChangeEvent) => {
    if (!isPeriodEnum(e.target.value)) {
      return;
    }
    setValue('frequency', e.target.value);
  };

  const handleClose = () => {
    activeGrantModalVar(null);
    resetForm();

    // Reset modal state after close animation is complete
    setTimeout(() => {
      reset();
      setGrantComplete(false);
    }, 200);
  };

  const getFrequencyLabel = (frequency: PeriodEnum) => {
    switch (frequency) {
      case PeriodEnum.OneTime: {
        return 'one time';
      }
      case PeriodEnum.Monthly: {
        return 'monthly';
      }
      case PeriodEnum.Annual: {
        return 'annual';
      }
      default: {
        return 'one time';
      }
    }
  };

  const resetForm = useCallback(() => {
    reset();
    setInputValue('');
    setAdvisorNonProfits([]);
  }, [reset]);

  const grantModalIsOpen = activeGrantModal === 'advisor';

  return (
    <Dialog isOpen={grantModalIsOpen} handleClose={handleClose}>
      {grantComplete ? (
        <Stack>
          <Typography variant="h1" mb={spacing(4)}>
            Grant Sent!
          </Typography>
          <Typography textAlign="center" lineHeight={1} mb={spacing(3)}>
            Your {getFrequencyLabel(frequency)} grant has been sent.{' '}
          </Typography>
          <Button
            variant="main"
            fullWidth
            sx={{ height: 69, fontSize: '16px' }}
            onClick={handleClose}
          >
            Done
          </Button>
        </Stack>
      ) : (
        <Stack>
          <Typography variant="h1" mb={spacing(2)}>
            Make a Grant
          </Typography>
          <Typography
            textAlign="center"
            fontSize="14px"
            lineHeight={1}
            mb={spacing(6)}
          >
            Select a nonprofit to send recurring or 1 time grant.
          </Typography>

          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                name="client"
                value={`${selectedClient?.firstName} ${selectedClient?.lastName}`}
                isDisabled
                width="90%"
                margin={spacing(0, 0, 6)}
                backgroundColor={palette.custom.lightGray}
              />
              <TextInput
                name="dafAccount"
                value={`${activeDAFAccount?.accountName} ${activeDAFAccount?.externalAccountId}`}
                isDisabled
                width="90%"
                margin={spacing(0, 0, 6)}
                backgroundColor={palette.custom.lightGray}
              />
              <AdvisorNonProfitAutoComplete
                advisorNonProfits={advisorNonProfits}
                setAdvisorNonProfits={setAdvisorNonProfits}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
              <SelectInput
                requirements={{ required: 'This is a required field' }}
                label={'Frequency'}
                name="frequency"
                error={errors.frequency}
                width={'90%'}
                margin={spacing(0, 0, 6)}
                ref={
                  register('frequency', {
                    required: 'Please select a giving frequency',
                  }).ref
                }
                value={frequency}
                onChange={handleFrequency}
                options={[
                  { label: 'One Time', value: PeriodEnum.OneTime },
                  { label: 'Monthly', value: PeriodEnum.Monthly },
                  { label: 'Annual', value: PeriodEnum.Annual },
                ]}
              />
              <CurrencyInput
                label={'Amount to Grant'}
                name="amount"
                type="currency"
                error={errors.amount}
                startAdornmentSymbol="$"
                width={'90%'}
                margin={spacing(0, 0, 6)}
                ref={
                  register('amount', {
                    required: 'This is a required field',
                    validate: (value) =>
                      Number(value.replace(/(,|\.)/g, '')) > 0 ||
                      'Amount must be greater than $0.00',
                  }).ref
                }
                value={amount}
                onChange={(e) => setValue('amount', e.target.value)}
              />
              {frequency === PeriodEnum.Annual && (
                <DatePickerInput
                  label={'Start Date'}
                  name="startDate"
                  error={errors.startDate}
                  width={'90%'}
                  margin={spacing(0, 0, 6)}
                  ref={
                    register('startDate', {
                      required:
                        frequency === PeriodEnum.Annual
                          ? 'Please select a start date'
                          : false,
                    }).ref
                  }
                  value={startDate}
                  minDate={new Date()}
                  onChange={(date) => setValue('startDate', date || undefined)}
                />
              )}
              <Button
                type="submit"
                variant="main"
                fullWidth
                sx={{ height: 69, fontSize: '16px' }}
              >
                {loading ? <Progress /> : 'Submit'}
              </Button>
            </Form>
          </FormProvider>
        </Stack>
      )}
    </Dialog>
  );
};

export default GrantModal;

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: 0,
});

const Progress = styled(CircularProgress)(({ theme }) => ({
  '& .MuiCircularProgress-bar': {
    color: theme.palette.common.black,
  },
  color: 'lightgrey',
  width: 20,
}));
