import {
  TableCell as MuiTableCell,
  styled,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';

const TableHeader = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  if (isMobile) {
    return (
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Client&apos;s Name & email</TableCell>
          <TableCell>Balance</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Client&apos;s Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Balance</TableCell>
        <TableCell>Actions</TableCell>
        {/* Header space for expand icon column*/}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0, 4, 2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2),
  },
}));
