import { useCallback, useState } from 'react';
import {
  styled,
  Menu,
  MenuItem,
  Typography,
  Box,
  Button,
  PaperProps,
  useTheme,
  Fade,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { subjectCodesVar } from '~/apollo';
import { TopLevelSubjectCode } from '~/shared/constants';
import { SubCategories } from '~/modules/nonProfits/types';
import CheckBox from '~/shared/components/CheckBox';

interface Props {
  categoryRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  subCategories?: SubCategories;
  handleCategoryClick: (
    selectedSubjectCodes: TopLevelSubjectCode[],
    isDeselectClick: boolean,
  ) => Promise<void>;
  handleClose: () => void;
}

const CategoriesMenu = ({
  categoryRef,
  isOpen,
  subCategories,
  handleClose,
  handleCategoryClick,
}: Props) => {
  const TopLevelSubjectCodes = useReactiveVar(subjectCodesVar);
  const [checkedCodes, setCheckedCodes] = useState<TopLevelSubjectCode[]>(
    TopLevelSubjectCodes ?? [],
  );

  const theme = useTheme();

  const paperStyles: PaperProps['sx'] = {
    color: 'black',
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(4, 4, 7),
    width: categoryRef.current?.clientWidth,
    borderRadius: theme.shape.customBorderRadius['5'],
    border: `1px solid ${theme.palette.custom.softGray}`,
    minWidth: 265,
  };

  const preventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleMenuItemClick = useCallback(
    (subjectCodes: TopLevelSubjectCode[], isUnChecking: boolean) => {
      if (isUnChecking) {
        setCheckedCodes(
          checkedCodes.filter((code) => !subjectCodes.includes(code)),
        );
      } else {
        setCheckedCodes([...checkedCodes, ...subjectCodes]);
      }
    },
    [checkedCodes],
  );

  const checkIfChecked = useCallback(
    (subjectCodes: TopLevelSubjectCode[]) => {
      return subjectCodes.every((code) => checkedCodes.includes(code));
    },
    [checkedCodes],
  );

  const handleApplyChanges = (subjectCodes: TopLevelSubjectCode[]) => {
    subjectCodesVar(subjectCodes);
    handleCategoryClick(subjectCodes, subjectCodes.length === 0);
  };

  return (
    <Menu
      id="lock-menu"
      anchorEl={categoryRef.current}
      open={isOpen}
      onClose={handleClose}
      onClick={preventPropagation}
      TransitionComponent={Fade}
      slotProps={{
        paper: {
          sx: paperStyles,
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontWeight={700} color={theme.palette.custom.darkTeal}>
          Categories
        </Typography>
        <Button
          variant="text"
          sx={{ color: theme.palette.custom.purple }}
          onClick={() => setCheckedCodes([])}
        >
          Clear
        </Button>
      </Box>
      {subCategories &&
        subCategories.map(({ label, subjectCodes }) => {
          const isChecked = checkIfChecked(subjectCodes);
          return (
            <MenuItem
              key={label}
              sx={{
                padding: theme.spacing(1, 0),
                color: theme.palette.custom.darkTeal,
              }}
              onClick={() =>
                handleMenuItemClick(subjectCodes, checkIfChecked(subjectCodes))
              }
            >
              <CheckBox isChecked={isChecked} />
              <Typography
                color={theme.palette.custom.darkTeal}
                fontWeight={isChecked ? 800 : 'auto'}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}

      <ApplyButton
        variant="contained"
        onClick={() => handleApplyChanges(checkedCodes)}
        fullWidth
      >
        <Typography fontWeight={800}>Apply Changes</Typography>
      </ApplyButton>
    </Menu>
  );
};

export default CategoriesMenu;

const ApplyButton = styled(Button)(({ theme }) => ({
  height: 40,
  background: theme.palette.custom.darkTeal,
  padding: theme.spacing(7, 3),
  margin: theme.spacing(5, 0, 0),
  borderRadius: theme.shape.customBorderRadius['100'],
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.custom.teal,
  },
}));
