export const palette = {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    main: '#1E88E5',
    light: '#64B5F6',
    dark: '#1976D2',
  },
  text: {
    ctaGreen: '#3E5B5C',
    darkBlue: '#1E253C',
  },
  error: {
    main: '#FF3636',
  },
  custom: {
    sageGreen: '#BFC3B2',
    lightGreen: '#C4D2C1',
    lightGray: '#F4F5F2',
    mediumGray: '#7C7C7C',
    softGray: '#dadada',
    transparentGray: '#3E5B5C66',
    darkGray: '#1F1F1F',
    darkRed: '#731C3A',
    teal: '#305457',
    darkTeal: '#192A2B',
    purple: '#3B2640',
    purpleHover: '#896691',
    outlineGray: '#DBE6E7',
    gray: '#A0A0A0',
    desaturatedTeal: '#3f5b5c',
    offWhite: '#ececec',
    charcoal: '#373737',
    softBlue: '#BEDFF7',
    lightBlue: '#73A9C3',
    softBrown: '#A1968C',
    softGreen: '#BEC38A',
    softRed: '#f99BAB',
    softOrange: '#FFB44F',
    softPurple: '#CAC6D4',
    pewter: '#96A6A8',
    periwinkle: '#5E6592',
    harvestYellow: '#D3B573',
    hoverPink: '#ece2ef',
  },
};
