import { MuiButton } from './button';
import { MuiInputBase, MuiInputLabel, MuiOutlinedInput } from './form';
import { MuiSnackbarContent } from './snackbar';
import { MuiTypography } from './typography';
import { MuiTableCell } from './tableCell';

export const components = {
  MuiButton,
  MuiTypography,
  MuiSnackbarContent,
  MuiOutlinedInput,
  MuiInputBase,
  MuiInputLabel,
  MuiTableCell,
};
