import { useReactiveVar } from '@apollo/client';
import { Button, Checkbox, Menu, MenuItem, styled } from '@mui/material';
import { useState } from 'react';
import { clientVar, isAdvisorVar } from '~/apollo';
import useAuth0 from '~/shared/hooks/useAuth0';
import { SHOW_ADVISOR_CHECKBOX } from '~/shared/config';

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileOpen = Boolean(anchorEl);
  const client = useReactiveVar(clientVar);
  const isAdvisor = useReactiveVar(isAdvisorVar);
  const { logout } = useAuth0();

  const initials =
    client &&
    `${client.firstName.charAt(0)}${client.lastName.charAt(0)}`.toUpperCase();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <>
      <ProfileButton
        id="positioned-button"
        aria-controls={isProfileOpen ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isProfileOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        {initials}
      </ProfileButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={isProfileOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        {SHOW_ADVISOR_CHECKBOX && (
          <Checkbox
            checked={isAdvisor}
            sx={{
              color: '#192A2B',
              '&.Mui-checked': {
                color: '#192A2B',
              },
            }}
            onChange={(_, checked) => {
              isAdvisorVar(checked);
            }}
          />
        )}
      </Menu>
    </>
  );
};

export default Profile;

const ProfileButton = styled(Button)(({ theme }) => ({
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.text.darkBlue,
  color: theme.palette.common.white,
  width: 32,
  minWidth: 32,
  height: 32,
  borderRadius: theme.shape.customBorderRadius['100'],
  fontSize: '13px',
  fontWeight: 'normal',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.palette.text.darkBlue,
    opacity: 0.87,
  },
  [theme.breakpoints.down('md')]: {
    width: 42,
    minWidth: 42,
    height: 42,
    fontSize: '14px',
  },
  [theme.breakpoints.down('sm')]: {
    width: 32,
    minWidth: 32,
    height: 32,
    fontSize: '12px',
  },
}));
