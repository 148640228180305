import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isAdvisorVar } from '~/apollo';
import AccountSelect from '~/modules/navigation/components/AccountSelect';
import { ROUTES } from '~/shared/constants';

interface MenuProps {
  iconRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  closeMenu: () => void;
}

const MobileMenu = ({ isOpen, iconRef, closeMenu }: MenuProps) => {
  const navigate = useNavigate();
  const { spacing } = useTheme();
  const currentYear = new Date().getFullYear();
  const isAdvisor = useReactiveVar(isAdvisorVar);

  const handleClick = (route: string) => {
    navigate(route);
    closeMenu();
  };

  return (
    <Menu
      open={isOpen}
      anchorEl={iconRef.current}
      onClose={closeMenu}
      sx={{
        top: 18,
      }}
      slotProps={{
        paper: {
          style: {
            width: 275,
            padding: spacing(6),
            borderRadius: 10,
          },
        },
      }}
    >
      {!isAdvisor && <AccountSelect padding={spacing(0, 0, 4)} />}
      {isAdvisor ? (
        <Box>
          <Link onClick={() => handleClick(ROUTES.ADVISOR.DASHBOARD)}>
            Dashboard
          </Link>
          <Link onClick={() => handleClick(ROUTES.ADVISOR.CLIENTS)}>
            Clients
          </Link>
          <Link onClick={() => handleClick(ROUTES.ADVISOR.NON_PROFITS)}>
            Non Profits
          </Link>
        </Box>
      ) : (
        <Box>
          <Link onClick={() => handleClick(ROUTES.DASHBOARD)}>Dashboard</Link>
          <Link onClick={() => handleClick(ROUTES.INVESTMENT_OVERVIEW)}>
            Investment Overview
          </Link>
          <Link onClick={() => handleClick(ROUTES.NON_PROFITS)}>
            Non Profits
          </Link>
          <Link onClick={() => handleClick(ROUTES.GRANTS)}>Grants</Link>
          <Link onClick={() => handleClick(ROUTES.TRANSACTIONS)}>
            Transactions
          </Link>
        </Box>
      )}
      <Box>
        {/* TODO: (Ticket: BRI-363) Find proper route */}
        <Typography
          variant="legalLink"
          fontSize={12}
          onClick={() => console.log('WE NEED THE LINK')}
          mr={spacing(2)}
        >
          Privacy Policy
        </Typography>

        {/* TODO: (Ticket: BRI-363) Find proper route */}
        <Typography
          variant="legalLink"
          fontSize={12}
          onClick={() => console.log('WE NEED THE LINK')}
        >
          Terms & Conditions
        </Typography>
      </Box>
      <Typography variant="copyRight">
        © {currentYear} Brighton Jones. All Rights Reserved.
      </Typography>
    </Menu>
  );
};

export default MobileMenu;

const Link = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.custom.darkTeal,
  cursor: 'pointer',
  padding: theme.spacing(3, 0),
  '&:hover': {
    background: 'transparent',
  },
}));
