import { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: 'Gotham',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.custom.gray,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.custom.gray}`,
      },
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: theme.palette.custom.gray,
    }),
  },
};

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 600,
        borderRadius: theme.shape.customBorderRadius['5'],

        '&.Mui-error': {
          color: theme.palette.error.main,
        },
      };
    },
  },
};

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        color: theme.palette.custom.gray,
        fontFamily: 'Gotham',
        fontWeight: 500,
        fontSize: 15,
        whiteSpace: 'normal',
        '&.Mui-focused': {
          color: theme.palette.custom.gray,
        },
      };
    },
  },
};
