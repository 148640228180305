import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { selectedNonProfitVar } from '~/apollo';
import CheckIcon from '~/assets/icons/CheckIcon';

const Age = () => {
  const selectedNonProfit = useReactiveVar(selectedNonProfitVar);

  if (!selectedNonProfit) {
    return null;
  }

  const hasAge = selectedNonProfit.ageOfNonProfit !== null;
  const isOneYearOld = selectedNonProfit.ageOfNonProfit === 1;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <CheckIcon />
      <Stack>
        <Typography fontSize="10px" fontWeight="bold">
          Age of Non Profit:
        </Typography>
        <Typography fontSize="10px">
          {!hasAge && 'N/A'}
          {hasAge && isOneYearOld
            ? '1 year old'
            : `${selectedNonProfit.ageOfNonProfit} years old`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Age;
