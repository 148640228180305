import { Table, TableBody, TableContainer } from '@mui/material';
import TransactionsTableRow from './TransactionTableRow';
import { Transaction } from '~/graphql/gen/graphql';
import TableHeader from './TableHeader';

interface Props {
  transactions: Transaction[];
}

const TransactionsTable = ({ transactions }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          {transactions.map((transaction) => (
            <TransactionsTableRow
              key={transaction.id}
              transaction={transaction}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;
