import { Box, Stack, styled } from '@mui/material';
import Category from './Category';
import { CategoryType } from '../types';
import { useReactiveVar } from '@apollo/client';
import {
  subjectCodesVar,
  nonProfitsVar,
  searchTermVar,
  pageNumberInputVar,
  nonProfitsIndexVar,
  pagesRemainingVar,
  isLoggingInVar,
  isLoadingNonProfitsVar,
  activeDAFIdVar,
  nonProfitsCoordsVar,
} from '~/apollo';
import {
  NON_PROFITS_INCREMENT,
  SnackbarVariant,
  TopLevelSubjectCode,
} from '~/shared/constants';
import useSnackbar from '~/shared/hooks/useSnackbar';
import BackendService from '~/services/backend.service';

export interface CategoriesProps {
  categories: CategoryType[];
}

const Categories = ({ categories }: CategoriesProps) => {
  const subjectCodes = useReactiveVar(subjectCodesVar);
  const searchTerm = useReactiveVar(searchTermVar);
  const isLoggingIn = useReactiveVar(isLoggingInVar);
  const nonProfitsCoords = useReactiveVar(nonProfitsCoordsVar);
  const { createSnackNotice } = useSnackbar();

  const handleCategoryClick = async (
    selectedSubjectCodes: TopLevelSubjectCode[],
    isDeselectClick: boolean,
  ) => {
    pageNumberInputVar(1);
    nonProfitsIndexVar(NON_PROFITS_INCREMENT);

    try {
      isLoadingNonProfitsVar(true);
      if (isDeselectClick) {
        subjectCodesVar(null);
      } else {
        subjectCodesVar(selectedSubjectCodes);
      }

      const nonProfitsData = await BackendService.getNonProfits({
        searchTerm,
        subjectCodes: isDeselectClick ? null : selectedSubjectCodes,
        pageNumber: 1,
        dafAccountId: activeDAFIdVar(),
        coordsString: nonProfitsCoords,
      });
      pagesRemainingVar(nonProfitsData.pagesRemaining);

      nonProfitsVar(nonProfitsData.nonProfits);
    } catch (error) {
      createSnackNotice(
        'An error occurred. Check connection and try again in a few moments.',
        SnackbarVariant.Error,
      );
    } finally {
      isLoadingNonProfitsVar(false);
    }
  };

  if (isLoggingIn) {
    return null;
  }

  return (
    <Container direction="row">
      <CategoriesContainer>
        {categories.map((category) => (
          <Category
            key={category.label}
            category={category}
            topLevelSubjectCodes={subjectCodes}
            handleCategoryClick={handleCategoryClick}
          />
        ))}
      </CategoriesContainer>
    </Container>
  );
};

export default Categories;

const Container = styled(Stack)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  padding: theme.spacing(5),
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const CategoriesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
  },
}));
