const CloseIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      fill="none"
      {...props}
    >
      <rect width="28" height="26" fill="#3B2640" rx="13" />
      <path
        fill="#F4F5F2"
        d="M20.237 5.768a2.62 2.62 0 0 0-3.707 0L8.658 13.64a2.96 2.96 0 0 0-.772 1.354l-.87 3.386a.5.5 0 0 0 .61.608l3.385-.869a2.95 2.95 0 0 0 1.354-.772l7.872-7.872a2.619 2.619 0 0 0 0-3.707zm-3 .707a1.621 1.621 0 0 1 2.293 2.293l-.78.78-2.292-2.294.779-.779zM15.75 7.961l2.293 2.293-6.386 6.386a1.95 1.95 0 0 1-.896.51l-2.567.66.66-2.567a1.94 1.94 0 0 1 .51-.896l6.386-6.386z"
      />
    </svg>
  );
};

export default CloseIcon;
