import {
  TableContainer,
  Paper,
  Table,
  tableCellClasses,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Collapse,
  Box,
  styled,
  useMediaQuery,
  Theme,
} from '@mui/material';
import React from 'react';
import CaretDownIcon from '~/assets/icons/CaretDownIcon';
import CaretUpIcon from '~/assets/icons/CaretUpIcon';
import { Portfolio } from '~/graphql/gen/graphql';
import { palette } from '~/shared/styles/theme/palette';
import ComponentTable from './ComponentTable';
import { theme } from '~/shared/styles/theme';

interface Props {
  portfolio?: Portfolio;
  expandedRows: Record<string, boolean>;
  handleRowClick: (id: string) => void;
}

const PortfolioTable = ({ portfolio, expandedRows, handleRowClick }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  if (!portfolio) {
    return null;
  }

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table
        aria-label="collapsible table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: 'none',
            borderBottom: `1px solid ${palette.custom.lightGray}`,
          },
          [`& .${tableCellClasses.head}`]: {
            borderBottom: `1px solid ${palette.custom.mediumGray}`,
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: isMobile
                ? theme.palette.custom.lightGray
                : 'white',
            }}
          >
            <TableCell>Portfolio</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>%</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {portfolio.portfolioComponents.map((portfolioComponent) => (
            <React.Fragment key={portfolioComponent.id}>
              <TableRow
                hover
                onClick={() => handleRowClick(portfolioComponent.id.toString())}
                sx={{ cursor: 'pointer', borderBottom: 'none !important' }}
              >
                <BorderlessTableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    fontSize="13px"
                    whiteSpace="normal"
                  >
                    {portfolioComponent.name}
                  </Typography>
                </BorderlessTableCell>
                <BorderlessTableCell>
                  <Typography variant="body2" fontSize="13px">
                    {portfolioComponent.totalMajorUnits}
                  </Typography>
                </BorderlessTableCell>
                <BorderlessTableCell>
                  <Typography variant="body2" fontSize="13px">
                    {portfolioComponent.portfolioWeight * 100}%
                  </Typography>
                </BorderlessTableCell>
                <BorderlessTableCell align="right">
                  <IconButton aria-label="expand row" size="small">
                    {expandedRows[portfolioComponent.id] ? (
                      <CaretUpIcon />
                    ) : (
                      <CaretDownIcon />
                    )}
                  </IconButton>
                </BorderlessTableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: isMobile
                      ? theme.palette.custom.lightGray
                      : 'transparent',
                  }}
                  colSpan={6}
                >
                  <Collapse
                    in={expandedRows[portfolioComponent.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      {...(!isMobile && {
                        marginBottom: 4,
                      })}
                    >
                      <ComponentTable component={portfolioComponent} />
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioTable;

const BorderlessTableCell = styled(TableCell)({
  borderBottom: 'none !important',
});
