import { gql } from '../gen';

export const CREATE_GRANT = gql(/* GraphQL */ `
  mutation CreateGrant($input: CreateGrantInput!) {
    createGrant(input: $input) {
      id
      createdAt
      nonProfitName
      startDate
      amountMajorUnits
      currencyCodeISO4217
      period
      categories {
        id
        subjectCode
        label
      }
    }
  }
`);

export const UPDATE_GRANT = gql(/* GraphQL */ `
  mutation UpdateGrant($input: UpdateGrantInput!) {
    updateGrant(input: $input) {
      id
      createdAt
      nonProfitName
      startDate
      amountMajorUnits
      currencyCodeISO4217
      period
      categories {
        id
        subjectCode
        label
      }
    }
  }
`);

export const CANCEL_GRANT = gql(/* GraphQL */ `
  mutation CancelGrant($input: CancelGrantInput!) {
    cancelGrant(input: $input) {
      id
    }
  }
`);
