import { useQuery, useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import type { KeyboardEvent } from 'react';
import { Stack, styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { GET_GRANTS_QUERY } from '~/graphql/queries/grants';
import { PeriodEnum } from '~/graphql/gen/graphql';
import { GrayContainer } from '~/shared/components/StyledComponents';
import TableSkeleton from './components/TableSkeleton';
import type { Grant } from '~/graphql/gen/graphql';
import { activeDAFIdVar } from '~/apollo';
import { GRANT_PAGE_FILTERS } from '~/shared/constants';
import TableControls from './components/TableControls';
import GrantlessState from './components/GrantlessState';
import ErrorState from './components/ErrorState';
import GrantsOverview from './components/GrantsOverview';
import GrantsTable from './components/GrantsTable';
import EmptySearch from './components/EmptySearch';

/**
 * Type guard to narrow grants, removing the possible null
 */
const isGetGrantsResult = (grant: Grant | null): grant is Grant => {
  return grant !== null;
};

const Grants = () => {
  const [grants, setGrants] = useState<Grant[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter') || GRANT_PAGE_FILTERS.ALL;
  const activeDAFId = useReactiveVar(activeDAFIdVar);

  if (!activeDAFId) {
    return null;
  }

  const { data, error, loading } = useQuery(GET_GRANTS_QUERY, {
    variables: {
      input: {
        dafAccountId: activeDAFId,
      },
    },
    onCompleted: (data) => {
      const grants = data.getGrants.filter(isGetGrantsResult);

      setGrants(grants);
    },
    fetchPolicy: 'cache-and-network',
  });

  const getFilteredGrants = (grants: Grant[], filter: string) => {
    let filteredGrants: Grant[] = [];
    switch (filter) {
      case GRANT_PAGE_FILTERS.RECURRING: {
        filteredGrants = grants.filter(
          (grant) =>
            grant.period === PeriodEnum.Annual ||
            grant.period === PeriodEnum.Monthly,
        );
        break;
      }
      case GRANT_PAGE_FILTERS.ONE_TIME: {
        filteredGrants = grants.filter(
          (grant) => grant.period === PeriodEnum.OneTime,
        );
        break;
      }
      default: {
        filteredGrants = grants;
        break;
      }
    }
    const sorted = filteredGrants.sort((a, b) => {
      const aStartDate = new Date(a.startDate ?? a.createdAt).getTime();
      const bStartDate = new Date(b.startDate ?? b.createdAt).getTime();
      return bStartDate - aStartDate;
    });
    return sorted;
  };

  const filteredGrants = getFilteredGrants(
    grants.filter(isGetGrantsResult) || [],
    filter,
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    const searchedGrants = data?.getGrants.filter((grant): grant is Grant => {
      if (!isGetGrantsResult(grant)) {
        return false;
      }

      if (searchTerm === '') {
        return true;
      }

      return grant.nonProfitName
        .toLowerCase()
        .includes(searchTerm?.toLowerCase() ?? '');
    });

    setGrants(searchedGrants || []);
  };

  const handleClear = () => {
    setGrants(data?.getGrants.filter(isGetGrantsResult) || []);
    setSearchTerm('');
  };

  const handleBlur = () => {
    if (searchTerm === '') {
      setGrants(data?.getGrants.filter(isGetGrantsResult) || []);
    }
  };

  const showGrants = !loading && !error && filteredGrants.length > 0;
  const showEmptySearch = !loading && !error && filteredGrants.length === 0;
  const showNoGrants = !loading && !error && data?.getGrants.length === 0;

  return (
    <Container>
      <GrantsOverview />
      <GrayContainer padding={0}>
        {showNoGrants ? (
          <GrantlessState />
        ) : (
          <>
            <TableControls
              handleKeyDown={handleKeyDown}
              handleBlur={handleBlur}
              handleClear={handleClear}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            {loading && <TableSkeleton />}
            {error && <ErrorState />}
            {showGrants && (
              <GrantsTable grants={filteredGrants} setGrants={setGrants} />
            )}
            {showEmptySearch && <EmptySearch />}
          </>
        )}
      </GrayContainer>
    </Container>
  );
};

export default Grants;

const Container = styled(Stack)({
  overflow: 'auto',
  flex: 1,
});
