import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { selectedNonProfitVar } from '~/apollo';
import EmployeesIcon from '~/assets/icons/EmployeesIcon';

const Employees = () => {
  const selectedNonProfit = useReactiveVar(selectedNonProfitVar);

  if (!selectedNonProfit) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <EmployeesIcon />
      <Stack>
        <Typography fontSize="10px" fontWeight="bold">
          Employees:
        </Typography>
        {selectedNonProfit.employeeNumber ? (
          <Typography fontSize="10px">
            {parseInt(selectedNonProfit.employeeNumber, 10).toLocaleString()}
          </Typography>
        ) : (
          <Typography fontSize="10px">N/A</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Employees;
