import { Stack, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SearchBar from '~/shared/components/SearchBar';
import {
  TableButtonText,
  TableControlButton,
  TableControlsContainer,
} from '~/shared/components/StyledComponents';
import { GRANT_PAGE_FILTERS } from '~/shared/constants';
import { theme } from '~/shared/styles/theme';

interface Props {
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  handleClear: () => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  handleBlur?: () => void;
}

const TableControls = ({
  handleKeyDown,
  handleClear,
  handleBlur,
  searchTerm,
  setSearchTerm,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter') || GRANT_PAGE_FILTERS.ALL;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const applyFilter = (filterType: string) => {
    setSearchParams({ filter: filterType });
  };

  return (
    <TableControlsContainer>
      <SearchBar
        handleKeyDown={handleKeyDown}
        handleClear={handleClear}
        handleBlur={handleBlur}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      <Stack direction="row" alignItems="center" mb={isMobile ? 6 : 0}>
        <TableControlButton
          isActive={filter === GRANT_PAGE_FILTERS.ALL}
          variant="tableFilter"
          onClick={() => applyFilter(GRANT_PAGE_FILTERS.ALL)}
        >
          <TableButtonText isActive={filter === GRANT_PAGE_FILTERS.ALL}>
            All Grants
          </TableButtonText>
        </TableControlButton>
        <TableControlButton
          isActive={filter === GRANT_PAGE_FILTERS.RECURRING}
          variant="tableFilter"
          onClick={() => applyFilter(GRANT_PAGE_FILTERS.RECURRING)}
        >
          <TableButtonText isActive={filter === GRANT_PAGE_FILTERS.RECURRING}>
            Recurring
          </TableButtonText>
        </TableControlButton>
        <TableControlButton
          isActive={filter === GRANT_PAGE_FILTERS.ONE_TIME}
          variant="tableFilter"
          onClick={() => applyFilter(GRANT_PAGE_FILTERS.ONE_TIME)}
        >
          <TableButtonText isActive={filter === GRANT_PAGE_FILTERS.ONE_TIME}>
            One Time
          </TableButtonText>
        </TableControlButton>
      </Stack>
    </TableControlsContainer>
  );
};

export default TableControls;
