import { Grid, Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';

const EmptyState = () => {
  return (
    <Grid item xs={12} md={6}>
      <DashboardCard
        sx={{
          border: `1px solid ${theme.palette.custom.darkTeal}`,
          color: theme.palette.custom.darkTeal,
          backgroundColor: 'transparent',
          borderRadius: '10px',
          minHeight: '256px',
        }}
      >
        <SectionHeading
          sx={{
            color: theme.palette.custom.desaturatedTeal,
            margin: 0,
          }}
        >
          Total Grants
        </SectionHeading>
        <Stack borderRadius="10px" justifyContent="center" height="100%">
          <Typography variant="h2" textAlign="center">
            Send your first grant to a cause you care about!
          </Typography>
        </Stack>
      </DashboardCard>
    </Grid>
  );
};

export default EmptyState;
