import { useReactiveVar } from '@apollo/client';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import { CSSProperties } from 'react';
import { activeDAFIdVar, clientVar } from '~/apollo';
import LocalStorageService from '~/services/localStorage.service';
import { LocalStorageKeys, SnackbarVariant } from '~/shared/constants';
import useSnackbar from '~/shared/hooks/useSnackbar';

interface AccountSelectProps {
  padding?: CSSProperties['padding'];
}

const AccountSelect = ({ padding }: AccountSelectProps) => {
  const client = useReactiveVar(clientVar);
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const { createSnackNotice } = useSnackbar();
  const { spacing } = useTheme();

  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value !== 'number') {
      createSnackNotice(
        'Unable to change account. Please Reload and try again.',
        SnackbarVariant.Error,
      );
      return;
    }

    activeDAFIdVar(e.target.value);
    LocalStorageService.set(LocalStorageKeys.activeDAFId, e.target.value);
  };

  if (!client) {
    return null;
  }

  if (client?.DAFAccounts && client?.DAFAccounts.length < 2) {
    return null;
  }

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{ padding: padding ?? spacing(0, 4, 0, 0) }}
    >
      <Label id="daf-account-input">DAF Account</Label>
      <StyledSelect
        labelId="daf-account-input"
        label="DAF Account"
        onChange={handleSelect}
        value={activeDAFId}
        MenuProps={{
          PaperProps: {
            sx: {
              '& li': {
                fontSize: '10px',
              },
            },
          },
        }}
      >
        {client.DAFAccounts.map((account) => {
          if (!account.id) {
            return null;
          }

          return (
            <MenuItem key={account.id} value={account.id}>
              {account.accountName}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default AccountSelect;

const Label = styled(InputLabel)({
  fontSize: '10px',
});

const StyledSelect = styled(Select)({
  fontSize: '10px',
  fontWeight: 'normal',
});
