import agricultureCard1 from './agriculture/agricultureCard1.png';
import agricultureCard2 from './agriculture/agricultureCard2.png';
import agricultureCard3 from './agriculture/agricultureCard3.png';
import agricultureCard4 from './agriculture/agricultureCard4.png';
import agricultureCard5 from './agriculture/agricultureCard5.png';
import artsCultureCard1 from './artsCulture/artsCultureCard1.png';
import artsCultureCard2 from './artsCulture/artsCultureCard2.png';
import artsCultureCard3 from './artsCulture/artsCultureCard3.png';
import artsCultureCard4 from './artsCulture/artsCultureCard4.png';
import artsCultureCard5 from './artsCulture/artsCultureCard5.png';
import biodiversityCard1 from './biodiversity/biodiversityCard1.png';
import biodiversityCard2 from './biodiversity/biodiversityCard2.png';
import biodiversityCard3 from './biodiversity/biodiversityCard3.png';
import biodiversityCard4 from './biodiversity/biodiversityCard4.png';
import biodiversityCard5 from './biodiversity/biodiversityCard5.png';
import developmentCard1 from './development/developmentCard1.png';
import developmentCard2 from './development/developmentCard2.png';
import developmentCard3 from './development/developmentCard3.png';
import developmentCard4 from './development/developmentCard4.png';
import developmentCard5 from './development/developmentCard5.png';
import disasterReliefCard1 from './disasterRelief/disasterReliefCard1.png';
import disasterReliefCard2 from './disasterRelief/disasterReliefCard2.png';
import disasterReliefCard3 from './disasterRelief/disasterReliefCard3.png';
import disasterReliefCard4 from './disasterRelief/disasterReliefCard4.png';
import disasterReliefCard5 from './disasterRelief/disasterReliefCard5.png';
import domesticatedCard1 from './domesticatedAnimals/domesticatedCard1.png';
import domesticatedCard2 from './domesticatedAnimals/domesticatedCard2.png';
import domesticatedCard3 from './domesticatedAnimals/domesticatedCard3.png';
import domesticatedCard4 from './domesticatedAnimals/domesticatedCard4.png';
import domesticatedCard5 from './domesticatedAnimals/domesticatedCard5.png';
import educationCard1 from './education/educationCard1.png';
import educationCard2 from './education/educationCard2.png';
import educationCard3 from './education/educationCard3.png';
import educationCard4 from './education/educationCard4.png';
import educationCard5 from './education/educationCard5.png';
import environmentCard1 from './environment/environmentCard1.png';
import environmentCard2 from './environment/environmentCard2.png';
import environmentCard3 from './environment/environmentCard3.png';
import environmentCard4 from './environment/environmentCard4.png';
import environmentCard5 from './environment/environmentCard5.png';
import healthWellnessCard1 from './healthWellness/healthWellnessCard1.png';
import healthWellnessCard2 from './healthWellness/healthWellnessCard2.png';
import healthWellnessCard3 from './healthWellness/healthWellnessCard3.png';
import healthWellnessCard4 from './healthWellness/healthWellnessCard4.png';
import healthWellnessCard5 from './healthWellness/healthWellnessCard5.png';
import humanRightsCard1 from './humanRights/humanRightsCard1.png';
import humanRightsCard2 from './humanRights/humanRightsCard2.png';
import humanRightsCard3 from './humanRights/humanRightsCard3.png';
import humanRightsCard4 from './humanRights/humanRightsCard4.png';
import humanRightsCard5 from './humanRights/humanRightsCard5.png';
import humanServicesCard1 from './humanServices/humanServicesCard1.png';
import humanServicesCard2 from './humanServices/humanServicesCard2.png';
import humanServicesCard3 from './humanServices/humanServicesCard3.png';
import humanServicesCard4 from './humanServices/humanServicesCard4.png';
import humanServicesCard5 from './humanServices/humanServicesCard5.png';
import infoComCard1 from './infoCom/infoComCard1.png';
import infoComCard2 from './infoCom/infoComCard2.png';
import infoComCard3 from './infoCom/infoComCard3.png';
import infoComCard4 from './infoCom/infoComCard4.png';
import infoComCard5 from './infoCom/infoComCard5.png';
import internationalCard1 from './international/internationalCard1.png';
import internationalCard2 from './international/internationalCard2.png';
import internationalCard3 from './international/internationalCard3.png';
import internationalCard4 from './international/internationalCard4.png';
import internationalCard5 from './international/internationalCard5.png';
import philanthropyCard1 from './philanthropy/philanthropyCard1.png';
import philanthropyCard2 from './philanthropy/philanthropyCard2.png';
import philanthropyCard3 from './philanthropy/philanthropyCard3.png';
import philanthropyCard4 from './philanthropy/philanthropyCard4.png';
import philanthropyCard5 from './philanthropy/philanthropyCard5.png';
import publicAffairsCard1 from './publicAffairs/publicAffairsCard1.png';
import publicAffairsCard2 from './publicAffairs/publicAffairsCard2.png';
import publicAffairsCard3 from './publicAffairs/publicAffairsCard3.png';
import publicAffairsCard4 from './publicAffairs/publicAffairsCard4.png';
import publicAffairsCard5 from './publicAffairs/publicAffairsCard5.png';
import religionCard1 from './religion/religionCard1.png';
import religionCard2 from './religion/religionCard2.png';
import religionCard3 from './religion/religionCard3.png';
import religionCard4 from './religion/religionCard4.png';
import religionCard5 from './religion/religionCard5.png';
import scienceCard1 from './science/scienceCard1.png';
import scienceCard2 from './science/scienceCard2.png';
import scienceCard3 from './science/scienceCard3.png';
import scienceCard4 from './science/scienceCard4.png';
import scienceCard5 from './science/scienceCard5.png';
import socialScienceCard1 from './socialScience/socialScienceCard1.png';
import socialScienceCard2 from './socialScience/socialScienceCard2.png';
import socialScienceCard3 from './socialScience/socialScienceCard3.png';
import socialScienceCard4 from './socialScience/socialScienceCard4.png';
import socialScienceCard5 from './socialScience/socialScienceCard5.png';
import sportsCard1 from './sports/sportsCard1.png';
import sportsCard2 from './sports/sportsCard2.png';
import sportsCard3 from './sports/sportsCard3.png';
import sportsCard4 from './sports/sportsCard4.png';
import sportsCard5 from './sports/sportsCard5.png';

const cards = {
  agriculture: {
    card1: agricultureCard1,
    card2: agricultureCard2,
    card3: agricultureCard3,
    card4: agricultureCard4,
    card5: agricultureCard5,
  },
  artsCulture: {
    card1: artsCultureCard1,
    card2: artsCultureCard2,
    card3: artsCultureCard3,
    card4: artsCultureCard4,
    card5: artsCultureCard5,
  },
  biodiversity: {
    card1: biodiversityCard1,
    card2: biodiversityCard2,
    card3: biodiversityCard3,
    card4: biodiversityCard4,
    card5: biodiversityCard5,
  },
  development: {
    card1: developmentCard1,
    card2: developmentCard2,
    card3: developmentCard3,
    card4: developmentCard4,
    card5: developmentCard5,
  },
  disasterRelief: {
    card1: disasterReliefCard1,
    card2: disasterReliefCard2,
    card3: disasterReliefCard3,
    card4: disasterReliefCard4,
    card5: disasterReliefCard5,
  },
  domesticatedAnimals: {
    card1: domesticatedCard1,
    card2: domesticatedCard2,
    card3: domesticatedCard3,
    card4: domesticatedCard4,
    card5: domesticatedCard5,
  },
  education: {
    card1: educationCard1,
    card2: educationCard2,
    card3: educationCard3,
    card4: educationCard4,
    card5: educationCard5,
  },
  environment: {
    card1: environmentCard1,
    card2: environmentCard2,
    card3: environmentCard3,
    card4: environmentCard4,
    card5: environmentCard5,
  },
  healthWellness: {
    card1: healthWellnessCard1,
    card2: healthWellnessCard2,
    card3: healthWellnessCard3,
    card4: healthWellnessCard4,
    card5: healthWellnessCard5,
  },
  humanRights: {
    card1: humanRightsCard1,
    card2: humanRightsCard2,
    card3: humanRightsCard3,
    card4: humanRightsCard4,
    card5: humanRightsCard5,
  },
  humanServices: {
    card1: humanServicesCard1,
    card2: humanServicesCard2,
    card3: humanServicesCard3,
    card4: humanServicesCard4,
    card5: humanServicesCard5,
  },
  infoCom: {
    card1: infoComCard1,
    card2: infoComCard2,
    card3: infoComCard3,
    card4: infoComCard4,
    card5: infoComCard5,
  },
  international: {
    card1: internationalCard1,
    card2: internationalCard2,
    card3: internationalCard3,
    card4: internationalCard4,
    card5: internationalCard5,
  },
  philanthropy: {
    card1: philanthropyCard1,
    card2: philanthropyCard2,
    card3: philanthropyCard3,
    card4: philanthropyCard4,
    card5: philanthropyCard5,
  },
  publicAffairs: {
    card1: publicAffairsCard1,
    card2: publicAffairsCard2,
    card3: publicAffairsCard3,
    card4: publicAffairsCard4,
    card5: publicAffairsCard5,
  },
  religion: {
    card1: religionCard1,
    card2: religionCard2,
    card3: religionCard3,
    card4: religionCard4,
    card5: religionCard5,
  },
  science: {
    card1: scienceCard1,
    card2: scienceCard2,
    card3: scienceCard3,
    card4: scienceCard4,
    card5: scienceCard5,
  },
  socialScience: {
    card1: socialScienceCard1,
    card2: socialScienceCard2,
    card3: socialScienceCard3,
    card4: socialScienceCard4,
    card5: socialScienceCard5,
  },
  sports: {
    card1: sportsCard1,
    card2: sportsCard2,
    card3: sportsCard3,
    card4: sportsCard4,
    card5: sportsCard5,
  },
};

export default cards;
