import {
  Box,
  Grid,
  Skeleton,
  Stack,
  styled,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material';

const DashboardSkeleton = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <Container>
      <Grid container spacing={5}>
        {/* WelcomeSection Skeleton */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            order: {
              xs: 2,
              md: 1,
            },
          }}
        >
          <CardWrapper>
            <Box p={6}>
              <Skeleton width={100} height={16} />
              <Box mt={5}>
                <Skeleton width="80%" height={48} />
              </Box>
              <Box mt={3}>
                <Skeleton width={195} height={40} />
              </Box>
            </Box>
          </CardWrapper>
        </Grid>

        {/* AccountSection Skeleton */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            order: {
              xs: 1,
              md: 2,
            },
          }}
        >
          <CardWrapper>
            <Box p={6}>
              <Box mb={3.5}>
                <Skeleton width="100%" height={16} />
                <Box mt={2}>
                  <TotalContainerSkeleton>
                    <Skeleton width="100%" height={32} />
                  </TotalContainerSkeleton>
                </Box>
              </Box>
              <Box mb={5}>
                <Skeleton width="100%" height={16} />
                <Box mt={2}>
                  <TotalContainerSkeleton>
                    <Skeleton width="100%" height={32} />
                  </TotalContainerSkeleton>
                </Box>
              </Box>
              <Skeleton width="100%" height={40} />
            </Box>
          </CardWrapper>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} md={8} display="flex">
          <Grid container spacing={6}>
            {/* FavoritesCard Skeleton */}
            <Grid item xs={12} md={6}>
              <CardWrapper>
                <Box p={4}>
                  <Stack direction="row" justifyContent="space-between" mb={6}>
                    <Skeleton width={120} height={24} />
                    <Skeleton width={80} height={32} />
                  </Stack>
                  <Stack alignItems="center" spacing={2}>
                    <Skeleton width={200} height={32} />
                    <Skeleton width={200} height={24} />
                  </Stack>
                </Box>
              </CardWrapper>
            </Grid>

            {/* TotalGrantsCard Skeleton */}
            <Grid item xs={12} md={6}>
              <CardWrapper>
                <Box p={4}>
                  <Stack direction="row" justifyContent="space-between" mb={6}>
                    <Skeleton width="50%" height={24} />
                    <Skeleton width="45%" height={32} />
                  </Stack>
                  <Stack alignItems="center" spacing={2}>
                    <Skeleton width="50%" height={24} />
                    <Skeleton width="45%" height={32} />
                  </Stack>
                </Box>
              </CardWrapper>
            </Grid>

            {/* RecurringGrantsCard Skeleton */}
            <Grid item xs={12}>
              <CardWrapper>
                <Box p={4}>
                  {!isMobile ? (
                    <>
                      <Skeleton width={160} height={24} sx={{ mb: 6.5 }} />
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box px={4}>
                          <Stack spacing={1}>
                            <Skeleton width={400} height={32} />
                            <Skeleton width={350} height={32} />
                          </Stack>
                        </Box>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={4}
                      >
                        <Skeleton width="50%" height={24} />
                        <Skeleton width="45%" height={32} />
                      </Stack>
                      <Stack alignItems="center" spacing={2}>
                        <Skeleton width="100%" height={32} />
                        <Skeleton width="90%" height={32} />
                      </Stack>
                    </>
                  )}
                </Box>
              </CardWrapper>
            </Grid>
          </Grid>
        </Grid>

        {/* CategoriesCard Skeleton */}
        <Grid item xs={12} md={4}>
          <CardWrapper>
            <Box p={4}>
              <Skeleton width="80%" height={32} />
              <Stack
                direction={{ xs: 'row', md: 'column' }}
                spacing={4}
                mt={4}
                alignItems="center"
              >
                <Stack spacing={2} width={isMobile ? '50%' : '100%'}>
                  {[1, 2, 3, 4].map((i) => (
                    <Stack
                      key={i}
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                    >
                      <Skeleton variant="circular" width={11} height={11} />
                      <Skeleton width={100} height={16} />
                    </Stack>
                  ))}
                </Stack>
                <Skeleton
                  variant="circular"
                  width={184}
                  height={184}
                  sx={{ minWidth: 184 }}
                />
              </Stack>
            </Box>
          </CardWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardSkeleton;

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 6.5, 6.5, 6.5),
  backgroundColor: theme.palette.custom.lightGray,
  gap: theme.spacing(7.5),
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));

const CardWrapper = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '10px',
  height: '100%',
});

const TotalContainerSkeleton = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.customBorderRadius['5'],
  background: theme.palette.custom.sageGreen,
  padding: theme.spacing(3.5, 3.5, 3.5, 3),
}));
