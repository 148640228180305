import { useReactiveVar } from '@apollo/client';
import { styled, Box, useMediaQuery, Theme } from '@mui/material';
import { activeDAFIdVar, clientVar } from '~/apollo';
import TotalsBox from '../../../shared/components/TotalsBox';

const TransactionsOverview = () => {
  const dafAccountId = useReactiveVar(activeDAFIdVar);
  const client = useReactiveVar(clientVar);
  const activeDAF = client?.DAFAccounts.find((daf) => daf.id === dafAccountId);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Layout>
      <TotalsBox
        key={'accountBalance'}
        title={'Total Account Balance'}
        total={activeDAF?.totalBalanceMajorUnits}
        stayBox={!isSmall}
      />
    </Layout>
  );
};

export default TransactionsOverview;

const Layout = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  padding: theme.spacing(0, 5),
  maxWidth: 500,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));
