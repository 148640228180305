import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import type { KeyboardEvent } from 'react';
import { Stack, styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { GrayContainer } from '~/shared/components/StyledComponents';
import { activeDAFIdVar } from '~/apollo';
import { TRANSACTIONS_PAGE_FILTERS } from '~/shared/constants';
import { OrderByEnum, Transaction } from '~/graphql/gen/graphql';
import { GET_TRANSACTIONS_QUERY } from '~/graphql/queries/transactions';
import DataDogService from '~/services/datadog.service';
import TableSkeleton from './components/TableSkeleton';
import TableControls from './components/TableControls';
import EmptyState from './components/EmptyState';
import ErrorState from './components/ErrorState';
import TransactionsOverview from './components/TransactionsOverview';
import TransactionsTable from './components/TransactionsTable';
import TransactionsTableFooter from './components/TransactionsTableFooter';
import useScrollObserver from '~/shared/hooks/useScrollObserver';

// Type guard orderBy value
function getValidOrderBy(value: string | null): OrderByEnum {
  const isValidOrderBy =
    value === OrderByEnum.Asc || value === OrderByEnum.Desc;
  return isValidOrderBy ? value : OrderByEnum.Desc;
}

const Transactions = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const filter = searchParams.get('filter');
  const search = searchParams.get('search') ?? filter;
  const orderBy = getValidOrderBy(searchParams.get('orderBy'));

  if (!activeDAFId) {
    return null;
  }

  const { data, error, loading } = useQuery(GET_TRANSACTIONS_QUERY, {
    variables: {
      input: {
        dafAccountId: activeDAFId,
        page: pageNumber,
        pageSize: 25,
        search: search === TRANSACTIONS_PAGE_FILTERS.ALL ? null : search,
        orderBy: orderBy,
      },
    },

    onCompleted: () => {
      if (!data?.getTransactions.transactions) {
        setTransactions([]);
        setTotalCount(0);
        return;
      }
      if (pageNumber > 1) {
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          ...data.getTransactions.transactions,
        ]);
      } else {
        setTransactions(data.getTransactions.transactions);
      }
      setTotalCount(data.getTransactions.totalTransactions);
      setIsLoadingMore(false);
    },
    onError: (e) => {
      setIsLoadingMore(false);
      DataDogService.logError(e);
    },
  });

  const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (searchText === '') {
      handleClear();
      return;
    }
    setPageNumber(1);
    searchParams.delete('filter');
    searchParams.set('search', searchText);
    setSearchParams(searchParams);
  };

  const handleClear = async () => {
    setPageNumber(1);
    setSearchText('');
    searchParams.set('filter', TRANSACTIONS_PAGE_FILTERS.ALL);
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  const handleBlur = async () => {
    if (searchText === '') {
      handleClear();
    }
  };

  const handleFilterSearch = async (filter: string) => {
    setPageNumber(1);
    setSearchText('');
    searchParams.set('filter', filter);
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    // Only set initial query params if there are no params in the url
    if (!searchParams.toString()) {
      setSearchParams({
        filter: TRANSACTIONS_PAGE_FILTERS.ALL,
        orderBy: OrderByEnum.Desc,
      });
    }
  }, []);

  const canLoadMore =
    !isLoadingMore &&
    !loading &&
    transactions.length > 0 &&
    transactions.length < totalCount;

  useScrollObserver({
    targetRef: loadMoreRef,
    onIntersect: handleLoadMore,
    options: {
      root: containerRef.current,
      rootMargin: '2px',
      threshold: 1.0,
      enabled: canLoadMore,
    },
    dependencies: [canLoadMore],
  });

  const showLoadingSkeleton = loading && !isLoadingMore;
  const showTransactions =
    !showLoadingSkeleton && !error && transactions.length > 0;
  const showEmptyState = !error && !showLoadingSkeleton && !showTransactions;

  return (
    <Container ref={containerRef}>
      <TransactionsOverview />
      <GrayContainer padding={0}>
        <TableControls
          handleKeyDown={handleKeyDown}
          handleBlur={handleBlur}
          handleClear={handleClear}
          searchText={searchText}
          handleFilterSearch={handleFilterSearch}
          setSearchText={setSearchText}
        />
        {showLoadingSkeleton && <TableSkeleton />}
        {error && <ErrorState />}
        {showTransactions && <TransactionsTable transactions={transactions} />}
        {showEmptyState && <EmptyState />}
        <TransactionsTableFooter
          ref={loadMoreRef}
          numberShown={transactions.length}
          totalNumber={totalCount}
          loading={isLoadingMore}
        />
      </GrayContainer>
    </Container>
  );
};

export default Transactions;

const Container = styled(Stack)({
  overflow: 'auto',
  flex: 1,
});
