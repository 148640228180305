import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  activeDAFIdVar,
  clientVar,
  isAdvisorVar,
  selectedClientVar,
} from '~/apollo';
import { GET_PORTFOLIOS_QUERY } from '~/graphql/queries/portfolios';
import { GrayContainer } from '~/shared/components/StyledComponents';
import OverviewPieChart from './components/PieChart';
import { palette } from '~/shared/styles/theme/palette';
import { theme } from '~/shared/styles/theme';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import PortfolioTable from './components/PortfolioTable';
import ErrorState from './components/ErrorState';
import PortfolioSelect from './components/PortfolioSelect';
import TableControls from './components/TableControls';

const InvestmentOverview = () => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const dafAccountId = useReactiveVar(activeDAFIdVar);
  const selectedClient = useReactiveVar(selectedClientVar);
  const client = useReactiveVar(clientVar);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const COLORS = [
    theme.palette.custom.lightBlue,
    theme.palette.custom.lightGreen,
    theme.palette.custom.softBrown,
    theme.palette.custom.darkRed,
  ];

  const selectedPortfolio = searchParams.get('portfolio');

  const clientId = isAdvisorVar() ? selectedClient?.id : client!.id;

  const { data, loading, error } = useQuery(GET_PORTFOLIOS_QUERY, {
    skip: !dafAccountId || (!selectedClient && !client),
    variables: {
      input: {
        dafAccountId: dafAccountId!,
        clientId: clientId!,
      },
    },
    onCompleted: (data) => {
      setSearchParams({
        portfolio: data.getPortfolios.portfolios[0].name,
      });
    },
  });

  const chartData =
    data?.getPortfolios.portfolios.map((portfolio) => ({
      label: portfolio.name,
      value: portfolio.dafWeight * 100,
    })) || [];

  const totalBalance = data?.getPortfolios.accountBalanceMajorUnits || '$0';
  const portfolio = data?.getPortfolios.portfolios.find(
    (portfolio) => portfolio.name === selectedPortfolio,
  );

  const handleRowClick = (id: string) => {
    setExpandedRows((previous) => ({
      ...previous,
      [id]: !previous[id],
    }));
  };

  return (
    <Stack flex={1}>
      <GrayContainer {...(isMobile && { padding: 0 })}>
        {error ? (
          <ErrorState />
        ) : (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            width="100%"
            height="100%"
            {...(!isMobile && { pb: theme.spacing(12.5) })}
          >
            <Stack
              {...(isMobile && {
                sx: { backgroundColor: palette.common.white },
              })}
            >
              <OverviewPieChart
                chartData={chartData}
                totalBalance={totalBalance}
                loading={loading}
                colors={COLORS}
              />
              {!isMobile &&
                data?.getPortfolios.portfolios.map((portfolio, index) => (
                  <PortfolioSelect
                    key={portfolio.id}
                    isActive={selectedPortfolio === portfolio.name}
                    label={portfolio.name}
                    circleColor={COLORS[index % COLORS.length]}
                    handleSelect={() => {
                      setSearchParams({
                        portfolio: portfolio.name,
                      });
                    }}
                  />
                ))}
            </Stack>

            <Stack
              sx={{
                borderRadius: theme.shape.customBorderRadius['10'],
                flex: 1,
                ...(!isMobile && {
                  backgroundColor: palette.common.white,
                }),
              }}
            >
              {isMobile && data?.getPortfolios.portfolios && (
                <TableControls
                  portfolios={data?.getPortfolios.portfolios}
                  colors={COLORS}
                />
              )}
              <Typography
                variant="h3"
                px={theme.spacing(4.5)}
                mt={theme.spacing(isMobile ? 0 : 7.5)}
              >
                {selectedPortfolio}
              </Typography>
              <PortfolioTable
                portfolio={portfolio}
                expandedRows={expandedRows}
                handleRowClick={handleRowClick}
              />
              <Typography
                alignSelf="end"
                margin={
                  isMobile
                    ? `${theme.spacing(8)} auto ${theme.spacing(9)}`
                    : `auto ${theme.spacing(6)} ${theme.spacing(7)}`
                }
                fontSize="14px"
              >
                Total {selectedPortfolio}:{' '}
                <Box component="span" fontWeight="bold">
                  {portfolio?.totalMajorUnits}
                </Box>
              </Typography>
            </Stack>
          </Stack>
        )}
      </GrayContainer>
    </Stack>
  );
};

export default InvestmentOverview;
