import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  variants: [
    {
      props: { variant: 'h1' },
      style: ({ theme }) => {
        return {
          color: theme.palette.common.black,
          textAlign: 'center',
          verticalAlign: 'center',
        };
      },
    },
    {
      props: { variant: 'legalLink' },
      style: ({ theme }) => {
        return {
          color: theme.palette.text.ctaGreen,
          cursor: 'pointer',
        };
      },
    },
    {
      props: { variant: 'copyRight' },
      style: ({ theme }) => {
        return {
          color: theme.palette.text.ctaGreen,
        };
      },
    },
    {
      props: { variant: 'clearSearch' },
      style: ({ theme }) => {
        return {
          color: theme.palette.common.black,
          cursor: 'pointer',
        };
      },
    },
    {
      props: { variant: 'snackbarBody' },
      style: ({ theme }) => {
        return {
          textTransform: 'uppercase',
          color: theme.palette.custom.offWhite,
        };
      },
    },
  ],
};
