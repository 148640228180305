import {
  styled,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import { HiOutlineSearch } from 'react-icons/hi';
import { useTheme } from '@mui/material/styles';
import { theme } from '~/shared/styles/theme';

interface Props {
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  handleClear: () => Promise<void> | void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  handleBlur?: () => void;
  placeholder?: string;
}

const SearchBar = ({
  handleClear,
  handleKeyDown,
  handleBlur,
  searchTerm,
  setSearchTerm,
  placeholder,
}: Props) => {
  const { spacing } = useTheme();

  return (
    <StyledTextField
      variant="outlined"
      placeholder={placeholder ?? 'Search by name...'}
      value={searchTerm ?? ''}
      onChange={(e) => setSearchTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      InputProps={{
        style: {
          padding: spacing(0, 4),
        },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton style={{ padding: 0 }}>
              <HiOutlineSearch
                color={theme.palette.custom.transparentGray}
                style={{ fontSize: '20px', padding: 0 }}
              />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment position="end">
            <Typography variant="clearSearch" onClick={handleClear}>
              CLEAR
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '230px',
  borderRadius: '100px',
  border: `1px solid ${theme.palette.custom.outlineGray}`,
  backgroundColor: theme.palette.common.white,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderRadius: theme.shape.customBorderRadius[20],
    },
    '& input': {
      height: '35px',
      padding: '0',
      fontSize: 12,
      fontWeight: 800,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
  },
}));
