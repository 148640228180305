import { styled, Box } from '@mui/material';
import { forwardRef } from 'react';
import { LoadMoreProgress } from '~/shared/components/StyledComponents';

interface Props {
  loading: boolean;
}

const ClientsTableFooter = forwardRef<HTMLDivElement, Props>(
  ({ loading }, ref) => {
    return <Container ref={ref}>{loading && <LoadMoreProgress />}</Container>;
  },
);

ClientsTableFooter.displayName = 'ClientsTableFooter';
export default ClientsTableFooter;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 54,
  padding: theme.spacing(0, 8, 0, 2),
}));
