import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import WavyLinesSVG from '~/assets/images/WavyLinesSVG';
import { theme } from '~/shared/styles/theme';
import { palette } from '~/shared/styles/theme/palette';

const WelcomeSection = () => {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        order: {
          xs: 2,
          md: 1,
        },
      }}
    >
      <Stack
        sx={{ backgroundColor: palette.common.white }}
        direction="row"
        padding={theme.spacing(5, 0, 0, 6)}
        borderRadius="10px"
        height="100%"
      >
        <Stack width="100%">
          <Typography
            variant="body2"
            fontSize="12px"
            mb={5}
            color={theme.palette.custom.darkTeal}
          >
            Make an impact
          </Typography>
          <Typography variant="h2" mb={3}>
            Find the causes that resonate with your values!{' '}
          </Typography>
          <Box mb={{ xs: 10 }}>
            <Link
              to="/non-profits"
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                variant="main"
                sx={{
                  maxWidth: '195px',
                }}
              >
                Explore Nonprofits
              </Button>
            </Link>
          </Box>
        </Stack>
        <WavyLinesSVG
          style={{
            alignSelf: 'flex-end',
          }}
        />
      </Stack>
    </Grid>
  );
};

export default WelcomeSection;
