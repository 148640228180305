import { animals, people, planet } from '~/assets/images';
import cards from '~/assets/images/cards';
import { CardImage } from '~/graphql/gen/graphql';

export const IS_DEV = import.meta.env.DEV;

export const NON_PROFITS_INCREMENT = 12;

export enum TopLevelSubjectCode {
  AgricultureFishingAndForestry = 'SM000000',
  ArtsAndCulture = 'SA000000',
  BioDiversity = 'SC040000',
  CommunityAndEconomicDevelopment = 'SN000000',
  DisasterRelief = 'SJ060000',
  DomesticatedAnimals = 'SC050000',
  Education = 'SB000000',
  Environment = 'SC000000',
  HealthAndWellness = 'SE000000',
  HumanRights = 'SR000000',
  HumanServices = 'SS000000',
  InformationAndCommunication = 'SH000000',
  InternationalRelations = 'ST000000',
  Philanthropy = 'SD000000',
  PublicAffairs = 'SK000000',
  PublicSafety = 'SJ000000',
  Religion = 'SP000000',
  Science = 'SF000000',
  SocialSciences = 'SG000000',
  SportsAndRecreation = 'SQ000000',
  Unknown = 'SZ000000',
}

export const topLevelSubjectCodes: string[] =
  Object.values(TopLevelSubjectCode);

export const CATEGORIES = [
  {
    subjectCodes: [
      TopLevelSubjectCode.ArtsAndCulture,
      TopLevelSubjectCode.CommunityAndEconomicDevelopment,
      TopLevelSubjectCode.DisasterRelief,
      TopLevelSubjectCode.Education,
      TopLevelSubjectCode.HealthAndWellness,
      TopLevelSubjectCode.HumanServices,
      TopLevelSubjectCode.HumanRights,
      TopLevelSubjectCode.InternationalRelations,
      TopLevelSubjectCode.Philanthropy,
      TopLevelSubjectCode.PublicAffairs,
      TopLevelSubjectCode.PublicSafety,
      TopLevelSubjectCode.Religion,
      TopLevelSubjectCode.SocialSciences,
      TopLevelSubjectCode.SportsAndRecreation,
    ],
    label: 'People',
    image: people,
    subCategories: [
      {
        subjectCodes: [TopLevelSubjectCode.HumanServices],
        label: 'Human Services',
      },
      {
        subjectCodes: [TopLevelSubjectCode.DisasterRelief],
        label: 'Disaster Relief',
      },
      {
        subjectCodes: [TopLevelSubjectCode.HealthAndWellness],
        label: 'Health',
      },
      {
        subjectCodes: [TopLevelSubjectCode.Education],
        label: 'Education',
      },
      {
        // TODO: Confirm correct categories
        subjectCodes: [
          TopLevelSubjectCode.ArtsAndCulture,
          TopLevelSubjectCode.CommunityAndEconomicDevelopment,
          TopLevelSubjectCode.InternationalRelations,
          TopLevelSubjectCode.PublicAffairs,
          TopLevelSubjectCode.PublicSafety,
          TopLevelSubjectCode.SportsAndRecreation,
        ],
        label: 'Other',
      },
      {
        // TODO: Confirm correct categories
        subjectCodes: [
          TopLevelSubjectCode.ArtsAndCulture,
          TopLevelSubjectCode.CommunityAndEconomicDevelopment,
          TopLevelSubjectCode.DisasterRelief,
          TopLevelSubjectCode.Education,
          TopLevelSubjectCode.HumanServices,
          TopLevelSubjectCode.HealthAndWellness,
          TopLevelSubjectCode.InternationalRelations,
          TopLevelSubjectCode.PublicAffairs,
          TopLevelSubjectCode.PublicSafety,
          TopLevelSubjectCode.SportsAndRecreation,
        ],
        label: 'Select All',
      },
    ],
  },
  {
    subjectCodes: [
      TopLevelSubjectCode.AgricultureFishingAndForestry,
      TopLevelSubjectCode.Environment,
      TopLevelSubjectCode.InformationAndCommunication,
      TopLevelSubjectCode.Science,
    ],
    label: 'Planet',
    image: planet,
  },
  {
    subjectCodes: [
      TopLevelSubjectCode.BioDiversity,
      TopLevelSubjectCode.DomesticatedAnimals,
    ],
    label: 'Animals',
    image: animals,
  },
];

export const ROUTES = {
  DASHBOARD: '/dashboard',
  NON_PROFITS: '/non-profits',
  GRANTS: '/grants',
  INVESTMENT_OVERVIEW: '/investment-overview',
  TRANSACTIONS: '/transactions',
  TAX_DOCUMENTS: '/tax-documents',
  ADVISOR: {
    DASHBOARD: '/advisor/dashboard',
    NON_PROFITS: '/advisor/non-profits',
    CLIENTS: '/advisor/clients',
    CLIENT_TRANSACTIONS: '/advisor/client-transactions',
    CLIENT_GRANTS: '/advisor/client-grants',
    CLIENT_OVERVIEW: '/advisor/client-overview',
  },
} as const;

export const CARD_IMAGES: Record<CardImage['subjectCode'], string[]> = {
  [TopLevelSubjectCode.AgricultureFishingAndForestry]: [
    cards.agriculture.card1,
    cards.agriculture.card2,
    cards.agriculture.card3,
    cards.agriculture.card4,
    cards.agriculture.card5,
  ],
  [TopLevelSubjectCode.ArtsAndCulture]: [
    cards.artsCulture.card1,
    cards.artsCulture.card2,
    cards.artsCulture.card3,
    cards.artsCulture.card4,
    cards.artsCulture.card5,
  ],
  [TopLevelSubjectCode.BioDiversity]: [
    cards.biodiversity.card1,
    cards.biodiversity.card2,
    cards.biodiversity.card3,
    cards.biodiversity.card4,
    cards.biodiversity.card5,
  ],
  [TopLevelSubjectCode.CommunityAndEconomicDevelopment]: [
    cards.development.card1,
    cards.development.card2,
    cards.development.card3,
    cards.development.card4,
    cards.development.card5,
  ],
  [TopLevelSubjectCode.DisasterRelief]: [
    cards.disasterRelief.card1,
    cards.disasterRelief.card2,
    cards.disasterRelief.card3,
    cards.disasterRelief.card4,
    cards.disasterRelief.card5,
  ],
  [TopLevelSubjectCode.DomesticatedAnimals]: [
    cards.domesticatedAnimals.card1,
    cards.domesticatedAnimals.card2,
    cards.domesticatedAnimals.card3,
    cards.domesticatedAnimals.card4,
    cards.domesticatedAnimals.card5,
  ],
  [TopLevelSubjectCode.Education]: [
    cards.education.card1,
    cards.education.card2,
    cards.education.card3,
    cards.education.card4,
    cards.education.card5,
  ],
  [TopLevelSubjectCode.Environment]: [
    cards.environment.card1,
    cards.environment.card2,
    cards.environment.card3,
    cards.environment.card4,
    cards.environment.card5,
  ],
  [TopLevelSubjectCode.HealthAndWellness]: [
    cards.healthWellness.card1,
    cards.healthWellness.card2,
    cards.healthWellness.card3,
    cards.healthWellness.card4,
    cards.healthWellness.card5,
  ],
  [TopLevelSubjectCode.HumanRights]: [
    cards.humanRights.card1,
    cards.humanRights.card2,
    cards.humanRights.card3,
    cards.humanRights.card4,
    cards.humanRights.card5,
  ],
  [TopLevelSubjectCode.HumanServices]: [
    cards.humanServices.card1,
    cards.humanServices.card2,
    cards.humanServices.card3,
    cards.humanServices.card4,
    cards.humanServices.card5,
  ],
  [TopLevelSubjectCode.InformationAndCommunication]: [
    cards.infoCom.card1,
    cards.infoCom.card2,
    cards.infoCom.card3,
    cards.infoCom.card4,
    cards.infoCom.card5,
  ],
  [TopLevelSubjectCode.InternationalRelations]: [
    cards.international.card1,
    cards.international.card2,
    cards.international.card3,
    cards.international.card4,
    cards.international.card5,
  ],
  [TopLevelSubjectCode.Philanthropy]: [
    cards.philanthropy.card1,
    cards.philanthropy.card2,
    cards.philanthropy.card3,
    cards.philanthropy.card4,
    cards.philanthropy.card5,
  ],
  [TopLevelSubjectCode.PublicAffairs]: [
    cards.publicAffairs.card1,
    cards.publicAffairs.card2,
    cards.publicAffairs.card3,
    cards.publicAffairs.card4,
    cards.publicAffairs.card5,
  ],
  [TopLevelSubjectCode.PublicSafety]: [
    cards.publicAffairs.card1,
    cards.publicAffairs.card2,
    cards.publicAffairs.card3,
    cards.publicAffairs.card4,
    cards.publicAffairs.card5,
  ],
  [TopLevelSubjectCode.Religion]: [
    cards.religion.card1,
    cards.religion.card2,
    cards.religion.card3,
    cards.religion.card4,
    cards.religion.card5,
  ],
  [TopLevelSubjectCode.Science]: [
    cards.science.card1,
    cards.science.card2,
    cards.science.card3,
    cards.science.card4,
    cards.science.card5,
  ],
  [TopLevelSubjectCode.SocialSciences]: [
    cards.socialScience.card1,
    cards.socialScience.card2,
    cards.socialScience.card3,
    cards.socialScience.card4,
    cards.socialScience.card5,
  ],
  [TopLevelSubjectCode.SportsAndRecreation]: [
    cards.sports.card1,
    cards.sports.card2,
    cards.sports.card3,
    cards.sports.card4,
    cards.sports.card5,
  ],
  [TopLevelSubjectCode.Unknown]: [
    cards.artsCulture.card1,
    cards.publicAffairs.card2,
    cards.philanthropy.card3,
    cards.humanServices.card4,
    cards.publicAffairs.card5,
  ],
};

export const HEADER_CONTENT = {
  [ROUTES.DASHBOARD]: {
    title: 'Welcome',
    description: 'Track Your Contributions and Impact',
  },
  [ROUTES.NON_PROFITS]: {
    title: 'Explore NonProfits',
    description: 'Select a category to filter nonprofits.',
  },
  [ROUTES.INVESTMENT_OVERVIEW]: {
    title: 'Investment Overview',
    description: 'Monitor Your Investments to Maximize Your Impact',
  },
  [ROUTES.GRANTS]: {
    title: 'Grants',
    description: 'Track Your Contributions and Impact',
  },
  [ROUTES.TRANSACTIONS]: {
    title: 'Transactions',
    description: 'Monitor Your Investments to Maximize Your Impact',
  },
  [ROUTES.ADVISOR.CLIENT_OVERVIEW]: {
    title: 'Investment Overview',
    description: 'For {{name}} | {{account}}',
  },
  [ROUTES.ADVISOR.CLIENT_GRANTS]: {
    title: 'Grants',
    description: 'For {{name}} | {{account}}',
  },
  [ROUTES.ADVISOR.CLIENT_TRANSACTIONS]: {
    title: 'Transactions',
    description: 'For {{name}} | {{account}}',
  },
  [ROUTES.ADVISOR.CLIENTS]: {
    title: 'Clients',
    description: 'Stay on top of their charitable contributions',
  },
} as const;

export const GRANT_PAGE_FILTERS = {
  ALL: 'all',
  ONE_TIME: 'one-time',
  RECURRING: 'recurring',
} as const;

export const TRANSACTIONS_PAGE_FILTERS = {
  ALL: 'all',
  GRANT: 'withdraw',
  CONTRIBUTION: 'deposit',
} as const;

export enum SnackbarVariant {
  Success = 'success',
  Error = 'error',
}

export enum LocalStorageKeys {
  activeDAFId = 'activeDAFId',
}
