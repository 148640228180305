import { gql } from '../gen';

export const CREATE_FAVORITE = gql(/* GraphQL */ `
  mutation CreateFavorite($input: FavoriteInput!) {
    createFavorite(input: $input) {
      id
      ein
    }
  }
`);

export const DELETE_FAVORITE = gql(/* GraphQL */ `
  mutation DeleteFavorite($id: Int!) {
    deleteFavorite(id: $id) {
      id
    }
  }
`);
