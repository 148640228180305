import { Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';

const ErrorState = () => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" flex={1}>
      <Typography
        variant="h3"
        mb={4.5}
        maxWidth="360px"
        textAlign="center"
        color={theme.palette.custom.darkTeal}
      >
        We&apos;re having trouble loading your clients list right now.
      </Typography>
      <Typography
        maxWidth="300px"
        fontSize="14px"
        textAlign="center"
        color={theme.palette.custom.darkTeal}
      >
        Please reload the page or check back later.
      </Typography>
    </Stack>
  );
};

export default ErrorState;
