import {
  styled,
  Dialog as MuiDialog,
  DialogActions,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import CloseBlackOutline from '~/assets/icons/CloseBlackOutline';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  fullScreen?: boolean;
}

const Dialog = ({ isOpen, children, fullScreen, handleClose }: Props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={fullScreen ?? isSmall}
      aria-modal="true"
    >
      <CloseAction onClick={handleClose}>
        <CloseBlackOutline />
      </CloseAction>

      <DialogContainer>{children}</DialogContainer>
    </StyledDialog>
  );
};

export default Dialog;

export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.customBorderRadius['20'],
    width: '465px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '100%',
    },
  },
}));

export const DialogContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '75%',
  margin: 0,
  padding: theme.spacing(12, 0, 14),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    padding: theme.spacing(8, 0, 10),
    overflow: 'auto',
  },
}));

const CloseAction = styled(DialogActions)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  cursor: 'pointer',
}));
