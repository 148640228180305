/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation LoginOrRegister {\n    loginOrRegister {\n      id\n      email\n      firstName\n      lastName\n      externalClientId\n      balanceMajorUnits\n      DAFAccounts {\n        id\n        accountName\n        externalAccountId\n        totalBalanceMajorUnits\n        totalGrantsMajorUnits\n        totalGrantsYTDMajorUnits\n        favorites {\n          id\n          ein\n        }\n      }\n    }\n  }\n':
    types.LoginOrRegisterDocument,
  '\n  mutation CreateGrant($input: CreateGrantInput!) {\n    createGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n':
    types.CreateGrantDocument,
  '\n  mutation UpdateGrant($input: UpdateGrantInput!) {\n    updateGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n':
    types.UpdateGrantDocument,
  '\n  mutation CancelGrant($input: CancelGrantInput!) {\n    cancelGrant(input: $input) {\n      id\n    }\n  }\n':
    types.CancelGrantDocument,
  '\n  mutation CreateFavorite($input: FavoriteInput!) {\n    createFavorite(input: $input) {\n      id\n      ein\n    }\n  }\n':
    types.CreateFavoriteDocument,
  '\n  mutation DeleteFavorite($id: Int!) {\n    deleteFavorite(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteFavoriteDocument,
  '\n  query GetClients($input: AdvisorClientsInput!) {\n    getClients(input: $input) {\n      clients {\n        id\n        externalClientId\n        firstName\n        lastName\n        balanceMajorUnits\n        email\n        DAFAccounts {\n          id\n          accountName\n          externalAccountId\n          favorites {\n            id\n            ein\n          }\n          totalBalanceMajorUnits\n          totalGrantsMajorUnits\n          totalGrantsYTDMajorUnits\n        }\n      }\n      matchingClientCount\n    }\n  }\n':
    types.GetClientsDocument,
  '\n  query GetClientsTotals($input: GetClientsTotalsInput!) {\n    getClientsTotals(input: $input) {\n      totalAccountsBalanceMajorUnits\n      totalAccountsGrantsMajorUnits\n      totalAccountsGrantsYTDMajorUnits\n      numberOfClients\n    }\n  }\n':
    types.GetClientsTotalsDocument,
  '\n  query GetClientDashboard($input: GetClientDashboardInput!) {\n    getClientDashboard(input: $input) {\n      accountBalanceMajorUnits\n      grantsTotalMajorUnits\n      grantsYTDMajorUnits\n      favoritesCount\n      topFavoriteCategories\n      recurringGrantsCount\n      grantChartData {\n        label\n        value\n      }\n    }\n  }\n':
    types.GetClientDashboardDocument,
  '\n  query GetGrants($input: GrantDAFInput!) {\n    getGrants(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n':
    types.GetGrantsDocument,
  '\n  query GetGrantChartData($input: GrantDAFInput!) {\n    getGrantChartData(input: $input) {\n      label\n      value\n    }\n  }\n':
    types.GetGrantChartDataDocument,
  '\n  query GetNonProfits($input: NonProfitsInput!) {\n    getNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        imageUrl\n        categories {\n          subjectCode\n          label\n        }\n        cardImage {\n          subjectCode\n          index\n        }\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        employeeNumber\n        annualBudget\n        ageOfNonProfit\n        link\n      }\n      pagesRemaining\n    }\n  }\n':
    types.GetNonProfitsDocument,
  '\n  query GetAdvisorNonProfits($input: GetAdvisorNonProfitsInput!) {\n    getAdvisorNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        categories {\n          subjectCode\n          label\n        }\n      }\n    }\n  }\n':
    types.GetAdvisorNonProfitsDocument,
  '\n  query GetPortfolios($input: PortfoliosInput!) {\n    getPortfolios(input: $input) {\n      accountBalanceMajorUnits\n      portfolios {\n        id\n        updatedAt\n        name\n        dafWeight\n        totalMajorUnits\n        portfolioComponents {\n          id\n          name\n          portfolioWeight\n          totalMajorUnits\n          portfolioComponentHoldings {\n            id\n            symbol\n            description\n            portfolioComponentWeight\n            totalMajorUnits\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPortfoliosDocument,
  '\n  query GetTransactions($input: TransactionsInput!) {\n    getTransactions(input: $input) {\n      totalTransactions\n      transactions {\n        id\n        createdAt\n        updatedAt\n        transactedAt\n        action\n        description\n        externalId\n        totalMajorUnits\n      }\n    }\n  }\n':
    types.GetTransactionsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation LoginOrRegister {\n    loginOrRegister {\n      id\n      email\n      firstName\n      lastName\n      externalClientId\n      balanceMajorUnits\n      DAFAccounts {\n        id\n        accountName\n        externalAccountId\n        totalBalanceMajorUnits\n        totalGrantsMajorUnits\n        totalGrantsYTDMajorUnits\n        favorites {\n          id\n          ein\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation LoginOrRegister {\n    loginOrRegister {\n      id\n      email\n      firstName\n      lastName\n      externalClientId\n      balanceMajorUnits\n      DAFAccounts {\n        id\n        accountName\n        externalAccountId\n        totalBalanceMajorUnits\n        totalGrantsMajorUnits\n        totalGrantsYTDMajorUnits\n        favorites {\n          id\n          ein\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateGrant($input: CreateGrantInput!) {\n    createGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateGrant($input: CreateGrantInput!) {\n    createGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateGrant($input: UpdateGrantInput!) {\n    updateGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateGrant($input: UpdateGrantInput!) {\n    updateGrant(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CancelGrant($input: CancelGrantInput!) {\n    cancelGrant(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CancelGrant($input: CancelGrantInput!) {\n    cancelGrant(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateFavorite($input: FavoriteInput!) {\n    createFavorite(input: $input) {\n      id\n      ein\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFavorite($input: FavoriteInput!) {\n    createFavorite(input: $input) {\n      id\n      ein\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteFavorite($id: Int!) {\n    deleteFavorite(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteFavorite($id: Int!) {\n    deleteFavorite(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetClients($input: AdvisorClientsInput!) {\n    getClients(input: $input) {\n      clients {\n        id\n        externalClientId\n        firstName\n        lastName\n        balanceMajorUnits\n        email\n        DAFAccounts {\n          id\n          accountName\n          externalAccountId\n          favorites {\n            id\n            ein\n          }\n          totalBalanceMajorUnits\n          totalGrantsMajorUnits\n          totalGrantsYTDMajorUnits\n        }\n      }\n      matchingClientCount\n    }\n  }\n',
): (typeof documents)['\n  query GetClients($input: AdvisorClientsInput!) {\n    getClients(input: $input) {\n      clients {\n        id\n        externalClientId\n        firstName\n        lastName\n        balanceMajorUnits\n        email\n        DAFAccounts {\n          id\n          accountName\n          externalAccountId\n          favorites {\n            id\n            ein\n          }\n          totalBalanceMajorUnits\n          totalGrantsMajorUnits\n          totalGrantsYTDMajorUnits\n        }\n      }\n      matchingClientCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetClientsTotals($input: GetClientsTotalsInput!) {\n    getClientsTotals(input: $input) {\n      totalAccountsBalanceMajorUnits\n      totalAccountsGrantsMajorUnits\n      totalAccountsGrantsYTDMajorUnits\n      numberOfClients\n    }\n  }\n',
): (typeof documents)['\n  query GetClientsTotals($input: GetClientsTotalsInput!) {\n    getClientsTotals(input: $input) {\n      totalAccountsBalanceMajorUnits\n      totalAccountsGrantsMajorUnits\n      totalAccountsGrantsYTDMajorUnits\n      numberOfClients\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetClientDashboard($input: GetClientDashboardInput!) {\n    getClientDashboard(input: $input) {\n      accountBalanceMajorUnits\n      grantsTotalMajorUnits\n      grantsYTDMajorUnits\n      favoritesCount\n      topFavoriteCategories\n      recurringGrantsCount\n      grantChartData {\n        label\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetClientDashboard($input: GetClientDashboardInput!) {\n    getClientDashboard(input: $input) {\n      accountBalanceMajorUnits\n      grantsTotalMajorUnits\n      grantsYTDMajorUnits\n      favoritesCount\n      topFavoriteCategories\n      recurringGrantsCount\n      grantChartData {\n        label\n        value\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGrants($input: GrantDAFInput!) {\n    getGrants(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetGrants($input: GrantDAFInput!) {\n    getGrants(input: $input) {\n      id\n      createdAt\n      nonProfitName\n      startDate\n      amountMajorUnits\n      currencyCodeISO4217\n      period\n      categories {\n        id\n        subjectCode\n        label\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGrantChartData($input: GrantDAFInput!) {\n    getGrantChartData(input: $input) {\n      label\n      value\n    }\n  }\n',
): (typeof documents)['\n  query GetGrantChartData($input: GrantDAFInput!) {\n    getGrantChartData(input: $input) {\n      label\n      value\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetNonProfits($input: NonProfitsInput!) {\n    getNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        imageUrl\n        categories {\n          subjectCode\n          label\n        }\n        cardImage {\n          subjectCode\n          index\n        }\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        employeeNumber\n        annualBudget\n        ageOfNonProfit\n        link\n      }\n      pagesRemaining\n    }\n  }\n',
): (typeof documents)['\n  query GetNonProfits($input: NonProfitsInput!) {\n    getNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        imageUrl\n        categories {\n          subjectCode\n          label\n        }\n        cardImage {\n          subjectCode\n          index\n        }\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        employeeNumber\n        annualBudget\n        ageOfNonProfit\n        link\n      }\n      pagesRemaining\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetAdvisorNonProfits($input: GetAdvisorNonProfitsInput!) {\n    getAdvisorNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        categories {\n          subjectCode\n          label\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetAdvisorNonProfits($input: GetAdvisorNonProfitsInput!) {\n    getAdvisorNonProfits(input: $input) {\n      nonProfits {\n        EIN\n        title\n        description\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        country\n        categories {\n          subjectCode\n          label\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPortfolios($input: PortfoliosInput!) {\n    getPortfolios(input: $input) {\n      accountBalanceMajorUnits\n      portfolios {\n        id\n        updatedAt\n        name\n        dafWeight\n        totalMajorUnits\n        portfolioComponents {\n          id\n          name\n          portfolioWeight\n          totalMajorUnits\n          portfolioComponentHoldings {\n            id\n            symbol\n            description\n            portfolioComponentWeight\n            totalMajorUnits\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetPortfolios($input: PortfoliosInput!) {\n    getPortfolios(input: $input) {\n      accountBalanceMajorUnits\n      portfolios {\n        id\n        updatedAt\n        name\n        dafWeight\n        totalMajorUnits\n        portfolioComponents {\n          id\n          name\n          portfolioWeight\n          totalMajorUnits\n          portfolioComponentHoldings {\n            id\n            symbol\n            description\n            portfolioComponentWeight\n            totalMajorUnits\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetTransactions($input: TransactionsInput!) {\n    getTransactions(input: $input) {\n      totalTransactions\n      transactions {\n        id\n        createdAt\n        updatedAt\n        transactedAt\n        action\n        description\n        externalId\n        totalMajorUnits\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetTransactions($input: TransactionsInput!) {\n    getTransactions(input: $input) {\n      totalTransactions\n      transactions {\n        id\n        createdAt\n        updatedAt\n        transactedAt\n        action\n        description\n        externalId\n        totalMajorUnits\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
