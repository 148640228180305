import { Stack, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const GrantlessState = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight={300}
      flex={1}
    >
      <Typography variant="h3" mb={3} textAlign="center" px={4}>
        You haven&apos;t made any grants yet.
      </Typography>
      <Typography
        fontSize="14px"
        maxWidth="310px"
        textAlign="center"
        lineHeight={1}
        mb={4.5}
        px={4}
      >
        Start making a difference by creating your first grant.
      </Typography>
      <Button
        variant="main"
        component={Link}
        sx={{ maxWidth: '200px', fontSize: '12px' }}
        to="/non-profits"
      >
        Explore Non Profits
      </Button>
    </Stack>
  );
};

export default GrantlessState;
