const CloseBlackOutline: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="31"
        height="31"
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5096 17.5989L20.9459 22.0352C21.0939 22.178 21.292 22.2571 21.4976 22.2553C21.7032 22.2535 21.8999 22.171 22.0453 22.0256C22.1907 21.8802 22.2732 21.6835 22.2749 21.4779C22.2767 21.2723 22.1977 21.0742 22.0548 20.9263L17.6185 16.49L22.0548 12.0536C22.1977 11.9057 22.2767 11.7076 22.2749 11.502C22.2732 11.2964 22.1907 11.0997 22.0453 10.9543C21.8999 10.8089 21.7032 10.7264 21.4976 10.7246C21.292 10.7229 21.0939 10.8019 20.9459 10.9447L16.5096 15.3811L12.0733 10.9447C11.9247 10.8054 11.7278 10.7294 11.5241 10.7327C11.3205 10.736 11.1261 10.8184 10.9821 10.9625C10.8382 11.1065 10.7559 11.301 10.7527 11.5046C10.7496 11.7083 10.8257 11.9052 10.9652 12.0536L15.4007 16.49L10.9644 20.9263C10.8895 20.9986 10.8297 21.0852 10.7886 21.1809C10.7475 21.2765 10.7259 21.3794 10.725 21.4836C10.7241 21.5877 10.7439 21.691 10.7834 21.7873C10.8228 21.8837 10.881 21.9713 10.9547 22.0449C11.0283 22.1185 11.1159 22.1768 11.2122 22.2162C11.3086 22.2556 11.4119 22.2755 11.516 22.2746C11.6201 22.2737 11.723 22.252 11.8187 22.2109C11.9144 22.1698 12.0009 22.1101 12.0733 22.0352L16.5096 17.5989Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseBlackOutline;
