import { gql } from '../gen';

export const LOGIN_OR_REGISTER = gql(/* GraphQL */ `
  mutation LoginOrRegister {
    loginOrRegister {
      id
      email
      firstName
      lastName
      externalClientId
      balanceMajorUnits
      DAFAccounts {
        id
        accountName
        externalAccountId
        totalBalanceMajorUnits
        totalGrantsMajorUnits
        totalGrantsYTDMajorUnits
        favorites {
          id
          ein
        }
      }
    }
  }
`);
