import { Components, Theme } from '@mui/material/styles';

export const MuiSnackbarContent: Components<Theme>['MuiSnackbarContent'] = {
  variants: [
    {
      props: { variant: 'error' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.charcoal,
        color: theme.palette.custom.offWhite,
      }),
    },
    {
      props: { variant: 'success' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.teal,
        color: theme.palette.custom.offWhite,
      }),
    },
  ],
};
