import { styled } from '@mui/material';
import { PieLabelRenderProps } from 'recharts';

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  percent,
}: PieLabelRenderProps) => {
  if (!cx || !cy || !percent) {
    return null;
  }

  const RADIAN = Math.PI / 180;
  const baseRadius = (Number(innerRadius) + Number(outerRadius)) / 2;
  let offset = 0;
  if (percent <= 0.1) {
    offset = 18;
  } else {
    offset = 0;
  }

  const radius = baseRadius + offset;
  const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
  const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Label x={x} y={y} textAnchor="middle" dominantBaseline="central">
      {value}%
    </Label>
  );
};

const Label = styled('text')(({ theme }) => ({
  fontFamily: 'Gotham',
  fill: 'black',
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: 10,
  },
}));

export default CustomLabel;
