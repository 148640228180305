import { Box, Button, Card as MuiCard, styled } from '@mui/material';
import Header from './Header';
import Content from './Content';
import type { NonProfitCardProps } from '~/modules/nonProfits/types';
import {
  activeGrantModalVar,
  isAdvisorVar,
  selectedNonProfitVar,
} from '~/apollo';
import { useReactiveVar } from '@apollo/client';

const Card = ({ nonProfit, onCardClick }: NonProfitCardProps) => {
  const isAdvisor = useReactiveVar(isAdvisorVar);
  const handleMakeGrantClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    selectedNonProfitVar(nonProfit);
    activeGrantModalVar('nonProfit');
  };

  const handleLearnMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCardClick(nonProfit);
  };

  return (
    <Container onClick={() => onCardClick(nonProfit)}>
      <Header nonProfit={nonProfit} />
      <Content imageUrl={nonProfit.imageUrl} title={nonProfit.title} />
      <ButtonContainer>
        {isAdvisor ? (
          <Button variant="text" onClick={handleLearnMoreClick}>
            Learn More
          </Button>
        ) : (
          <Button variant="main" onClick={handleMakeGrantClick}>
            Make a Grant
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default Card;

const Container = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: isAdvisorVar() ? '250px' : '292px',
  maxWidth: '380px',
  minWidth: '255px',
  borderRadius: '0',
  boxShadow: '0 4px 5px 0 rgb(0, 0, 0, 0.06)',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: isAdvisorVar() ? '210px' : '230px',
    minWidth: '155px',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 'auto',
  padding: theme.spacing(0, 3.5, 7),
}));
