import {
  styled,
  Stack,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
} from '@mui/material';
import { ROUTES } from '~/shared/constants';
import NavLink from './components/NavLink';
import AccountSelect from './components/AccountSelect';
import LogoAndTag from '~/shared/components/LogoAndTag';
import { isAdvisorVar } from '~/apollo';
import { useReactiveVar } from '@apollo/client';

const Sidebar = () => {
  const { spacing, breakpoints } = useTheme();
  const currentYear = new Date().getFullYear();
  const isAdvisor = useReactiveVar(isAdvisorVar);
  const isXLScreen = useMediaQuery(breakpoints.up('lg'));

  return (
    <Layout>
      <TopContent>
        <LogoAndTag width={isXLScreen ? 110 : 85} />
        {!isAdvisor && <AccountSelect />}
        {isAdvisor ? (
          <LinksContainer>
            <NavLink to={ROUTES.ADVISOR.DASHBOARD}>Dashboard</NavLink>
            <NavLink to={ROUTES.ADVISOR.CLIENTS}>Clients</NavLink>
            <NavLink to={ROUTES.ADVISOR.NON_PROFITS}>Non Profits</NavLink>
          </LinksContainer>
        ) : (
          <LinksContainer>
            <NavLink to={ROUTES.DASHBOARD}>Dashboard</NavLink>
            <NavLink to={ROUTES.INVESTMENT_OVERVIEW}>
              Investment Overview
            </NavLink>
            <NavLink to={ROUTES.NON_PROFITS}>Non Profits</NavLink>
            <NavLink to={ROUTES.GRANTS}>Grants</NavLink>
            <NavLink to={ROUTES.TRANSACTIONS}>Transactions</NavLink>
          </LinksContainer>
        )}
      </TopContent>
      <BottomContent>
        <Typography variant="copyRight">
          © {currentYear} Brighton Jones.
        </Typography>
        <Typography variant="copyRight">All Rights Reserved.</Typography>
        <LinksContainer>
          {/* TODO: (Ticket: BRI-363) Find proper route */}
          <Typography
            variant="legalLink"
            onClick={() => console.log('WE NEED THE LINK')}
            mr={spacing(2)}
          >
            Privacy Policy
          </Typography>

          {/* TODO: (Ticket: BRI-363) Find proper route */}
          <Typography
            variant="legalLink"
            onClick={() => console.log('WE NEED THE LINK')}
          >
            Terms & Conditions
          </Typography>
        </LinksContainer>
      </BottomContent>
    </Layout>
  );
};

export default Sidebar;

const Layout = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxWidth: '175px',
  minWidth: '175px',
  height: '100vh',
  borderRight: `1px solid ${theme.palette.custom.transparentGray}`,
}));

const TopContent = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(6),
}));

const BottomContent = styled(Stack)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(0, 0, 4, 4),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const LinksContainer = styled(Box)({
  width: '100%',
});
