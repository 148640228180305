import { useEffect, useRef, useState } from 'react';
import { styled, Button, useTheme, Typography, Box } from '@mui/material';
import { IoFilter } from 'react-icons/io5';
import LocationAutocomplete from './LocationAutocomplete';
import {
  activeDAFIdVar,
  nonProfitsCoordsVar,
  pageNumberInputVar,
  searchTermVar,
  subjectCodesVar,
} from '~/apollo';
import { NonProfitsInput } from '~/graphql/gen/graphql';
import { useReactiveVar } from '@apollo/client';
import {
  SearchBoxRetrieveResponse,
  SearchBoxSuggestion,
} from '@mapbox/search-js-core';
import { getLabel } from '~/shared/utils';

interface LocationFilterProps {
  handleSearch: (input: NonProfitsInput) => Promise<void>;
}

const LocationFilter = ({ handleSearch }: LocationFilterProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const autoCompleteRef = useRef<HTMLButtonElement>(null);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<SearchBoxSuggestion | null>(null);
  const [searchTerm, setSearchTerm] = useState(
    selectedSuggestion ? getLabel(selectedSuggestion) : '',
  );
  const [suggestions, setSuggestions] = useState<SearchBoxSuggestion[]>([]);
  const nonProfitsCoords = useReactiveVar(nonProfitsCoordsVar);

  const [isOpen, setIsOpen] = useState(false);
  const [locationDetails, setLocationDetails] =
    useState<SearchBoxRetrieveResponse | null>(null);
  const { palette } = useTheme();

  const handleApplyFilter = () => {
    const coords = locationDetails?.features[0].geometry.coordinates.join(',');
    nonProfitsCoordsVar(coords);
    handleSearch({
      dafAccountId: activeDAFIdVar(),
      searchTerm: searchTermVar(),
      subjectCodes: subjectCodesVar(),
      coordsString: nonProfitsCoordsVar(),
      pageNumber: 1,
    });
    pageNumberInputVar(1);
    setIsOpen(false);
  };

  const handleClear = () => {
    setSearchTerm(''); // Reset the searchTerm state
    setSuggestions([]); // Clear suggestions if necessary
    setSelectedSuggestion(null); // Clear selected suggestion if necessary
    nonProfitsCoordsVar(null);
    handleSearch({
      dafAccountId: activeDAFIdVar(),
      searchTerm: searchTermVar(),
      subjectCodes: subjectCodesVar(),
      coordsString: null,
      pageNumber: 1,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isButtonClick = buttonRef.current?.contains(event.target as Node);
      const isAutoCompleteClick = autoCompleteRef.current?.contains(
        event.target as Node,
      );
      if (
        !isAutoCompleteClick &&
        !isButtonClick &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <FilterButton
        ref={buttonRef}
        variant="tableFilter"
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        sx={{
          background: isOpen ? palette.custom.purple : 'transparent',
          color: isOpen ? palette.common.white : palette.common.black,
        }}
      >
        <IoFilter />
        <Typography fontSize={10} fontWeight={'bold'} ml={2}>
          {nonProfitsCoords ? 'Filtered by: City' : 'Filter by Location'}
        </Typography>
      </FilterButton>
      {isOpen && (
        <SearchContainer ref={containerRef} display={isOpen ? 'flex' : 'none'}>
          <Typography variant="h3" fontSize={15} mb={3.5}>
            Filter Results
          </Typography>
          <LocationAutocomplete
            autoCompleteRef={autoCompleteRef}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedSuggestion={selectedSuggestion}
            setSelectedSuggestion={setSelectedSuggestion}
            setLocationDetails={setLocationDetails}
            handleClear={handleClear}
          />
          <ApplyButton variant="main" onClick={handleApplyFilter}>
            Apply Changes
          </ApplyButton>
        </SearchContainer>
      )}
    </Container>
  );
};

export default LocationFilter;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
}));

const FilterButton = styled(Button)(({ theme }) => ({
  height: 35,
  padding: theme.spacing(1.5, 3),
  marginLeft: theme.spacing(2),
  borderRadius: theme.shape.customBorderRadius['100'],
  '&:hover': {
    background: theme.palette.custom.purpleHover,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2, 1, 1),
    marginLeft: theme.spacing(0),
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  top: 43,
  right: 0,
  width: 380,
  padding: theme.spacing(5, 4, 6),
  background: theme.palette.common.white,
  boxShadow: theme.shadows[1],
  zIndex: theme.zIndex.appBar,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    right: 0,
  },
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  height: 40,
  marginTop: theme.spacing(6),
  background: theme.palette.custom.darkTeal,
  padding: theme.spacing(1.5, 3),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.customBorderRadius['100'],
  '&:hover': {
    background: theme.palette.custom.teal,
  },
}));
