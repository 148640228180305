import {
  styled,
  Stack,
  useTheme,
  Box,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { PieChart, Pie, Cell } from 'recharts';
import CustomLabel from './PieChartLabel';

interface Props {
  data: {
    label: string;
    value: number;
  }[];
}

const PieChartComponent = ({ data }: Props) => {
  const { palette, spacing } = useTheme();
  const isBetweenMdLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('md', 'lg'),
  );
  const COLORS = [
    palette.custom.harvestYellow,
    palette.custom.softGreen,
    palette.custom.periwinkle,
    palette.custom.pewter,
  ];

  return (
    <Layout>
      <Stack mr={spacing(isBetweenMdLg ? 3 : 4)}>
        <Typography mb={spacing(2.5)} fontSize={12}>
          Top Categories
        </Typography>
        {data.map((category, i) => {
          return (
            <Box
              key={category.label}
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <Dot sx={{ background: COLORS[i] }} />
              <CategoryLabel color="black">{category.label}</CategoryLabel>
            </Box>
          );
        })}
      </Stack>
      <PieContainer>
        <PieChart
          width={isBetweenMdLg ? 100 : 132}
          height={isBetweenMdLg ? 100 : 132}
          style={{
            pointerEvents: 'none',
          }}
        >
          <Pie
            data={data}
            dataKey="value"
            nameKey="label"
            fill="#8884d8"
            cursor="cursor"
            label={CustomLabel}
            labelLine={false}
            stroke={'none'}
            startAngle={90}
            endAngle={450}
            outerRadius={isBetweenMdLg ? 50 : 66} // This sets the width, as 66 is half 132
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </PieContainer>
    </Layout>
  );
};

export default PieChartComponent;

const Layout = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PieContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 152,
  minHeight: 152,
  background: theme.palette.custom.lightGray,
  borderRadius: theme.shape.customBorderRadius['circle'],
  [theme.breakpoints.between('md', 'lg')]: {
    minWidth: 115,
    minHeight: 115,
  },
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '7px',
  height: '7px',
  marginRight: theme.spacing(1.5),
  borderRadius: theme.shape.customBorderRadius['circle'],
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '10px',
  fontWeight: 500,
  textTransform: 'capitalize',
}));
