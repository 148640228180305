import { Table, TableBody, TableContainer } from '@mui/material';
import ClientsTableRow from '~/modules/advisor/components/ClientsTableRow';
import TableHeader from '~/modules/advisor/components/TableHeader';
import { Client } from '~/graphql/gen/graphql';
import { useState } from 'react';

interface Props {
  clients: Client[];
}

const ClientsTable = ({ clients }: Props) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          {clients.map((client) => (
            <ClientsTableRow
              key={client.id}
              client={client}
              expandedRow={expandedRow}
              setExpandedRow={setExpandedRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientsTable;
