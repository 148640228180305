import { styled, Box, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { LoadMoreProgress } from '~/shared/components/StyledComponents';

interface Props {
  numberShown?: number;
  totalNumber?: number;
  loading: boolean;
}

const TransactionsTableFooter = forwardRef<HTMLDivElement, Props>(
  ({ numberShown, totalNumber, loading }, ref) => {
    const numbersAreDefined = numberShown && totalNumber;

    return (
      <Container ref={ref}>
        {loading ? (
          <LoadMoreProgress />
        ) : (
          // space-between helper
          <Box />
        )}
        {numbersAreDefined ? (
          <Typography fontSize={10}>
            Showing {numberShown} of {totalNumber}
          </Typography>
        ) : (
          // space-between helper
          <Box />
        )}
      </Container>
    );
  },
);

TransactionsTableFooter.displayName = 'TransactionsTableFooter';
export default TransactionsTableFooter;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 54,
  padding: theme.spacing(0, 8, 0, 2),
}));
