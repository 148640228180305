import { forwardRef } from 'react';
import { CSSProperties } from 'react';
import { FormControl, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  label?: string;
  error?: Record<string, unknown>;
  name: string;
  isDisabled?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  variant?: 'filled' | 'outlined' | 'standard';
  requirements?: RegisterOptions;
  fullWidth?: boolean;
  value?: Date | null;
  minDate?: Date;
  onChange?: (date: Date | null) => void;
};

const DatePickerInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      error,
      margin,
      width,
      variant,
      isDisabled,
      value,
      fullWidth,
      minDate,
      onChange,
    },
    ref,
  ) => {
    const errorMessage =
      typeof error?.message === 'string' ? error.message : '';

    return (
      <FormControl
        variant={variant}
        sx={{ width, margin }}
        error={Boolean(error)}
        fullWidth={fullWidth}
      >
        <DatePicker
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          ref={ref}
          label={label}
          minDate={minDate}
        />
        {errorMessage && (
          <Typography color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  },
);

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
