import {
  styled,
  Stack,
  useTheme,
  Box,
  Typography,
  Skeleton,
} from '@mui/material';

const PieChartSkeleton = () => {
  const { spacing } = useTheme();

  return (
    <Layout>
      <Stack mr={spacing(6)}>
        <Typography mb={spacing(2.5)} fontSize={12}>
          Top Categories
        </Typography>
        {Array.from({ length: 4 }).map((_, i) => (
          <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Dot>
              <Skeleton variant="circular" width={7} height={7} />
            </Dot>
            <Skeleton variant="text" width={60} height={10} />
          </Box>
        ))}
      </Stack>
      <PieContainer>
        <Skeleton variant="circular" width={132} height={132} />
      </PieContainer>
    </Layout>
  );
};

export default PieChartSkeleton;

const Layout = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PieContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 152,
  height: 152,
  background: theme.palette.custom.lightGray,
  borderRadius: theme.shape.customBorderRadius['circle'],
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '7px',
  height: '7px',
  marginRight: theme.spacing(1.5),
  borderRadius: theme.shape.customBorderRadius['circle'],
}));
