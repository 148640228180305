import {
  styled,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { activeGrantModalVar } from '~/apollo';
import type { Client } from '~/graphql/gen/graphql';
import { ROUTES } from '~/shared/constants';
import ClientDAFSelect from './ClientDAFSelect';

interface Props {
  client: Client;
}

const ClientDropdownContent = ({ client }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const navigate = useNavigate();

  const handleMakeGrant = () => {
    activeGrantModalVar('advisor');
  };

  const hasDAFAccounts = client.DAFAccounts.length > 0;

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: isMobile ? 345 : 'auto',
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Typography fontSize={10} fontWeight={500} mr={4}>
            {hasDAFAccounts ? 'Viewing:' : 'No accounts to select'}
          </Typography>

          {hasDAFAccounts && <ClientDAFSelect client={client} />}
        </Box>

        {isMobile && (
          <Button variant="text" onClick={handleMakeGrant}>
            Make a Grant
          </Button>
        )}
      </Box>
      <Box>
        <StyledButton
          variant="outlined"
          onClick={() => navigate(ROUTES.ADVISOR.CLIENT_TRANSACTIONS)}
        >
          View Transactions
        </StyledButton>
        <StyledButton
          variant="outlined"
          sx={{ mx: isMobile ? 1 : 2 }}
          onClick={() => navigate(ROUTES.ADVISOR.CLIENT_GRANTS)}
        >
          View Grants
        </StyledButton>
        <StyledButton
          variant="outlined"
          onClick={() => navigate(ROUTES.ADVISOR.CLIENT_OVERVIEW)}
        >
          View Investment Overview
        </StyledButton>
      </Box>
    </Container>
  );
};

export default ClientDropdownContent;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  height: 47,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 116,
    alignItems: 'center',
    padding: theme.spacing(3, 0, 5),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: 10,
  padding: theme.spacing(1.5, 2),
  color: theme.palette.custom.purple,
  border: `1px solid ${theme.palette.custom.purple}`,
  '&:hover': {
    border: `1px solid ${theme.palette.custom.purple}`,
  },
}));
