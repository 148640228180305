import { gql } from '../gen';

export const GET_GRANTS_QUERY = gql(/* GraphQL */ `
  query GetGrants($input: GrantDAFInput!) {
    getGrants(input: $input) {
      id
      createdAt
      nonProfitName
      startDate
      amountMajorUnits
      currencyCodeISO4217
      period
      categories {
        id
        subjectCode
        label
      }
    }
  }
`);

export const GET_GRANT_CHART_DATA = gql(/* GraphQL */ `
  query GetGrantChartData($input: GrantDAFInput!) {
    getGrantChartData(input: $input) {
      label
      value
    }
  }
`);
