import {
  Typography,
  styled,
  Button,
  useTheme,
  SelectChangeEvent,
  useMediaQuery,
  Theme,
  Stack,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CreateGrantInput, Grant, PeriodEnum } from '~/graphql/gen/graphql';
import CurrencyInput from '~/shared/components/Forms/CurrencyInput';
import SelectInput from '~/shared/components/Forms/SelectInput';
import DatePickerInput from '~/shared/components/Forms/DatePicker';
import SearchInput from '~/shared/components/Forms/SearchInput';
import { CircularProgress } from '@mui/material';
import useSnackbar from '~/shared/hooks/useSnackbar';
import DataDogService from '~/services/datadog.service';
import { UPDATE_GRANT } from '~/graphql/mutations/grants';
import { activeDAFIdVar } from '~/apollo';
import { isPeriodEnum, getMinorUnitsFromString } from '~/shared/utils';
import { useState } from 'react';
import CancelGrant from './CancelGrant';
import { SnackbarVariant } from '~/shared/constants';
import Dialog from '~/shared/components/Dialog';

type Inputs = {
  amount: string;
  frequency: CreateGrantInput['period'];
  startDate?: Date;
};

interface Props {
  grant: Grant;
  isOpen: boolean;
  setGrants: React.Dispatch<React.SetStateAction<Grant[]>>;
  onClose: () => void;
}

const EditGrantModal = ({ isOpen, grant, setGrants, onClose }: Props) => {
  const { createSnackNotice } = useSnackbar();
  const { spacing, palette } = useTheme();
  const [showCancel, setShowCancel] = useState(false);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    reset,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      amount: grant.amountMajorUnits,
      frequency: grant.period,
      startDate: new Date(grant.startDate),
    },
  });
  const [updateGrant, { loading }] = useMutation(UPDATE_GRANT, {
    onCompleted: (data) => {
      createSnackNotice('UPDATES HAVE BEEN SAVED', SnackbarVariant.Success);
      setGrants((grants) => {
        return grants.map((grant) => {
          if (grant.id === data.updateGrant.id) {
            return data.updateGrant;
          }
          return grant;
        });
      });
    },
    update: (cache) => {
      // Invalidate to require network call new chart data
      cache.evict({ fieldName: 'getGrantChartData' });
      cache.gc();
    },
    onError: (error) => {
      createSnackNotice(
        'UPDATE FAILED, PLEASE TRY AGAIN',
        SnackbarVariant.Error,
      );
      DataDogService.logError(error);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const dafAccountId = activeDAFIdVar();
    if (!dafAccountId) {
      createSnackNotice(
        'UPDATE FAILED, PLEASE TRY AGAIN',
        SnackbarVariant.Error,
      );
      return;
    }

    let startDate = new Date(grant.startDate);
    if (data.frequency === PeriodEnum.Annual && data.startDate) {
      startDate = new Date(data.startDate);
    } else if (
      // If client switches from annual to monthly, set the start date to today
      data.frequency === PeriodEnum.Monthly &&
      grant.period === PeriodEnum.Annual
    ) {
      startDate = new Date();
    }

    updateGrant({
      variables: {
        input: {
          grantId: grant.id,
          dafAccountId,
          amountMinorUnits: getMinorUnitsFromString(data.amount),
          period: data.frequency,
          startDate,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  };

  const amount = watch('amount');
  const frequency = watch('frequency');
  const startDate = watch('startDate');

  const handleFrequency = (e: SelectChangeEvent) => {
    if (!isPeriodEnum(e.target.value)) {
      return;
    }
    setValue('frequency', e.target.value);
  };

  const handleClose = () => {
    onClose();
    // Reset modal state after close animation is complete
    setTimeout(() => {
      setShowCancel(false);
      reset({
        amount: grant.amountMajorUnits,
        frequency: grant.period,
        startDate: new Date(grant.startDate),
      });
    }, 200);
  };

  const autoCompleteValue = { label: grant.nonProfitName, value: grant };
  return (
    <Dialog isOpen={isOpen} handleClose={handleClose}>
      {showCancel ? (
        <CancelGrant
          grant={grant}
          onClose={handleClose}
          setGrants={setGrants}
        />
      ) : (
        <Stack>
          <Typography variant="h1" mb={spacing(2)}>
            Edit Recurring Grant
          </Typography>
          <Typography
            textAlign="center"
            fontSize="14px"
            lineHeight={1}
            mb={spacing(6)}
          >
            Make changes to your recurring grant.
          </Typography>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <SearchInput
              label={'Nonprofit'}
              name="nonProfit"
              width={isSmall ? '100%' : '90%'}
              margin={spacing(0, 0, 6)}
              value={autoCompleteValue}
              onChange={() => {}}
              options={[autoCompleteValue]}
              isDisabled
            />
            <SelectInput
              requirements={{ required: 'This is a required field' }}
              label={'Frequency'}
              name="frequency"
              error={errors.frequency}
              width={isSmall ? '100%' : '90%'}
              margin={spacing(0, 0, 6)}
              ref={
                register('frequency', {
                  required: 'Please select a giving frequency',
                }).ref
              }
              value={frequency ?? grant.period}
              onChange={handleFrequency}
              options={[
                { label: 'Monthly', value: PeriodEnum.Monthly },
                { label: 'Annual', value: PeriodEnum.Annual },
              ]}
            />
            <CurrencyInput
              label={'Amount to Grant'}
              name="amount"
              type="currency"
              error={errors.amount}
              startAdornmentSymbol="$"
              width={isSmall ? '100%' : '90%'}
              margin={spacing(0, 0, 6)}
              ref={
                register('amount', {
                  required: 'This is a required field',
                  validate: (value) =>
                    Number(value.replace(/[$,]/g, '')) > 0 ||
                    'Amount must be greater than $0.00',
                }).ref
              }
              value={amount}
              onChange={(e) => setValue('amount', e.target.value)}
            />
            {frequency === PeriodEnum.Annual && (
              <DatePickerInput
                label={'Start Date'}
                name="startDate"
                error={errors.startDate}
                width={isSmall ? '100%' : '90%'}
                margin={spacing(0, 0, 6)}
                ref={
                  register('startDate', {
                    required:
                      frequency === PeriodEnum.Annual
                        ? 'Please select a start date'
                        : false,
                  }).ref
                }
                value={startDate}
                onChange={(date) => setValue('startDate', date || undefined)}
              />
            )}
            <Typography fontSize={12} lineHeight={1.1} mb={spacing(4)}>
              All requests for grants are reviewed by our team to ensure they
              meet all applicable guidelines and regulations. If you have
              questions about your donation, please consult our team.
            </Typography>
            <Button
              type="submit"
              variant="main"
              fullWidth
              sx={{
                height: 69,
                fontSize: '16px',
                mb: spacing(isSmall ? 3 : 6),
              }}
            >
              {loading ? <Progress /> : 'Save Changes'}
            </Button>
            <CancelButton disableRipple onClick={() => setShowCancel(true)}>
              <Typography
                fontSize={15}
                color={palette.error.main}
                fontWeight={'bold'}
              >
                Cancel Recurring Grant
              </Typography>
            </CancelButton>
          </Form>
        </Stack>
      )}
    </Dialog>
  );
};

export default EditGrantModal;

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: 0,
});

const Progress = styled(CircularProgress)(({ theme }) => ({
  '& .MuiCircularProgress-bar': {
    color: theme.palette.common.black,
  },
  color: 'lightgrey',
  width: 20,
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 700,
  fontSize: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
}));
