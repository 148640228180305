import {
  Button,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { shouldNotForwardPropsWithKeys } from '../utils';
import { CSSProperties } from 'react';

interface GrayContainerProps {
  padding?: CSSProperties['padding'];
}
export const GrayContainer = styled(Stack, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<GrayContainerProps>([
    'padding',
  ]),
})<GrayContainerProps>(({ theme, padding = '22px' }) => ({
  padding,
  backgroundColor: theme.palette.custom.lightGray,
  borderTopLeftRadius: theme.shape.customBorderRadius['20'],
  borderTopRightRadius: theme.shape.customBorderRadius['20'],
  alignItems: 'center',
  flex: 1,
}));

interface ControlProps {
  isActive: boolean;
  padding?: CSSProperties['padding'];
}

export const TableControlsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(4, 4, 4, 5.5),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(5, 2, 4),
  },
}));

export const TableControlButton = styled(Button, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<ControlProps>([
    'isActive',
    'padding',
  ]),
})<ControlProps>(({ theme, isActive, padding }) => ({
  borderRadius: theme.shape.customBorderRadius['100'],
  padding: padding ?? theme.spacing(1.5, 3),
  marginRight: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    padding: padding ?? theme.spacing(1.5, 4),
    background: isActive ? theme.palette.custom.purple : 'transparent',
    color: isActive ? theme.palette.common.white : theme.palette.common.black,
    '&:hover': {
      background: theme.palette.custom.purpleHover,
    },
  },
}));

export const TableButtonText = styled(Typography, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<ControlProps>(['isActive']),
})<ControlProps>(({ theme, isActive }) => ({
  fontSize: 14,
  fontWeight: isActive ? 700 : 400,
  textDecoration: isActive ? 'underline' : 'none',
  [theme.breakpoints.down('md')]: {
    textDecoration: 'none',
    color: isActive ? theme.palette.common.white : theme.palette.common.black,
  },
}));

export const LoadMoreProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.common.black,
  },
  backgroundColor: 'lightgrey',
  width: 55,
}));
