import { useReactiveVar } from '@apollo/client';
import {
  FormControl,
  Select,
  MenuItem,
  styled,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import { CSSProperties } from 'react';
import { activeDAFIdVar, selectedClientVar } from '~/apollo';
import { Client } from '~/graphql/gen/graphql';
import { SnackbarVariant } from '~/shared/constants';
import useSnackbar from '~/shared/hooks/useSnackbar';

interface AccountSelectProps {
  client: Client;
  padding?: CSSProperties['padding'];
}

const ClientDAFSelect = ({ client, padding }: AccountSelectProps) => {
  const selectedClient = useReactiveVar(selectedClientVar);
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const { createSnackNotice } = useSnackbar();
  const { spacing } = useTheme();

  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value !== 'number') {
      createSnackNotice(
        'Unable to select account. Please Reload and try again.',
        SnackbarVariant.Error,
      );
      return;
    }

    activeDAFIdVar(e.target.value as number);
  };

  if (!client || !client.DAFAccounts.some(({ id }) => id === activeDAFId)) {
    return null;
  }

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{ padding: padding ?? spacing(0, 4, 0, 0) }}
    >
      <StyledSelect
        labelId="daf-account-input"
        label="DAF Account"
        onChange={handleSelect}
        value={activeDAFId || selectedClient?.DAFAccounts[0]?.id || ''}
        variant="outlined"
        notched={false}
        MenuProps={{
          PaperProps: {
            sx: {
              '& li': {
                fontSize: '10px',
              },
            },
          },
        }}
      >
        {client.DAFAccounts.map((account) => {
          if (!account.id) {
            return null;
          }

          return (
            <MenuItem key={account.id} value={account.id}>
              {account.accountName}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default ClientDAFSelect;

const StyledSelect = styled(Select)(({ theme }) => ({
  width: 170,
  fontSize: '10px',
  fontWeight: 'normal',
  background: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    width: 150,
  },
  [theme.breakpoints.down('sm')]: {
    width: 170,
  },
}));
