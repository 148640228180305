const FavoriteFilledIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  height = '28',
  width = '32',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.851.691c-2.796 0-5.245 1.234-6.771 3.318C14.554 1.925 12.105.691 9.309.691a8.302 8.302 0 0 0-5.935 2.525A8.733 8.733 0 0 0 .912 9.302c0 9.721 14.056 17.59 14.655 17.915a1.063 1.063 0 0 0 1.026 0c.599-.325 14.654-8.194 14.654-17.915a8.733 8.733 0 0 0-2.462-6.086A8.302 8.302 0 0 0 22.851.691z"
        fill="#FF4A4A"
      />
    </svg>
  );
};

export default FavoriteFilledIcon;
