import { useQuery } from '@apollo/client';
import { styled, Box } from '@mui/material';
import { GET_CLIENTS_TOTALS_QUERY } from '~/graphql/queries/clients';
import TotalsBox from '~/shared/components/TotalsBox';

const ClientsSummary = () => {
  const { data } = useQuery(GET_CLIENTS_TOTALS_QUERY, {
    variables: {
      input: {
        advisorId: 1,
      },
    },
  });

  return (
    <Container>
      <TotalsContainer>
        <TotalsBox
          width={'24%'}
          key={'accountBalance'}
          title={"Client's Total Account Balance"}
          total={data?.getClientsTotals?.totalAccountsBalanceMajorUnits}
        />
        <TotalsBox
          width={'24%'}
          key={'ytdGrants'}
          title={"Client's Year to Date Grants"}
          total={data?.getClientsTotals?.totalAccountsGrantsYTDMajorUnits}
        />
        <TotalsBox
          width={'24%'}
          key={'allTimeGrants'}
          title={"Client's All Time Total Grants"}
          total={data?.getClientsTotals?.totalAccountsGrantsMajorUnits}
        />
        <TotalsBox
          width={'24%'}
          key={'numberOfClients'}
          title={'Clients Managed'}
          total={String(data?.getClientsTotals?.numberOfClients ?? '-')}
        />
      </TotalsContainer>
    </Container>
  );
};

export default ClientsSummary;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  padding: theme.spacing(0, 5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const TotalsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(2),
  maxWidth: 1300,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(0),
  },
}));
