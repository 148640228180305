import { z } from 'zod';

const envSchema = z.object({
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_AUDIENCE: z.string(),
  VITE_GRAPHQL_URL: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_DATA_DOG_CLIENT_TOKEN: z.string(),
  VITE_DATA_DOG_SERVICE_NAME: z.string(),
  VITE_SHOW_ADVISOR_CHECKBOX: z.string(),
  VITE_SUPPORT_EMAIL: z.string(),
  VITE_MAPBOX_KEY: z.string(),
});

const env = envSchema.parse(import.meta.env);

export const AUTH0_DOMAIN = env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = env.VITE_AUTH0_AUDIENCE;
export const GRAPHQL_URL = env.VITE_GRAPHQL_URL;
export const SENTRY_DSN = env.VITE_SENTRY_DSN;
export const DATA_DOG_CLIENT_TOKEN = env.VITE_DATA_DOG_CLIENT_TOKEN;
export const DATA_DOG_SERVICE_NAME = env.VITE_DATA_DOG_SERVICE_NAME;
export const SHOW_ADVISOR_CHECKBOX = env.VITE_SHOW_ADVISOR_CHECKBOX === 'true';
export const SUPPORT_EMAIL = env.VITE_SUPPORT_EMAIL;
export const MAPBOX_KEY = env.VITE_MAPBOX_KEY;
