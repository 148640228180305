import { Box, Card as MuiCard, Skeleton, styled } from '@mui/material';

const SkeletonCard = () => {
  return (
    <Container>
      <SkeletonHeader>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </SkeletonHeader>
      <SkeletonContent>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="50%"
          sx={{ mb: '16px' }}
        />
        <Skeleton variant="rectangular" width="100%" height="20%" />
      </SkeletonContent>
      <ButtonContainer>
        <SkeletonButton variant="rounded" />
      </ButtonContainer>
    </Container>
  );
};

export default SkeletonCard;

const Container = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '292px',
  maxWidth: '380px',
  minWidth: '255px',
  borderRadius: '0',
  boxShadow: '0 4px 5px 0 rgb(0, 0, 0, 0.06)',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: '230px',
    minWidth: '155px',
  },
}));

const SkeletonHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '70px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    minHeight: '45px',
  },
}));

const SkeletonContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(0, 3.5, 7),
}));

const SkeletonButton = styled(Skeleton)({
  height: '50px',
  borderRadius: '45px',
});
