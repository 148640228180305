import React, { forwardRef } from 'react';
import { CSSProperties } from 'react';
import {
  Autocomplete,
  TextField,
  FormControl,
  Typography,
} from '@mui/material';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  label?: string;
  error?: Record<string, unknown>;
  name: string;
  isDisabled?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  variant?: 'filled' | 'outlined' | 'standard';
  requirements?: RegisterOptions;
  value?: { label: string; value: unknown } | null;
  onChange?: (
    event: React.SyntheticEvent,
    value: { label: string; value: unknown } | null,
  ) => void;
  options: { label: string; value: unknown }[];
};

const SearchInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      error,
      name,
      margin,
      width,
      variant,
      value,
      isDisabled,
      onChange,
      options,
    },
    ref,
  ) => {
    const errorMessage =
      typeof error?.message === 'string' ? error.message : '';

    // TODO: Add search logic BRI-132

    return (
      <FormControl
        variant={variant}
        fullWidth
        sx={{ width, margin }}
        error={Boolean(error)}
      >
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={value}
          popupIcon={null}
          onChange={onChange}
          disabled={isDisabled}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              inputRef={ref}
              label={label}
              variant={variant}
              error={Boolean(error)}
              helperText={errorMessage}
              fullWidth
            />
          )}
        />
        {errorMessage && (
          <Typography color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
