const CloseIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="31"
        height="31"
        rx="9"
        fill="#000"
        fillOpacity=".2"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.51 17.599 4.436 4.436a.784.784 0 0 0 1.108-1.109l-4.436-4.436 4.437-4.436a.784.784 0 0 0-1.11-1.11l-4.436 4.437-4.436-4.436a.784.784 0 0 0-1.108 1.109L15.4 16.49l-4.436 4.436a.784.784 0 1 0 1.109 1.11l4.436-4.437z"
        fill="#fff"
      />
    </svg>
  );
};

export default CloseIcon;
