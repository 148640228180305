export const shape = {
  borderRadius: 4,
  customBorderRadius: {
    5: '5px',
    10: '10px',
    20: '20px',
    35: '35px',
    50: '50px',
    100: '100px',
    circle: '50%',
  },
};
