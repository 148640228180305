import { useCallback, useRef, useState } from 'react';
import {
  Box,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  activeDAFIdVar,
  clientVar,
  isLoggingInVar,
  selectedClientVar,
} from '~/apollo';
import { useReactiveVar } from '@apollo/client';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import { theme } from '~/shared/styles/theme';
import { HEADER_CONTENT, ROUTES } from '~/shared/constants';
import type { HeaderContent } from '~/shared/types';
import Profile from './Profile';
import MobileMenu from '~/modules/navigation/MobileMenu';
import { checkRouteValidity } from '~/shared/utils';
import LogoAndTag from './LogoAndTag';

// Check whether the path is a valid key of the HEADER_CONTENT object
const isValidPath = (path: string): path is keyof HeaderContent => {
  return path in HEADER_CONTENT;
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuIconRef = useRef<HTMLDivElement>(null);
  const isLoggingIn = useReactiveVar(isLoggingInVar);
  const client = useReactiveVar(clientVar);
  const selectedClient = useReactiveVar(selectedClientVar);
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const location = useLocation();
  const { spacing } = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const isSmallMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const path = location.pathname;
  const today = new Date().toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const headerContent = isValidPath(path)
    ? HEADER_CONTENT[path]
    : { title: '', description: '' };

  const isNonProfits = path === ROUTES.NON_PROFITS;
  const isDashboard = path === ROUTES.DASHBOARD;
  const isValidRoute = checkRouteValidity(path);
  const showBalance = !isNonProfits && isValidRoute;
  const showGrayBackground = isDashboard || (!isValidRoute && !isMobile);

  const headerTitle = isDashboard
    ? `${headerContent.title} ${client?.firstName ? client.firstName : ''}`
    : headerContent.title;

  const dynamicDescription = useCallback(
    (description: string) => {
      let dynamicDescription = description;
      if (selectedClient) {
        dynamicDescription = dynamicDescription.replace(
          '{{name}}',
          `${selectedClient.firstName} ${selectedClient.lastName}`,
        );
      }
      if (activeDAFId) {
        const activeDAF = selectedClient?.DAFAccounts.find(
          (account) => account.id === activeDAFId,
        );
        dynamicDescription = dynamicDescription.replace(
          '{{account}}',
          `${activeDAF?.accountName} ${activeDAF?.externalAccountId}`,
        );
      }
      return dynamicDescription;
    },
    [selectedClient, activeDAFId],
  );

  if (isLoggingIn) {
    return null;
  }

  if (isMobile) {
    return (
      <Stack
        justifyContent="space-between"
        padding={spacing(3, 5, 4)}
        sx={{
          backgroundColor: showGrayBackground
            ? theme.palette.custom.lightGray
            : theme.palette.common.white,
        }}
      >
        <MobileTopContainer>
          <Box display="flex" alignItems="center">
            <Box ref={menuIconRef} display="flex" alignItems="center">
              <MenuIcon
                size={isSmallMobile ? 24 : 30}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
            </Box>
            <LogoAndTag
              width={isSmallMobile ? 90 : 100}
              margin={spacing(3, 0, 0, 4)}
            />
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {isMenuOpen && (
              <MobileMenu
                closeMenu={() => setIsMenuOpen(false)}
                isOpen={isMenuOpen}
                iconRef={menuIconRef}
              />
            )}
            {showBalance && (
              <Typography fontSize="10px" fontStyle="italic" mr={4}>
                Balance as of: {today}
              </Typography>
            )}
            <Profile />
          </Box>
        </MobileTopContainer>
        <Stack direction="column">
          <Typography
            fontSize="28px"
            fontWeight="bold"
            color={theme.palette.text.darkBlue}
            lineHeight={1.1}
          >
            {headerTitle}
          </Typography>
          <Typography fontSize="14px" color={theme.palette.custom.darkTeal}>
            {dynamicDescription(headerContent.description)}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      padding={theme.spacing(5.5, 7.5, 6, 5.5)}
      sx={{
        backgroundColor: showGrayBackground
          ? theme.palette.custom.lightGray
          : theme.palette.common.white,
      }}
    >
      <Stack direction="column">
        <Typography
          fontSize="28px"
          fontWeight="bold"
          color={theme.palette.text.darkBlue}
        >
          {headerTitle}
        </Typography>
        <Typography fontSize="14px" color={theme.palette.custom.darkTeal}>
          {dynamicDescription(headerContent.description)}
        </Typography>
      </Stack>
      <Stack direction="row" gap={theme.spacing(3.5)} alignItems="baseline">
        {showBalance && (
          <Typography fontSize="10px" fontStyle="italic">
            Balance as of: {today}
          </Typography>
        )}
        <Profile />
      </Stack>
    </Stack>
  );
};

export default Header;

const MobileTopContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const MenuIcon = styled(RxHamburgerMenu)({
  cursor: 'pointer',
  userSelect: 'none',
});
