import React, { forwardRef } from 'react';
import { CSSProperties } from 'react';
import { FormControl, TextField } from '@mui/material';
import { RegisterOptions } from 'react-hook-form';

type Props = {
  placeholder?: string;
  label?: string;
  error?: Record<string, unknown>;
  name: string;
  isDisabled?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  fullWidth?: boolean;
  multilineLabel?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  requirements?: RegisterOptions;
  startAdornmentSymbol?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
};

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      label,
      error,
      name,
      margin,
      width,
      fullWidth,
      variant,
      value,
      isDisabled,
      onChange,
      backgroundColor,
    },
    ref,
  ) => {
    const errorMessage =
      typeof error?.message === 'string' ? error.message : '';

    return (
      <FormControl
        variant={variant}
        sx={{ width, margin }}
        error={Boolean(error)}
        fullWidth={fullWidth}
      >
        <TextField
          label={label}
          inputRef={ref}
          name={name}
          placeholder={placeholder}
          variant={variant}
          error={Boolean(error)}
          helperText={errorMessage}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          fullWidth
          sx={{
            ...(backgroundColor && { backgroundColor }),
          }}
        />
      </FormControl>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
