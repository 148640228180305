import { gql } from '../gen';

export const NON_PROFIT_QUERY = gql(/* GraphQL */ `
  query GetNonProfits($input: NonProfitsInput!) {
    getNonProfits(input: $input) {
      nonProfits {
        EIN
        imageUrl
        categories {
          subjectCode
          label
        }
        cardImage {
          subjectCode
          index
        }
        title
        description
        addressLine1
        addressLine2
        city
        state
        zip
        country
        employeeNumber
        annualBudget
        ageOfNonProfit
        link
      }
      pagesRemaining
    }
  }
`);

export const ADVISOR_NON_PROFIT_QUERY = gql(/* GraphQL */ `
  query GetAdvisorNonProfits($input: GetAdvisorNonProfitsInput!) {
    getAdvisorNonProfits(input: $input) {
      nonProfits {
        EIN
        title
        description
        addressLine1
        addressLine2
        city
        state
        zip
        country
        categories {
          subjectCode
          label
        }
      }
    }
  }
`);
