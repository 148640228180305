import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { selectedNonProfitVar } from '~/apollo';
import BudgetIcon from '~/assets/icons/BudgetIcon';

const AnnualBudget = () => {
  const selectedNonProfit = useReactiveVar(selectedNonProfitVar);

  if (!selectedNonProfit) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <BudgetIcon />
      <Stack>
        <Typography fontSize="10px" fontWeight="bold">
          Annual Budget:
        </Typography>
        <Typography fontSize="10px">
          {selectedNonProfit.annualBudget === null
            ? 'N/A'
            : `$${selectedNonProfit.annualBudget.toLocaleString()}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AnnualBudget;
