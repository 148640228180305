import { styled, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { CSSProperties } from 'react';

interface Props {
  total?: string | null;
  title: string;
  stayBox?: boolean;
  width?: CSSProperties['width'];
}

const TotalsBox = ({ total, title, stayBox, width }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  if (isMobile && !stayBox) {
    return (
      <Stack width={'100%'}>
        <TitleText>{title}</TitleText>
        <MobileBox>
          <TotalText>{total ?? '-'}</TotalText>
        </MobileBox>
      </Stack>
    );
  }
  return (
    <Box width={width ?? '33%'}>
      <TotalText>{total ?? '-'}</TotalText>
      <TitleText>{title}</TitleText>
    </Box>
  );
};

export default TotalsBox;

const Box = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 135, // wide enough for an amount in the 100s of millions
  height: 108,
  borderRadius: theme.shape.customBorderRadius['10'],
  background: theme.palette.custom.sageGreen,
  [theme.breakpoints.down('md')]: {
    minWidth: 200,
  },
}));

const MobileBox = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: 52,
  background: theme.palette.custom.sageGreen,
  borderRadius: theme.shape.customBorderRadius['10'],
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: 17,
  fontWeight: 700,
  color: theme.palette.custom.darkTeal,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  width: '75%',
  maxWidth: 120,
  color: theme.palette.custom.darkTeal,
  textAlign: 'center',
  lineHeight: 1,
  [theme.breakpoints.down('md')]: {
    textAlign: 'start',
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '100%',
  },
}));
