import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import TableHeader from './TableHeader';

const TableSkeleton = () => {
  return (
    <TableContainer>
      <Table>
        <TableHeader />

        <TableBody>
          {Array.from(new Array(5)).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="circular" width={24} height={24} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
