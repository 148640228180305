import SearchBar from '~/shared/components/SearchBar';
import { TableControlsContainer } from '~/shared/components/StyledComponents';

interface Props {
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  handleClear: () => void;
  searchText: string;
  setSearchText: (value: string) => void;
}

const TableControls = ({
  handleKeyDown,
  handleClear,
  searchText,
  setSearchText,
}: Props) => {
  return (
    <TableControlsContainer>
      <SearchBar
        handleKeyDown={handleKeyDown}
        handleClear={handleClear}
        searchTerm={searchText}
        setSearchTerm={setSearchText}
        placeholder="Search..."
      />
    </TableControlsContainer>
  );
};

export default TableControls;
