import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Stack,
  styled,
} from '@mui/material';
import type { Theme } from '@mui/material';
import type { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';
import CardButton from '../../CardButton';
import { theme } from '~/shared/styles/theme';
import { Link } from 'react-router-dom';
import EmptyState from './EmptyState';

interface Props {
  data: GetClientDashboardQuery;
}

const RecurringGrantsCard = ({ data }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  if (!data.getClientDashboard.recurringGrantsCount) {
    return <EmptyState />;
  }

  return (
    <Grid item xs={12}>
      <DashboardCard
        sx={{
          color: theme.palette.custom.darkTeal,
          '.MuiCardContent-root': {
            paddingBottom: theme.spacing(4),
          },
        }}
      >
        <Stack height="100%">
          {!isMobile && (
            <SectionHeading
              sx={{
                mb: 6.5,
              }}
            >
              Recurring Grants
            </SectionHeading>
          )}
          {isMobile && (
            <Stack direction="row" justifyContent="space-between">
              <SectionHeading
                sx={{
                  mb: 4,
                }}
              >
                Recurring Grants
              </SectionHeading>
              <Link to="/grants?filter=recurring">
                <CardButton text="View All" sx={{ fontSize: '11px', mb: 2 }} />
              </Link>
            </Stack>
          )}
          <Typography
            variant="h2"
            lineHeight={1}
            mb={{ md: 0, xs: 1 }}
            px={{ xs: 0, md: 4 }}
            sx={{
              fontSize: { md: '24px', xs: '20px' },
              textAlign: { md: 'left', xs: 'center' },
            }}
          >
            {data.getClientDashboard.recurringGrantsCount}
            {data.getClientDashboard.recurringGrantsCount === 1
              ? ' recurring grant '
              : ' recurring grants '}
            <Box component="span" fontWeight={400}>
              {data.getClientDashboard.recurringGrantsCount === 1
                ? 'is '
                : 'are '}
              on autopilot.
            </Box>
          </Typography>
          <Typography
            lineHeight={1}
            mb="auto"
            px={{ xs: 0, md: 4 }}
            sx={{
              fontSize: {
                md: '24px',
                xs: '20px',
              },
              textAlign: { md: 'left', xs: 'center' },
            }}
          >
            Nice job, keep supporting your chosen causes!
          </Typography>
          {!isMobile && (
            <ViewAllLink to="/grants?filter=recurring">
              <CardButton text="View All" sx={{ fontSize: '11px' }} />
            </ViewAllLink>
          )}
        </Stack>
      </DashboardCard>
    </Grid>
  );
};

export default RecurringGrantsCard;

const ViewAllLink = styled(Link)({
  alignSelf: 'flex-end',
});
