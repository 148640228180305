import React, { forwardRef } from 'react';
import { CSSProperties } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { RegisterOptions } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type Props = {
  placeholder?: string;
  label?: string;
  error?: Record<string, unknown>;
  name: string;
  isDisabled?: boolean;
  margin?: CSSProperties['margin'];
  width?: CSSProperties['width'];
  multilineLabel?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  requirements?: RegisterOptions;
  type?: string;
  startAdornmentSymbol?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CurrencyInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      label,
      error,
      name,
      isDisabled,
      margin,
      width,
      variant,
      startAdornmentSymbol,
      value,
      onChange,
    },
    ref,
  ) => {
    const errorMessage =
      typeof error?.message === 'string' ? error.message : '';

    return (
      <NumericFormat
        customInput={TextField}
        inputRef={ref}
        name={name}
        placeholder={placeholder}
        label={label}
        variant={variant}
        error={Boolean(error)}
        helperText={errorMessage}
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        allowNegative={false}
        InputProps={{
          ...(startAdornmentSymbol && {
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={{ fontWeight: 500, color: 'black' }}>
                  {startAdornmentSymbol}
                </Typography>
              </InputAdornment>
            ),
          }),
        }}
        sx={{
          width,
          margin,
        }}
        fullWidth
      />
    );
  },
);

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
