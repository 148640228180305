import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import type { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import { theme } from '~/shared/styles/theme';
import { palette } from '~/shared/styles/theme/palette';
import CardButton from '../CardButton';

interface Props {
  data: GetClientDashboardQuery;
}

const AccountSection = ({ data }: Props) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        order: { xs: 1, md: 2 },
      }}
    >
      <Stack
        padding={theme.spacing(5, 6)}
        borderRadius="10px"
        sx={{ backgroundColor: palette.common.white }}
      >
        <Box mb={3.5}>
          <Typography
            variant="body2"
            fontSize="12px"
            color={theme.palette.custom.darkTeal}
            mb={{ xs: 1, md: 2 }}
          >
            Your account balance total is{' '}
          </Typography>
          <TotalContainer>
            <TotalText>
              {data?.getClientDashboard.accountBalanceMajorUnits || '$0'}
            </TotalText>
          </TotalContainer>
        </Box>
        <Box sx={{ backgroundColor: palette.common.white }} mb={5}>
          <Typography
            variant="body2"
            fontSize="12px"
            color={theme.palette.custom.darkTeal}
            mb={{ xs: 1, md: 2 }}
          >
            You have granted a total of{' '}
          </Typography>
          <TotalContainer>
            <TotalText>
              {data?.getClientDashboard.grantsTotalMajorUnits || '$0'}
            </TotalText>
          </TotalContainer>
        </Box>
        <Link to="/transactions">
          <CardButton text="View Transactions" sx={{ width: '100%' }} />
        </Link>
      </Stack>
    </Grid>
  );
};

export default AccountSection;

const TotalContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  borderRadius: theme.shape.customBorderRadius['5'],
  background: theme.palette.custom.sageGreen,
  padding: theme.spacing(3.5, 0, 3.5, 3),
}));

const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.custom.darkTeal,
}));
