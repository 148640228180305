import { Stack, Box, Skeleton, useMediaQuery, Theme } from '@mui/material';
import { Cell, Pie, PieChart } from 'recharts';
import BalanceCircle from './BalanceCircle';
import { theme } from '~/shared/styles/theme';

interface Props {
  chartData: { label: string; value: number }[];
  totalBalance: string;
  loading: boolean;
  colors: string[];
}

const OverviewPieChart = ({
  chartData,
  totalBalance,
  loading,
  colors,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const CHART_RADIUS = 146;
  const CHART_SIZE = 316;

  return (
    <Stack
      pl={isMobile ? 0 : 2}
      pt={isMobile ? 0 : 7}
      mr={isMobile ? 0 : 8}
      mb={isMobile ? theme.spacing(5) : 8}
      alignItems="center"
    >
      {loading && (
        <Skeleton variant="circular" width={CHART_SIZE} height={CHART_SIZE} />
      )}
      {!loading && (
        <Box position="relative" width={CHART_SIZE} height={CHART_SIZE}>
          <PieChart width={316} height={316} style={{ pointerEvents: 'none' }}>
            {/* Outer pie for the gray ring around the chart */}
            <Pie
              data={[{ value: 1 }]} // placeholder data
              dataKey="value"
              startAngle={90}
              endAngle={450}
              outerRadius={156}
              innerRadius={CHART_RADIUS}
              fill="#EBECE9"
              stroke="none"
              isAnimationActive={false}
            />

            <Pie
              data={chartData}
              dataKey="value"
              nameKey="label"
              cursor="cursor"
              labelLine={false}
              stroke={'none'}
              startAngle={90}
              endAngle={450}
              outerRadius={CHART_RADIUS}
            >
              {chartData.map(({ label }, index) => (
                <Cell
                  key={`cell-${label}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>

          <BalanceCircle totalBalance={totalBalance} />
        </Box>
      )}
    </Stack>
  );
};

export default OverviewPieChart;
