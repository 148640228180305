import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import TableHeader from './TableHeader';

const TableSkeleton = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <TableContainer>
      <Table>
        <TableHeader />

        <TableBody>
          {Array.from(new Array(10)).map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              {!isMobile && (
                <TableCell>
                  <Skeleton variant="text" width="100%" />
                </TableCell>
              )}
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width="100%" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
