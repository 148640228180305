import {
  TableCell as MuiTableCell,
  styled,
  TableHead,
  TableRow,
} from '@mui/material';

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ paddingLeft: 6 }}>Nonprofit</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Frequency</TableCell>
        <TableCell>Start Date</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell align="center" sx={{ paddingRight: 6, maxWidth: 115 }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0, 2, 2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
  },
}));
