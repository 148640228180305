import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import { Category, PeriodEnum } from '~/graphql/gen/graphql';
import { ROUTES, topLevelSubjectCodes } from './constants';

export const shouldNotForwardPropsWithKeys =
  <CustomProps>(props: Array<keyof CustomProps>) =>
  (propName: PropertyKey): boolean =>
    !props.map((p) => p.toString()).includes(propName.toString());

export const checkIsAuth0Redirect = () => {
  if (
    // Auth0 redirect returns url with these query params:
    window.location.search.includes('code=') &&
    window.location.search.includes('state=')
  ) {
    return true;
  }
  return false;
};

export const getMinorUnitsFromString = (amount: string) => {
  if (!amount || amount.trim() === '') {
    return 0;
  }

  // Remove whitespace, dollar sign, and commas
  const cleanedAmount = amount.trim().replace(/[$,]/g, '');

  // Check if there is a decimal point
  if (cleanedAmount.includes('.')) {
    // Convert to number directly without adding extra zeros
    return Math.round(parseFloat(cleanedAmount) * 100);
  } else {
    // Multiply by 100 to convert whole dollars to cents
    return Number(cleanedAmount) * 100;
  }
};

export const isPeriodEnum = (value: string): value is PeriodEnum => {
  return Object.values(PeriodEnum).includes(value as PeriodEnum);
};

export const getLabel = (suggestion: SearchBoxSuggestion | null) => {
  if (!suggestion) {
    return '';
  }

  if (suggestion.context.region) {
    return `${suggestion.name}, ${suggestion.context.region.name}`;
  }
  return suggestion.name;
};

export const getTopLevelCategories = (nonProfCategories: Category[]) => {
  return nonProfCategories.filter((category) =>
    topLevelSubjectCodes.includes(category.subjectCode),
  );
};

export const checkRouteValidity = (path: string) => {
  const getAllValues = (
    obj: Record<string, string | Record<string, string>>,
  ): string[] => {
    return Object.values(obj).reduce((acc: string[], value) => {
      if (typeof value === 'object' && value !== null) {
        return [...acc, ...getAllValues(value)];
      }
      return [...acc, value];
    }, []);
  };

  const allRoutes = getAllValues(ROUTES);
  return allRoutes.includes(path);
};
