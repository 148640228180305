import {
  Card,
  CardContent,
  Typography,
  Box,
  styled,
  Stack,
} from '@mui/material';
import { Cell, Pie, PieChart } from 'recharts';
import { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import CustomLabel from '~/shared/components/Charts/PieChartLabel';
import { theme } from '~/shared/styles/theme';
import { palette } from '~/shared/styles/theme/palette';
import EmptyState from './EmptyState';

interface Props {
  data: GetClientDashboardQuery;
}

const CategoriesCard = ({ data }: Props) => {
  const chartData = data.getClientDashboard.grantChartData
    .filter((item): item is NonNullable<typeof item> => item != null)
    .map((item) => ({
      label: item.label,
      value: item.value,
    }));

  const COLORS = [
    palette.custom.harvestYellow,
    palette.custom.softGreen,
    palette.custom.periwinkle,
    palette.custom.pewter,
  ];

  if (!chartData.length) {
    return <EmptyState />;
  }

  return (
    <Card elevation={0} sx={{ height: '100%' }}>
      <CardContent sx={{ color: theme.palette.custom.darkTeal }}>
        <Typography
          variant="h2"
          fontSize={{
            xs: '20px',
            md: '24px',
          }}
        >
          Most Supported Nonprofit Categories So Far:
        </Typography>
        <Stack
          direction={{ xs: 'row', md: 'column' }}
          minHeight="314px"
          py={4}
          alignItems="center"
        >
          <Box mb={4} alignSelf="flex-start">
            {chartData.map((category, i) => {
              return (
                <Box
                  key={category.label}
                  sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                >
                  <Dot sx={{ background: COLORS[i] }} />
                  <CategoryLabel color="black">{category.label}</CategoryLabel>
                </Box>
              );
            })}
          </Box>
          <PieChart width={184} height={184} style={{ pointerEvents: 'none' }}>
            <Pie
              dataKey="value"
              nameKey="label"
              data={chartData}
              fill="#8884D8"
              cursor="cursor"
              label={CustomLabel}
              labelLine={false}
              stroke="none"
              startAngle={90}
              endAngle={450}
              outerRadius={92}
            >
              {chartData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CategoriesCard;

const Dot = styled(Box)(({ theme }) => ({
  width: '11px',
  height: '11px',
  marginRight: theme.spacing(1.5),
  borderRadius: theme.shape.customBorderRadius['circle'],
  border: `1px solid ${theme.palette.custom.darkTeal}`,
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '10px',
  fontWeight: 500,
  textTransform: 'capitalize',
}));
