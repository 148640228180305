import { styled, Stack } from '@mui/material';
import { GrayContainer } from '~/shared/components/StyledComponents';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { GET_CLIENTS_QUERY } from '~/graphql/queries/clients';
import { Fragment, KeyboardEvent, useRef, useState } from 'react';
import { Client } from '~/graphql/gen/graphql';
import ClientsTableFooter from '~/modules/advisor/components/ClientsTableFooter';
import useScrollObserver from '~/shared/hooks/useScrollObserver';
import TableSkeleton from '~/modules/advisor/components/TableSkeleton';
import TableControls from '~/modules/advisor/components/TableControls';
import ClientsTable from '~/modules/advisor/components/ClientsTable';
import EmptyState from '~/modules/advisor/components/EmptySearch';
import ErrorState from '~/modules/advisor/components/ErrorState';
import NoClientsState from '~/modules/advisor/components/NoClients';
import ClientsSummary from './components/ClientsSummary';

const Clients = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const [clients, setClients] = useState<Client[]>([]);
  const [hasNoClients, setHasNoClients] = useState(false);
  const [searchText, setSearchText] = useState(search || '');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const { data, loading, error } = useQuery(GET_CLIENTS_QUERY, {
    variables: {
      input: {
        // TODO: BRI-291 Update to advisor id when auth is implemented
        advisorId: 1,
        page: pageNumber,
        pageSize: 25,
        search: search,
      },
    },
    onCompleted: (data) => {
      if (!search && data?.getClients?.matchingClientCount === 0) {
        setHasNoClients(true);
        setClients([]);
      } else if (pageNumber > 1) {
        setClients(clients.concat(data.getClients.clients));
      } else {
        setClients(data.getClients.clients);
      }
      setIsLoadingMore(false);
    },
    onError: () => {
      setIsLoadingMore(false);
    },
  });

  const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (searchText === '') {
      handleClear();
      return;
    }
    setPageNumber(1);
    searchParams.set('search', searchText);
    setSearchParams(searchParams);
  };

  const handleClear = async () => {
    setPageNumber(1);
    setSearchText('');
    searchParams.delete('search');
    setSearchParams(searchParams);
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setPageNumber((prev) => prev + 1);
  };

  const canLoadMore: boolean =
    !isLoadingMore &&
    !loading &&
    clients.length > 0 &&
    !!data?.getClients.matchingClientCount &&
    clients.length < data?.getClients.matchingClientCount;

  useScrollObserver({
    targetRef: loadMoreRef,
    onIntersect: handleLoadMore,
    options: {
      root: containerRef.current,
      rootMargin: '2px',
      threshold: 1.0,
      enabled: canLoadMore,
    },
    dependencies: [canLoadMore],
  });

  const showLoadingSkeleton = loading && !isLoadingMore;
  const showClients = !showLoadingSkeleton && !error && clients.length > 0;
  const showEmptyState =
    !error && !showLoadingSkeleton && !showClients && !hasNoClients;
  const showNoClients = !error && !showLoadingSkeleton && hasNoClients;
  return (
    <Container ref={containerRef}>
      <ClientsSummary />
      <GrayContainer padding={0}>
        {error ? (
          <ErrorState />
        ) : (
          <Fragment>
            <TableControls
              handleClear={handleClear}
              handleKeyDown={handleKeyDown}
              setSearchText={setSearchText}
              searchText={searchText}
            />
            {showLoadingSkeleton && <TableSkeleton />}
            {showEmptyState && <EmptyState />}
            {showClients && <ClientsTable clients={clients} />}
            {showNoClients && <NoClientsState />}
            <ClientsTableFooter ref={loadMoreRef} loading={isLoadingMore} />
          </Fragment>
        )}
      </GrayContainer>
    </Container>
  );
};

export default Clients;

const Container = styled(Stack)({
  overflow: 'auto',
  flex: 1,
});
