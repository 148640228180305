import { useState } from 'react';
import { useSearchBoxCore, useSearchSession } from '@mapbox/search-js-react';
import {
  SearchBoxRetrieveResponse,
  SearchBoxSuggestion,
} from '@mapbox/search-js-core';
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { HiOutlineSearch } from 'react-icons/hi';
import { MAPBOX_KEY } from '~/shared/config';
import { getLabel } from '~/shared/utils';

interface LocationAutocompleteProps {
  autoCompleteRef: React.RefObject<HTMLElement>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  suggestions: SearchBoxSuggestion[];
  setSuggestions: React.Dispatch<React.SetStateAction<SearchBoxSuggestion[]>>;
  selectedSuggestion: SearchBoxSuggestion | null;
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SearchBoxSuggestion | null>
  >;
  setLocationDetails: React.Dispatch<
    React.SetStateAction<SearchBoxRetrieveResponse | null>
  >;
  handleClear: () => void;
}

const LocationAutocomplete = ({
  autoCompleteRef,
  suggestions,
  setSuggestions,
  searchTerm,
  setSearchTerm,
  selectedSuggestion,
  setSelectedSuggestion,
  setLocationDetails,
  handleClear,
}: LocationAutocompleteProps) => {
  const [open, setOpen] = useState(false);
  const searchBoxCore = useSearchBoxCore({
    accessToken: MAPBOX_KEY,
    types: 'place',
    country: 'US',
  });
  const searchSession = useSearchSession(searchBoxCore);

  const { spacing, palette } = useTheme();

  const handleKeyDown = async (value: string) => {
    setSearchTerm(value);
    const response = await searchSession.suggest(value);
    setSuggestions(response.suggestions);
  };

  const handleSelect = async (suggestion: SearchBoxSuggestion | null) => {
    if (suggestion) {
      setSelectedSuggestion(suggestion);
      setSearchTerm(getLabel(suggestion));
      setSuggestions([]);
      const response = await searchSession.retrieve(suggestion);
      setLocationDetails(response);
    }
  };

  return (
    <Autocomplete<SearchBoxSuggestion, false, false, false>
      id="location-autocomplete"
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) =>
        option?.mapbox_id === value?.mapbox_id
      }
      getOptionLabel={(option) => getLabel(option)}
      onChange={(_, option) => {
        handleSelect(option);
      }}
      value={selectedSuggestion}
      inputValue={searchTerm ?? selectedSuggestion?.name}
      ListboxProps={{
        sx: {
          '& .MuiAutocomplete-option': {
            height: 42,
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: palette.custom.hoverPink,
          },
        },
        ref: autoCompleteRef,
      }}
      getOptionKey={(option) => option?.mapbox_id}
      options={suggestions}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          onChange={(e) => handleKeyDown(e.currentTarget.value)}
          value={searchTerm}
          InputProps={{
            ...params.InputProps,
            style: {
              padding: spacing(0, 4),
            },
            startAdornment: (
              <>
                {params.InputProps.startAdornment}
                <InputAdornment position="start">
                  <IconButton style={{ padding: 0 }}>
                    <HiOutlineSearch
                      color={palette.custom.transparentGray}
                      style={{ fontSize: '20px', padding: 0 }}
                    />
                  </IconButton>
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                {searchTerm && (
                  <InputAdornment position="end">
                    <Typography variant="clearSearch" onClick={handleClear}>
                      CLEAR
                    </Typography>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default LocationAutocomplete;

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  borderRadius: '100px',
  border: `1px solid ${theme.palette.custom.outlineGray}`,
  backgroundColor: theme.palette.common.white,
  '& .MuiOutlinedInput-root': {
    height: '35px', // Set the height of the input wrapper
    padding: 0,
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      height: '35px', // Set the height of the input element
      padding: '0 14px',
      fontSize: 12,
      fontWeight: 800,
      '&::placeholder': {
        fontWeight: 400,
      },
    },
  },
}));
