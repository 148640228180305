import { useEffect, RefObject } from 'react';

interface UseInfiniteScrollOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  enabled?: boolean;
}

interface UseInfiniteScrollParams {
  targetRef: RefObject<Element>;
  onIntersect: () => void;
  options?: UseInfiniteScrollOptions;
  dependencies?: unknown[];
}

const useScrollObserver = ({
  targetRef,
  onIntersect,
  options = {},
  dependencies = [],
}: UseInfiniteScrollParams) => {
  const {
    root = null,
    rootMargin = '0px',
    threshold = 0.99,
    enabled = true,
  } = options;

  useEffect(() => {
    if (!enabled || !targetRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    const element = targetRef.current;
    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [
    onIntersect,
    targetRef.current,
    root,
    rootMargin,
    threshold,
    enabled,
    ...dependencies,
  ]);
};

export default useScrollObserver;
