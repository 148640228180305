import { SxProps, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

const SectionHeading = ({ children, sx }: Props) => {
  return (
    <Typography
      variant="body2"
      fontSize="11px"
      color={theme.palette.custom.darkTeal}
      mb={2}
      sx={sx}
    >
      {children}
    </Typography>
  );
};

export default SectionHeading;
