import { Grid, Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';

const EmptyState = () => {
  return (
    <Grid item xs={12}>
      <DashboardCard
        sx={{
          border: `1px solid ${theme.palette.custom.darkTeal}`,
          color: theme.palette.custom.darkTeal,
          backgroundColor: 'transparent',
          borderRadius: '10px',
        }}
      >
        <Stack justifyContent="center" minHeight={{ xs: '256px', md: '474px' }}>
          <Typography variant="h2" textAlign="center">
            Your most supported nonprofit categories will show here
          </Typography>
        </Stack>
        <SectionHeading
          sx={{
            color: theme.palette.custom.desaturatedTeal,
            margin: 0,
          }}
        >
          Nonprofits
        </SectionHeading>
      </DashboardCard>
    </Grid>
  );
};

export default EmptyState;
