const WavyLinesSVG: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="176"
      height="185"
      fill="none"
      {...props}
    >
      <mask
        id="a"
        width="226"
        height="280"
        x="20"
        y="12"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="#3C5256"
          d="M79.982 290.168c29.368 7.046 56.951-11.611 81.287-28.072 3.65-2.467 7.421-5.021 11.095-7.407 3.573-2.321 7.215-4.599 11.073-7.001l.187-.115c24.868-15.508 53.056-33.088 60.499-64.029 7.447-30.935-9.667-59.379-24.763-84.478l-.142-.231c-2.336-3.878-4.535-7.54-6.652-11.215-2.188-3.801-4.388-7.781-6.512-11.63-14.185-25.701-30.268-54.832-59.641-61.883-15.115-3.621-24.526-.005-30.998 7.567-7.104 8.311-10.668 21.384-14.346 34.866-1.472 5.396-2.99 10.968-4.765 16.23-7.74 22.957-30.172 38.063-56.15 55.556l-.361.238c-28.728 19.349-19.955 59.865-10.854 88.663.475 1.493.95 3.017 1.428 4.571 8.493 27.192 19.059 61.032 49.61 68.355m-38.39-158.729.363-.239c25.389-17.097 49.375-33.248 57.497-57.337 1.812-5.387 3.358-11.032 4.848-16.5 6.846-25.088 12.752-46.755 41.309-39.907 27.971 6.71 43.674 35.16 57.53 60.267 2.244 4.06 4.362 7.905 6.545 11.68 2.117 3.675 4.31 7.322 6.635 11.19l.186.312c14.752 24.531 31.483 52.33 24.367 81.869-2.568 10.655-7.793 19.703-14.566 27.629-12.004 14.041-28.858 24.554-44.398 34.246l-.146.093c-3.883 2.419-7.55 4.712-11.164 7.055-3.679 2.392-7.304 4.843-11.145 7.44-23.768 16.077-50.703 34.296-78.673 27.587-28.74-6.898-38.997-39.728-47.23-66.111l-1.433-4.577c-8.759-27.726-17.306-66.663 9.475-84.697z"
        />
        <path
          fill="#3C5256"
          d="M106.692 247.349c19.871 4.771 38.419-7.778 54.78-18.848 2.564-1.732 4.982-3.371 7.408-4.944a460.526 460.526 0 0 1 7.519-4.753c16.723-10.426 35.668-22.249 40.705-43.166 5.032-20.912-6.471-40.041-16.623-56.911l-.169-.287c-1.526-2.54-2.976-4.949-4.364-7.36-1.398-2.432-2.773-4.921-4.22-7.536l-.126-.226c-9.543-17.287-20.357-36.876-40.223-41.642-10.322-2.477-16.799.12-21.249 5.325-4.909 5.74-7.338 14.651-9.726 23.41-.985 3.608-2.001 7.335-3.194 10.868-5.112 15.164-20.036 25.21-37.314 36.846l-.242.163c-19.52 13.151-13.626 40.504-7.483 59.936l.958 3.053c5.718 18.312 12.835 41.099 33.568 46.077M81.465 141.152l.241-.163c17.057-11.485 33.174-22.326 38.667-38.633 1.226-3.632 2.251-7.41 3.249-11.064 4.68-17.163 8.381-30.722 26.954-26.266 18.469 4.43 28.903 23.338 38.144 40.075 1.491 2.706 2.904 5.26 4.352 7.767a481.087 481.087 0 0 0 4.566 7.698c9.812 16.307 20.921 34.792 16.231 54.306-1.687 7.021-5.142 12.993-9.621 18.231-7.974 9.328-19.202 16.335-29.549 22.782l-.125.081c-2.589 1.62-5.038 3.142-7.448 4.709-2.451 1.589-4.879 3.239-7.464 4.982-15.798 10.682-33.698 22.792-52.167 18.362-18.915-4.537-25.718-26.318-31.182-43.828l-.963-3.067c-5.802-18.361-11.485-44.129 6.105-55.972"
        />
        <path
          fill="#3C5256"
          d="m161.577 211.698.02-.021c1.881-1.267 3.731-2.522 5.548-3.699 1.756-1.144 3.585-2.28 5.438-3.443l.212-.131c12.648-7.887 26.979-16.819 30.808-32.729 3.824-15.906-4.873-30.372-12.552-43.137a352.455 352.455 0 0 1-3.405-5.746 345.284 345.284 0 0 1-3.27-5.838c-7.221-13.074-15.401-27.892-30.52-31.518-7.918-1.898-12.931.185-16.362 4.206-3.822 4.471-5.69 11.334-7.445 17.756-.734 2.69-1.495 5.484-2.375 8.097-3.803 11.273-14.971 18.791-27.905 27.495l-.18.119c-14.936 10.056-10.459 30.824-5.798 45.574l.72 2.295c4.33 13.878 9.723 31.144 25.539 34.93 15.119 3.626 29.147-5.865 41.532-14.236m-60.184-65.709.181-.12c12.894-8.681 25.073-16.876 29.253-29.276.908-2.696 1.679-5.511 2.453-8.352 3.465-12.689 6.194-22.708 19.772-19.455 13.718 3.294 21.518 17.432 28.409 29.902 1.106 2.01 2.201 3.99 3.297 5.883a345.73 345.73 0 0 0 3.443 5.801c7.335 12.197 15.644 26.014 12.156 40.517-1.252 5.203-3.814 9.635-7.146 13.53-5.957 6.968-14.369 12.218-22.122 17.051a315.583 315.583 0 0 0-5.7 3.606 325.29 325.29 0 0 0-5.618 3.753c-11.81 7.992-25.2 17.045-38.913 13.755-14.003-3.361-19.078-19.616-23.159-32.686l-.72-2.306c-4.323-13.673-8.57-32.867 4.419-41.609"
        />
        <path
          fill="#3C5256"
          d="M100.017 258.053c22.244 5.341 43.049-8.739 61.402-21.152l-.386-.577.396.577c2.876-1.948 5.601-3.788 8.324-5.561 2.717-1.768 5.493-3.502 8.45-5.344 18.758-11.698 40.015-24.951 45.653-48.374 5.634-23.418-7.27-44.881-18.662-63.808l-.132-.222c-1.739-2.887-3.386-5.628-4.97-8.371-1.635-2.84-3.219-5.701-4.891-8.734-10.704-19.388-22.832-41.36-45.076-46.7-11.501-2.76-18.719.138-23.682 5.947-5.488 6.423-8.204 16.396-10.879 26.198-1.108 4.058-2.252 8.262-3.589 12.226-5.769 17.112-22.574 28.424-42.03 41.527l-.266.179c-21.834 14.7-15.206 45.345-8.326 67.118l1.08 3.434c6.409 20.532 14.39 46.087 37.574 51.647M71.49 138.728l.266-.179c19.144-12.887 37.226-25.064 43.377-43.31 1.375-4.077 2.532-8.317 3.654-12.423 5.086-18.64 9.475-34.733 30.54-29.675 20.847 5.004 32.596 26.296 42.965 45.084 1.678 3.048 3.273 5.93 4.923 8.784 1.667 2.89 3.429 5.817 5.13 8.649 11.043 18.364 23.563 39.173 18.267 61.198-1.908 7.927-5.804 14.669-10.856 20.584-8.978 10.508-21.617 18.385-33.263 25.647l-.136.082c-2.911 1.815-5.661 3.533-8.369 5.29a525.324 525.324 0 0 0-8.379 5.599c-17.789 12.034-37.951 25.67-58.793 20.671-21.371-5.125-29.034-29.67-35.194-49.394a972.448 972.448 0 0 0-1.08-3.444c-6.543-20.696-12.935-49.762 6.953-63.158"
        />
        <path
          fill="#3C5256"
          d="M126.726 215.233c12.742 3.062 24.518-4.903 34.9-11.932l.03-.032c1.569-1.05 3.103-2.084 4.613-3.071 1.494-.97 3.051-1.943 4.713-2.976 10.608-6.621 22.637-14.122 25.855-27.516 3.228-13.405-4.079-25.537-10.517-36.246l-.088-.14a339.877 339.877 0 0 1-2.759-4.649c-.894-1.556-1.785-3.168-2.73-4.871-6.056-10.968-12.921-23.403-25.662-26.454-6.755-1.62-11.017.15-13.929 3.563-3.223 3.777-4.81 9.562-6.282 14.978-.61 2.25-1.249 4.572-1.985 6.754-3.14 9.32-12.433 15.577-23.194 22.819l-.156.103c-12.622 8.507-8.879 25.984-4.955 38.392l.603 1.92c3.64 11.658 8.168 26.155 21.528 29.364m-15.366-66.812.156-.103c10.806-7.28 21.014-14.153 24.542-24.601.769-2.28 1.416-4.654 2.054-6.987 2.848-10.455 5.1-18.707 16.185-16.045 11.346 2.724 17.826 14.474 23.551 24.837l.11.202c.891 1.612 1.766 3.199 2.649 4.725a276.48 276.48 0 0 0 2.874 4.845c6.093 10.14 13.007 21.628 10.115 33.63-1.032 4.287-3.147 7.954-5.901 11.176-4.953 5.799-11.965 10.169-18.438 14.208a351.28 351.28 0 0 0-4.744 2.998c-1.54.998-3.103 2.054-4.692 3.135-9.82 6.64-20.947 14.167-32.287 11.447-11.552-2.768-15.762-16.265-19.146-27.11l-.604-1.93c-3.582-11.337-7.099-27.234 3.576-34.427z"
        />
        <path
          fill="#3C5256"
          d="m161.369 245.298-.396-.577.467.523c3.172-2.148 6.169-4.172 9.168-6.124 2.99-1.941 6.037-3.839 9.266-5.855l.121-.076c20.793-12.97 44.367-27.669 50.607-53.598 6.234-25.924-8.069-49.71-20.753-70.79-1.969-3.27-3.823-6.353-5.605-9.449-1.767-3.07-3.499-6.2-5.325-9.515l-.099-.181c-11.865-21.489-25.313-45.849-49.93-51.76-12.669-3.043-20.638.16-26.111 6.566-6.071 7.1-9.089 18.152-12.046 29.01-1.226 4.505-2.498 9.155-3.979 13.55-6.426 19.06-25.102 31.638-46.735 46.208l-.296.201c-24.137 16.248-16.786 50.184-9.169 74.299l1.19 3.805c7.106 22.756 15.947 51.065 41.587 57.223 24.622 5.906 47.685-9.694 68.038-23.46zM61.528 136.29l.297-.201c21.225-14.294 41.279-27.792 48.087-47.985 1.524-4.515 2.804-9.226 4.048-13.783 5.67-20.785 10.564-38.74 34.132-33.09 23.214 5.569 36.289 29.254 47.823 50.148 1.875 3.39 3.642 6.6 5.457 9.746 1.826 3.167 3.702 6.29 5.693 9.6 12.28 20.416 26.2 43.559 20.302 68.09-2.128 8.843-6.465 16.345-12.096 22.933-9.986 11.682-24.03 20.444-36.97 28.516l-.126.082c-3.244 2.022-6.302 3.93-9.316 5.887-2.99 1.942-5.956 3.944-9.099 6.071l-.211.141c-19.78 13.376-42.195 28.538-65.415 22.974-23.832-5.718-32.355-33.026-39.205-54.97a789.42 789.42 0 0 0-1.196-3.82c-7.28-23.038-14.401-55.401 7.79-70.334"
        />
        <path
          fill="#3C5256"
          d="M86.656 279.464c26.994 6.476 52.321-10.65 74.66-25.763l-.391-.582.491.506c3.485-2.354 6.773-4.583 10.064-6.719 3.241-2.105 6.541-4.165 10.031-6.344l.282-.174c22.839-14.242 48.715-30.381 55.556-58.816 6.841-28.435-8.868-54.55-22.744-77.612-2.177-3.622-4.234-7.042-6.214-10.47-1.941-3.373-3.844-6.81-5.857-10.457l-.116-.206c-13.025-23.6-27.788-50.343-54.793-56.819-13.941-3.343-22.611-.008-28.573 6.965-6.526 7.64-9.803 19.64-13.171 31.991-1.354 4.96-2.753 10.097-4.383 14.939-7.088 21.014-27.64 34.853-51.44 50.879l-.327.222c-3.117 2.1-5.757 4.466-7.967 7.053-16.552 19.365-9.412 51.09-2.044 74.428l1.317 4.191c7.797 24.966 17.503 56.043 45.593 62.784m-42.414-63.875-1.317-4.2c-7.106-22.48-14.06-53.01 1.45-71.153a35.086 35.086 0 0 1 7.183-6.363l.337-.222c23.307-15.691 45.317-30.515 52.782-52.657 1.668-4.945 3.081-10.129 4.443-15.14 6.258-22.937 11.663-42.748 37.718-36.491 25.598 6.139 39.982 32.202 52.682 55.202 2.052 3.723 3.993 7.235 6 10.718 2.008 3.484 4.075 6.914 6.263 10.556 13.521 22.473 28.836 47.945 22.332 74.977-2.343 9.744-7.127 18.021-13.326 25.28-11 12.873-26.464 22.516-40.739 31.42-3.587 2.239-6.987 4.354-10.318 6.523-3.438 2.229-6.887 4.567-10.22 6.823-21.771 14.729-46.454 31.422-72.047 25.278-26.283-6.311-35.67-36.377-43.217-60.536"
        />
        <path
          fill="#3C5256"
          d="M113.37 236.638c17.493 4.196 33.784-6.821 48.159-16.545 2.243-1.515 4.359-2.949 6.483-4.326a384.165 384.165 0 0 1 6.587-4.161c14.683-9.159 31.326-19.541 35.752-37.942 4.43-18.407-5.672-35.201-14.637-50.115-1.373-2.289-2.675-4.441-3.92-6.601a392.613 392.613 0 0 1-3.802-6.8c-8.378-15.18-17.877-32.386-35.37-36.582-9.081-2.176-14.834.22-18.789 4.844-4.415 5.17-6.58 13.105-8.613 20.549-.857 3.141-1.741 6.385-2.771 9.435-4.454 13.216-17.503 22-32.609 32.175l-.211.141c-2.047 1.38-3.778 2.934-5.227 4.633-10.757 12.592-6.167 33.068-1.413 48.122l.835 2.672c5.028 16.093 11.28 36.122 29.552 40.506m-26.367-41.591-.842-2.687c-4.486-14.201-8.895-33.488.82-44.847a21.947 21.947 0 0 1 4.447-3.938l.212-.141c14.98-10.084 29.13-19.61 33.962-33.953 1.062-3.158 1.964-6.465 2.854-9.705 4.07-14.923 7.282-26.715 23.363-22.86 16.095 3.859 25.215 20.384 33.262 34.96a452.044 452.044 0 0 0 3.836 6.85 358.204 358.204 0 0 0 4.002 6.747c8.571 14.25 18.285 30.406 14.191 47.409-1.473 6.11-4.476 11.312-8.381 15.883-6.961 8.148-16.788 14.274-25.835 19.917a448.76 448.76 0 0 0-6.637 4.193 385.547 385.547 0 0 0-6.539 4.365c-13.802 9.334-29.444 19.923-45.539 16.064-16.46-3.95-22.399-22.973-27.17-38.252"
        />
      </mask>
      <g mask="url(#a)">
        <path fill="#3C5256" d="M-5.177 185V0H176v185z" />
      </g>
    </svg>
  );
};

export default WavyLinesSVG;
