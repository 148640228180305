import { useQuery, useReactiveVar } from '@apollo/client';
import { styled, Typography, Box, useMediaQuery, Theme } from '@mui/material';
import { activeDAFIdVar, clientVar } from '~/apollo';
import { GET_GRANT_CHART_DATA } from '~/graphql/queries/grants';
import PieChart from '~/shared/components/Charts/PieChart';
import PieChartSkeleton from '~/shared/components/Charts/PieChartSkeleton';
import TotalsBox from '~/shared/components/TotalsBox';

const GrantsOverview = () => {
  const dafAccountId = useReactiveVar(activeDAFIdVar);
  const client = useReactiveVar(clientVar);
  const activeDAF = client?.DAFAccounts.find((daf) => daf.id === dafAccountId);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { data, loading } = useQuery(GET_GRANT_CHART_DATA, {
    skip: !dafAccountId,
    variables: {
      input: {
        dafAccountId: dafAccountId!,
      },
    },
  });

  const showEmptyState = !loading && !data?.getGrantChartData && !isSmall;

  return (
    <Layout>
      <TotalsContainer>
        <TotalsBox
          key={'accountBalance'}
          title={'Total Account Balance'}
          total={activeDAF?.totalBalanceMajorUnits}
        />
        <TotalsBox
          key={'ytdGrants'}
          title={'Year to Date Grants'}
          total={activeDAF?.totalGrantsYTDMajorUnits}
        />
        <TotalsBox
          key={'allTimeGrants'}
          title={'All Time Total Grants'}
          total={activeDAF?.totalGrantsMajorUnits}
        />
      </TotalsContainer>

      {(data?.getGrantChartData || loading) && (
        <ChartContainer>
          {data?.getGrantChartData && (
            <PieChart data={data.getGrantChartData} />
          )}
          {loading && <PieChartSkeleton />}
        </ChartContainer>
      )}
      {showEmptyState && (
        <ChartContainer>
          <EmptyStateContainer>
            <Typography
              fontSize={12}
              width={isSmall ? '80%' : '60%'}
              textAlign={'center'}
            >
              Your top nonprofit categories will appear here once you start
              making grants.
            </Typography>
          </EmptyStateContainer>
        </ChartContainer>
      )}
    </Layout>
  );
};

export default GrantsOverview;

const Layout = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  padding: theme.spacing(0, 5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const TotalsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(0),
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
  marginLeft: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(8, 0, 3),
  },
}));

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.custom.lightGray,
  borderRadius: theme.shape.customBorderRadius['10'],
  height: 108,
  width: '100%',
  maxWidth: 480,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 600,
  },
}));
