import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import Sidebar from '~/modules/navigation/Sidebar';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Snackbar from './Snackbar';
import NonProfitGrantModal from '~/modules/nonProfits/components/GrantModal';
import AdvisorGrantModal from '~/modules/advisor/components/GrantModal';

const Layout = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <Box display={'flex'}>
      {!isMobile && <Sidebar />}
      <Stack
        sx={{
          overflow: 'auto',
          width: '100%',
          height: isMobile ? '100%' : '100vh',
        }}
      >
        <Header />
        <Outlet />
      </Stack>
      <Snackbar />
      <NonProfitGrantModal />
      <AdvisorGrantModal />
    </Box>
  );
};

export default Layout;
