import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DafAccount, NonProfit, Client } from '~/graphql/gen/graphql';
import {
  NON_PROFITS_INCREMENT,
  SnackbarVariant,
  TopLevelSubjectCode,
} from '~/shared/constants';
import { GRAPHQL_URL } from '~/shared/config';
import auth0Client from '~/auth0';

export interface SnackbarMessageInfo {
  message: string;
  key: number;
  duration?: number;
  variant?: SnackbarVariant;
}

export const selectedNonProfitVar = makeVar<NonProfit | null>(null);
export const activeGrantModalVar = makeVar<null | 'nonProfit' | 'advisor'>(
  null,
);
export const searchTermVar = makeVar<string | null>(null);
export const subjectCodesVar = makeVar<TopLevelSubjectCode[] | null>(null);
export const nonProfitsCoordsVar = makeVar<string | null>(null);
export const pagesRemainingVar = makeVar<number>(0);
export const pageNumberInputVar = makeVar(1);
export const nonProfitsVar = makeVar<NonProfit[]>([]);
export const isLoggingInVar = makeVar<boolean>(true);
export const nonProfitsIndexVar = makeVar<number>(NON_PROFITS_INCREMENT);
export const isLoadingNonProfitsVar = makeVar(false);
export const clientVar = makeVar<Client | null>(null);
export const activeDAFIdVar = makeVar<DafAccount['id'] | null>(null);
export const snackbarMessagesVar = makeVar<SnackbarMessageInfo[]>([]);
export const snackbarIsOpenVar = makeVar(false);
export const messageInfoVar = makeVar<SnackbarMessageInfo | undefined>(
  undefined,
);
export const selectedClientVar = makeVar<Client | null>(null);
export const isAdvisorVar = makeVar<boolean>(false);

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await auth0Client.getTokenSilently();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
