import { styled, Typography, Box, useTheme } from '@mui/material';
import { RiArrowRightLine } from 'react-icons/ri';
import { shouldNotForwardPropsWithKeys } from '~/shared/utils';

interface Props {
  isActive: boolean;
  label: string;
  circleColor: string;
  handleSelect: () => void;
}

const PortfolioSelect = ({
  isActive,
  label,
  circleColor,
  handleSelect,
}: Props) => {
  const { palette } = useTheme();
  return (
    <Container isActive={isActive} onClick={handleSelect}>
      <Box display={'flex'} alignItems="center">
        <ColorCircle sx={{ backgroundColor: circleColor }} />
        <Typography fontSize={14} fontWeight={500}>
          {label}
        </Typography>
      </Box>
      <ArrowCircle>
        {isActive && (
          <RiArrowRightLine size={26} color={palette.common.black} />
        )}
      </ArrowCircle>
    </Container>
  );
};

export default PortfolioSelect;

interface CustomProps {
  isActive: boolean;
}

const Container = styled(Box, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<CustomProps>(['isActive']),
})<CustomProps>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 45,
  margin: theme.spacing(0, 6, 4, 1),
  padding: theme.spacing(0, 1, 0, 3),
  color: isActive ? theme.palette.common.white : theme.palette.common.black,
  backgroundColor: isActive
    ? theme.palette.custom.purple
    : theme.palette.common.white,
  borderRadius: theme.shape.customBorderRadius['35'],
  border: `1px solid ${theme.palette.custom.outlineGray}`,
  cursor: 'pointer',
}));

const ColorCircle = styled(Box)(({ theme }) => ({
  width: 27,
  height: 27,
  borderRadius: '50%',
  marginRight: theme.spacing(2.5),
}));

const ArrowCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 37,
  height: 37,
  borderRadius: '50%',
  backgroundColor: theme.palette.common.white,
}));
