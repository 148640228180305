import { Typography, styled, Button, Stack, useTheme } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Grant } from '~/graphql/gen/graphql';
import { CircularProgress } from '@mui/material';
import useSnackbar from '~/shared/hooks/useSnackbar';
import DataDogService from '~/services/datadog.service';
import { CANCEL_GRANT } from '~/graphql/mutations/grants';
import startCase from 'lodash.startcase';
import { activeDAFIdVar } from '~/apollo';
import { SnackbarVariant } from '~/shared/constants';
interface Props {
  grant: Grant;
  setGrants: React.Dispatch<React.SetStateAction<Grant[]>>;
  onClose: () => void;
}

const CancelGrant = ({ grant, setGrants, onClose }: Props) => {
  const { createSnackNotice } = useSnackbar();
  const activeDAFId = useReactiveVar(activeDAFIdVar);
  const { spacing } = useTheme();

  const [cancelGrant, { loading }] = useMutation(CANCEL_GRANT, {
    onCompleted: (data) => {
      createSnackNotice(
        'RECURRING GRANT HAS BEEN CANCELED',
        SnackbarVariant.Success,
      );
      setGrants((grants) => {
        return grants.filter((grant) => grant.id !== data.cancelGrant.id);
      });
    },
    update: (cache) => {
      // Invalidate to require network call new chart data
      cache.evict({ fieldName: 'getGrantChartData' });
      cache.gc();
    },
    onError: (error) => {
      createSnackNotice(
        'UPDATE FAILED, PLEASE TRY AGAIN',
        SnackbarVariant.Error,
      );
      DataDogService.logError(error);
    },
  });

  const handleCancellation = async () => {
    if (!activeDAFId) {
      createSnackNotice(
        'CANCELLATION FAILED, PLEASE TRY AGAIN',
        SnackbarVariant.Error,
      );
      return;
    }
    cancelGrant({
      variables: {
        input: {
          grantId: grant.id,
          dafAccountId: activeDAFId,
        },
      },
    });
  };

  return (
    <Stack>
      <Typography variant="h1" mb={spacing(3)}>
        Cancel Recurring Grant
      </Typography>
      <Copy mb={spacing(3)}>
        Are you sure you want to cancel your {grant.amountMajorUnits}/
        {startCase(grant.period.toLocaleLowerCase())} grant to{' '}
        {grant.nonProfitName}?
      </Copy>
      <Copy mb={spacing(8)}>
        This action will stop all future payments to {grant.nonProfitName}.
      </Copy>

      <Button
        type="submit"
        variant="main"
        fullWidth
        onClick={handleCancellation}
        sx={{ height: 69, fontSize: '16px', mb: spacing(6) }}
      >
        {loading ? <Progress /> : 'Proceed and Cancel'}
      </Button>
      <StyledButton disableRipple onClick={() => onClose()}>
        <Typography fontSize={15} fontWeight={'bold'}>
          Nevermind
        </Typography>
      </StyledButton>
    </Stack>
  );
};

export default CancelGrant;

const Copy = styled(Typography)({
  width: '85%',
  fontSize: '16px',
  lineHeight: '1',
  textAlign: 'center',
});

const Progress = styled(CircularProgress)(({ theme }) => ({
  '& .MuiCircularProgress-bar': {
    color: theme.palette.common.black,
  },
  color: 'lightgrey',
  width: 20,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '15px',
  color: theme.palette.common.black,
  textTransform: 'none',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
}));
