import {
  Box,
  TableCell as MuiTableCell,
  styled,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FaSort } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { OrderByEnum } from '~/graphql/gen/graphql';

const TableHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderBy = searchParams.get('orderBy');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const isBetweenMedAndSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'md'),
  );

  const { spacing } = useTheme();
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            width: isBetweenMedAndSmall ? 180 : 'auto',
            cursor: 'pointer',
            verticalAlign: 'center',
          }}
          onClick={() => {
            searchParams.set(
              'orderBy',
              orderBy === OrderByEnum.Desc ? OrderByEnum.Asc : OrderByEnum.Desc,
            );
            setSearchParams(searchParams);
          }}
        >
          <Box display="flex" alignItems="center">
            Date
            <FaSort style={{ marginLeft: spacing(1) }} />
          </Box>
        </TableCell>
        {isMobile && <TableCell align="left">ID/Description</TableCell>}
        {!isMobile && <TableCell>ID</TableCell>}
        {!isMobile && <TableCell>Description</TableCell>}
        <TableCell sx={{ pr: isMobile ? 6 : 8 }} align="right">
          Amount
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0, 4, 2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
  },
}));
