import {
  NonProfitsInput,
  Client,
  GetAdvisorNonProfitsInput,
} from '~/graphql/gen/graphql';
import apolloClient from '~/apollo';
import {
  ADVISOR_NON_PROFIT_QUERY,
  NON_PROFIT_QUERY,
} from '~/graphql/queries/nonProfits';
import DataDogService from './datadog.service';
import { LOGIN_OR_REGISTER } from '~/graphql/mutations/client';

class BackendService {
  static async loginOrRegister(): Promise<Client> {
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: LOGIN_OR_REGISTER,
      });
      if (errors || !data) {
        throw errors ? errors : new Error('Unable to retrieve client data');
      }
      return data.loginOrRegister;
    } catch (error) {
      DataDogService.logError(error);
      throw error;
    }
  }

  static async getNonProfits(input: NonProfitsInput) {
    try {
      // Keep subject codes sorted for caching purposes
      input.subjectCodes = input.subjectCodes?.sort();

      const { data, error } = await apolloClient.query({
        query: NON_PROFIT_QUERY,
        variables: {
          input,
        },
      });
      if (error || !data.getNonProfits) {
        throw error ? error : new Error('No non-profit data');
      }
      return data.getNonProfits;
    } catch (error) {
      DataDogService.logError(error);
      throw error;
    }
  }

  static async getAdvisorNonProfits(input: GetAdvisorNonProfitsInput) {
    try {
      const { data, error } = await apolloClient.query({
        query: ADVISOR_NON_PROFIT_QUERY,
        variables: {
          input,
        },
      });

      if (error || !data.getAdvisorNonProfits) {
        throw error ? error : new Error('No advisor non-profit data');
      }

      return data.getAdvisorNonProfits;
    } catch (error) {
      DataDogService.logError(error);
      throw error;
    }
  }
}

export default BackendService;
