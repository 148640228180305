import {
  Box,
  Button,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import auth0Client from '~/auth0';
import {
  tealBlob,
  tealBlobMobile,
  wavyLines,
  wavyLinesMobile,
} from '~/assets/images';
import { useEffect } from 'react';
import LogoAndTag from '~/shared/components/LogoAndTag';

const LoginPage = () => {
  const { spacing } = useTheme();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const redirectIfLoggedIn = async () => {
      const isAuthenticated = await auth0Client.isAuthenticated();
      if (isAuthenticated) {
        window.location.href = '/dashboard';
      }
    };

    redirectIfLoggedIn();
  }, []);

  return (
    <Container>
      <TealBlob src={isSmall ? tealBlobMobile : tealBlob} />
      <LogoContainer>
        <LogoAndTag />
      </LogoContainer>

      <MainContainer>
        <Header variant="h1">Welcome to Your Personal Financial Hub</Header>
        <Copy variant="body1">
          Manage your investments, make grants, and view your financial
          overview.
        </Copy>
        <LoginButton
          variant="outlined"
          onClick={() => auth0Client.loginWithRedirect()}
        >
          Log In to Your Account
        </LoginButton>
      </MainContainer>
      <BottomContent>
        <Box>
          {/* TODO: (Ticket: BRI-363) Find proper route */}
          <Typography
            variant="legalLink"
            onClick={() => console.log('WE NEED THE LINK')}
            mr={spacing(2)}
          >
            Privacy Policy
          </Typography>

          {/* TODO: (Ticket: BRI-363) Find proper route */}
          <Typography
            variant="legalLink"
            onClick={() => console.log('WE NEED THE LINK')}
          >
            Terms & Conditions
          </Typography>
        </Box>
      </BottomContent>
      <WavyLines src={isSmall ? wavyLinesMobile : wavyLines} />
    </Container>
  );
};

export default LoginPage;

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.custom.sageGreen,
}));

const LogoContainer = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(6),
  left: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    left: theme.spacing(7),
  },
}));

const TealBlob = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0),
  right: theme.spacing(0),
  width: 381,
  zIndex: theme.zIndex.min,
  [theme.breakpoints.down('sm')]: {
    width: 222,
  },
  '@media (max-height: 740px)': {
    width: 170,
  },
  '@media (max-width: 350px)': {
    width: '60%',
  },
}));

const WavyLines = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0),
  left: theme.spacing(0),
  width: 214,
  zIndex: theme.zIndex.min,
  [theme.breakpoints.down('sm')]: {
    width: 175,
  },
  '@media (max-height: 700px)': {
    width: 155,
  },
}));

const MainContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'flex-start',
  zIndex: theme.zIndex.appBar,
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2, 0, 3),
  },
  '@media (max-height: 650px)': {
    marginTop: theme.spacing(8),
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'start',
  maxWidth: 325,
  lineHeight: 1,
  color: theme.palette.custom.teal,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    maxWidth: 300,
    padding: theme.spacing(0, 2),
  },
}));

const Copy = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  maxWidth: 325,
  lineHeight: 1,
  color: theme.palette.custom.teal,
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
    padding: theme.spacing(0, 2),
  },
}));

const LoginButton = styled(Button)({
  width: 236,
  height: 45,
  fontSize: '14px',
});

const BottomContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(0, 3, 4),
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 7, 4),
  },
}));
