import {
  Box,
  Snackbar as MuiSnackbar,
  SnackbarContent,
  Typography,
  useTheme,
} from '@mui/material';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import useSnackbar from '~/shared/hooks/useSnackbar';
import { SnackbarVariant } from '~/shared/constants';

const Snackbar = () => {
  const { messageInfo, snackbarIsOpen, handleClose, handleExited } =
    useSnackbar();
  const { spacing, palette } = useTheme();

  const CustomSnackbarMessage = () => {
    return (
      <Box display="flex" alignItems="center">
        {messageInfo?.variant === SnackbarVariant.Success && (
          <IoCheckmarkCircleOutline
            size={21}
            color={palette.custom.offWhite}
            style={{ marginRight: spacing(2) }}
          />
        )}
        <Typography variant="snackbarBody">{messageInfo?.message}</Typography>
      </Box>
    );
  };

  return (
    <MuiSnackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={snackbarIsOpen}
      autoHideDuration={messageInfo?.duration || 8000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo ? messageInfo.message : undefined}
    >
      <SnackbarContent
        message={messageInfo ? <CustomSnackbarMessage /> : undefined}
        variant={messageInfo?.variant ?? 'success'}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
