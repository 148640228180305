import {
  Box,
  Button,
  Stack,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import startCase from 'lodash.startcase';
import { PeriodEnum } from '~/graphql/gen/graphql';
import EditIcon from '~/assets/icons/EditIcon';
import { theme } from '~/shared/styles/theme';
import type { Grant } from '~/graphql/gen/graphql';
import EditGrantModal from './EditGrantModal';
import { useState } from 'react';

type GrantRowProps = {
  grant: Grant;
  setGrants: React.Dispatch<React.SetStateAction<Grant[]>>;
};

const GrantTableRow = ({ grant, setGrants }: GrantRowProps) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const grantCategories = grant.categories
    .map((category) => category.label)
    .join(', ');
  const grantStartDate = grant.startDate
    ? new Date(grant.startDate).toLocaleString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
    : new Date(grant.createdAt).toLocaleString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
  const grantFrequency = startCase(grant.period.toLowerCase());

  const handleEditClick = () => {
    setEditModalIsOpen(true);
  };

  if (isMobile) {
    return (
      <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
        <EditGrantModal
          grant={grant}
          isOpen={editModalIsOpen}
          setGrants={setGrants}
          onClose={() => setEditModalIsOpen(false)}
        />
        <TableCell sx={{ maxWidth: '275px' }} title={grant.nonProfitName}>
          <Stack>
            <Box display={'flex'} alignItems={'center'}>
              <Typography
                fontSize={14}
                fontWeight={500}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {grant.nonProfitName}
              </Typography>
              <Dot />
              <Typography fontSize={14} fontWeight={500} minWidth={80}>
                {grant.amountMajorUnits}
              </Typography>
            </Box>
            <Typography fontSize={12}>
              {grantFrequency === 'One Time'
                ? grantFrequency + ' on'
                : grantFrequency + ' Starts'}{' '}
              {grantStartDate}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          {grant.period !== PeriodEnum.OneTime && (
            <Button
              onClick={() => handleEditClick()}
              sx={{ padding: 0, minWidth: 28 }}
            >
              <EditIcon />
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
      <EditGrantModal
        grant={grant}
        isOpen={editModalIsOpen}
        setGrants={setGrants}
        onClose={() => setEditModalIsOpen(false)}
      />
      <TableCell
        sx={{ fontWeight: 'bold', maxWidth: '190px', paddingLeft: 6 }}
        title={grant.nonProfitName}
      >
        {grant.nonProfitName}
      </TableCell>
      <TableCell
        sx={{ textTransform: 'capitalize', maxWidth: '190px' }}
        title={grantCategories}
      >
        {grantCategories}
      </TableCell>
      <TableCell>{grantFrequency}</TableCell>
      <TableCell>{grantStartDate}</TableCell>
      <TableCell sx={{ fontWeight: 'bold' }}>
        {grant.amountMajorUnits}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          width: 115,
          paddingRight: 6,
        }}
      >
        {grant.period !== PeriodEnum.OneTime && (
          <Button onClick={() => handleEditClick()} fullWidth disableRipple>
            <EditIcon />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default GrantTableRow;

const Dot = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 3),
  borderRadius: theme.shape.customBorderRadius['circle'],
  backgroundColor: theme.palette.common.black,
  border: '1px solid black',
  alignSelf: 'center',
  minWidth: 5,
  height: 5,
}));
