import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import type { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';
import CardButton from '../../CardButton';
import { CARD_IMAGES } from '~/shared/constants';
import { theme } from '~/shared/styles/theme';
import EmptyState from './EmptyState';

const getCategoryFirstImage = (subjectCodes: string[]) => {
  return subjectCodes.map((selectedSubjectCodes) => {
    if (CARD_IMAGES[selectedSubjectCodes]) {
      return CARD_IMAGES[selectedSubjectCodes][0];
    }

    // Return a default image if no match is found
    return CARD_IMAGES[0][0];
  });
};

interface Props {
  data: GetClientDashboardQuery;
}

const FavoritesCard = ({ data }: Props) => {
  const categoryImages = getCategoryFirstImage(
    data.getClientDashboard.topFavoriteCategories,
  );

  if (!data.getClientDashboard.favoritesCount) {
    return <EmptyState />;
  }

  return (
    <Grid item xs={12} md={6}>
      <DashboardCard sx={{ color: theme.palette.custom.darkTeal }}>
        <Stack
          direction="row"
          borderRadius="10px"
          justifyContent="space-between"
          mb={6}
        >
          <SectionHeading>Favorites</SectionHeading>
          <Link to="/non-profits">
            <CardButton text="View All" sx={{ fontSize: '10px' }} />
          </Link>
        </Stack>
        <Typography variant="h2" textAlign="center">
          {data.getClientDashboard.favoritesCount} Nonprofits
        </Typography>
        <Typography fontSize="20px" textAlign="center" mb={4} lineHeight={1}>
          Favorited & Counting
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CircleImageGrid images={categoryImages} />
          </Grid>
        </Grid>
      </DashboardCard>
    </Grid>
  );
};

export default FavoritesCard;

interface CircleImageGridProps {
  images: string[];
}

const CircleImage = styled(Box)({
  position: 'relative',
  width: 56,
  height: 56,
  marginRight: -24, // Creates the overlap effect
  borderRadius: '50%',
  border: '2px solid white',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const CircleImageGrid = ({ images = [] }: CircleImageGridProps) => {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center">
      {images.map((image, index) => (
        <CircleImage
          key={index}
          sx={{
            zIndex: images.length - index,
            '&:last-child': {
              marginRight: 0,
            },
          }}
        >
          <img src={image} alt={`Category ${index + 1}`} />
        </CircleImage>
      ))}
    </Box>
  );
};
