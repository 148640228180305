import { LocalStorageKeys } from '~/shared/constants';
import DataDogService from './datadog.service';

class LocalStorageService {
  static get = (key: LocalStorageKeys) => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      DataDogService.logError(error);
      return null;
    }
  };
  static set = (key: LocalStorageKeys, value: unknown) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };
  static delete = (key: LocalStorageKeys) => {
    window.localStorage.removeItem(key);
  };
}

export default LocalStorageService;
