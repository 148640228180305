import { Typography, styled, useTheme } from '@mui/material';

interface Props {
  label: string;
}

const CategoryLabel = ({ label }: Props) => {
  const { spacing } = useTheme();
  const lastSpaceIndex = label.lastIndexOf(' ');
  if (lastSpaceIndex !== -1) {
    return (
      <>
        <Label variant="body2">{label.slice(0, lastSpaceIndex + 1)}</Label>

        <Label variant="body2" mb={spacing(2)}>
          {label.slice(lastSpaceIndex + 1)}
        </Label>
      </>
    );
  }
  return (
    <>
      <Label variant="body2">{label}</Label>
    </>
  );
};

export default CategoryLabel;

const Label = styled(Typography)(({ theme }) => ({
  // color controlled by parent css in Category.tsx
  fontSize: '25px',
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: '14px',
  '&::selection': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));
