import {
  Button,
  Collapse,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import {
  activeDAFIdVar,
  activeGrantModalVar,
  selectedClientVar,
} from '~/apollo';
import CaretDownIcon from '~/assets/icons/CaretDownIcon';
import CaretUpIcon from '~/assets/icons/CaretUpIcon';
import { Client } from '~/graphql/gen/graphql';
import { theme } from '~/shared/styles/theme';
import ClientDropdownContent from './ClientDropdownContent';
import { useReactiveVar } from '@apollo/client';

type ClientRowProps = {
  client: Client;
  expandedRow: number | null;
  setExpandedRow: React.Dispatch<React.SetStateAction<number | null>>;
};

const ClientTableRow = ({
  client,
  expandedRow,
  setExpandedRow,
}: ClientRowProps) => {
  useReactiveVar(selectedClientVar);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const activeDAFIdBelongsToClient = client.DAFAccounts.some(
    (account) => account.id === activeDAFIdVar(),
  );

  const handleMakeGrantClick = () => {
    activeGrantModalVar('advisor');
    selectedClientVar(client);
    const otherRowIsExpanded = expandedRow && expandedRow !== client.id;
    if (otherRowIsExpanded) {
      setExpandedRow(null);
    }
    const activeDAFIdBelongsToClient = client.DAFAccounts.some(
      (account) => account.id === activeDAFIdVar(),
    );
    if (!activeDAFIdBelongsToClient) {
      activeDAFIdVar(client.DAFAccounts[0].id);
    }
  };

  const handleExpandRow = (clientId: number) => {
    if (expandedRow === clientId) {
      setExpandedRow(null);
      activeDAFIdVar(null);
    } else {
      setExpandedRow(clientId);
      selectedClientVar(client);
      if (!activeDAFIdBelongsToClient) {
        activeDAFIdVar(client.DAFAccounts[0].id);
      }
    }
  };

  const isExpanded = expandedRow === client.id;

  if (isMobile) {
    return (
      <React.Fragment>
        <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
          <BorderlessTableCell>{client.externalClientId}</BorderlessTableCell>
          <BorderlessTableCell sx={{ maxWidth: 0 }}>
            <Typography
              fontSize={12}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {`${client.firstName} ${client.lastName}`}
            </Typography>
            <Typography
              fontSize={12}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {client.email}
            </Typography>
          </BorderlessTableCell>
          <BorderlessTableCell>
            <Typography fontSize={12} fontWeight={500}>
              {client.balanceMajorUnits}
            </Typography>
          </BorderlessTableCell>
          <BorderlessTableCell>
            <IconButton
              sx={{ padding: 0 }}
              aria-label="expand row"
              size="small"
              onClick={() => handleExpandRow(client.id)}
            >
              {isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}
            </IconButton>
          </BorderlessTableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              padding: theme.spacing(0, 2),
            }}
            colSpan={6}
          >
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ClientDropdownContent client={client} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
        <BorderlessTableCell>{client.externalClientId}</BorderlessTableCell>
        <BorderlessTableCell>{`${client.firstName} ${client.lastName}`}</BorderlessTableCell>
        <BorderlessTableCell>{client.email}</BorderlessTableCell>
        <BorderlessTableCell>{client.balanceMajorUnits}</BorderlessTableCell>
        <BorderlessTableCell>
          <Button variant="text" onClick={handleMakeGrantClick}>
            Make a Grant
          </Button>
        </BorderlessTableCell>
        <BorderlessTableCell
          align="right"
          sx={{
            width: 115,
            paddingRight: 6,
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandRow(client.id)}
          >
            {isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}
          </IconButton>
        </BorderlessTableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <ClientDropdownContent client={client} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ClientTableRow;

const BorderlessTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none !important',
  [theme.breakpoints.down('md')]: {
    height: 74,
    padding: theme.spacing(0, 2),
  },
}));
