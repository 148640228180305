import { Stack, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SearchBar from '~/shared/components/SearchBar';
import {
  TableButtonText,
  TableControlButton,
  TableControlsContainer,
} from '~/shared/components/StyledComponents';
import { TRANSACTIONS_PAGE_FILTERS } from '~/shared/constants';
import { theme } from '~/shared/styles/theme';

interface Props {
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  handleClear: () => void;
  searchText: string;
  setSearchText: (value: string) => void;
  handleFilterSearch: (filter: string) => void;
  handleBlur?: () => void;
}

const TableControls = ({
  handleKeyDown,
  handleClear,
  handleBlur,
  searchText,
  handleFilterSearch,
  setSearchText,
}: Props) => {
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TableControlsContainer>
      <SearchBar
        handleKeyDown={handleKeyDown}
        handleClear={handleClear}
        handleBlur={handleBlur}
        searchTerm={searchText}
        setSearchTerm={setSearchText}
        placeholder="Search..."
      />

      <Stack direction="row" alignItems="center" mb={isMobile ? 6 : 0}>
        <TableControlButton
          isActive={filter === TRANSACTIONS_PAGE_FILTERS.ALL}
          variant="tableFilter"
          onClick={() => handleFilterSearch(TRANSACTIONS_PAGE_FILTERS.ALL)}
        >
          <TableButtonText isActive={filter === TRANSACTIONS_PAGE_FILTERS.ALL}>
            All Transactions
          </TableButtonText>
        </TableControlButton>
        <TableControlButton
          isActive={filter === TRANSACTIONS_PAGE_FILTERS.GRANT}
          variant="tableFilter"
          onClick={() => handleFilterSearch(TRANSACTIONS_PAGE_FILTERS.GRANT)}
        >
          <TableButtonText
            isActive={filter === TRANSACTIONS_PAGE_FILTERS.GRANT}
          >
            Grants
          </TableButtonText>
        </TableControlButton>
        <TableControlButton
          isActive={filter === TRANSACTIONS_PAGE_FILTERS.CONTRIBUTION}
          variant="tableFilter"
          onClick={() =>
            handleFilterSearch(TRANSACTIONS_PAGE_FILTERS.CONTRIBUTION)
          }
        >
          <TableButtonText
            isActive={filter === TRANSACTIONS_PAGE_FILTERS.CONTRIBUTION}
          >
            Contributions
          </TableButtonText>
        </TableControlButton>
      </Stack>
    </TableControlsContainer>
  );
};

export default TableControls;
