import { TableCell, TableRow, Theme, useMediaQuery } from '@mui/material';
import { Transaction } from '~/graphql/gen/graphql';
import { theme } from '~/shared/styles/theme';

type TransactionRowProps = {
  transaction: Transaction;
};

const TransactionTableRow = ({ transaction }: TransactionRowProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const transactionStartDate = transaction.transactedAt
    ? new Date(transaction.transactedAt).toLocaleString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
    : new Date(transaction.transactedAt).toLocaleString([], {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });

  if (isMobile) {
    return (
      <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
        <TableCell size="small" style={{ maxWidth: 100 }}>
          {transactionStartDate}
        </TableCell>
        <TableCell sx={{ maxWidth: 100 }} align="left">
          {transaction.externalId}
          <br />
          {transaction.action}
        </TableCell>
        <TableCell
          sx={{ fontWeight: 'bold', pr: 6 }}
          align="right"
          size="small"
          width={100}
        >
          {transaction.totalMajorUnits}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow sx={{ backgroundColor: theme.palette.common.white }}>
      <TableCell>{transactionStartDate}</TableCell>
      <TableCell>{transaction.externalId}</TableCell>
      <TableCell>{transaction.action}</TableCell>
      <TableCell sx={{ fontWeight: 'bold', pr: 8 }} align="right">
        {transaction.totalMajorUnits}
      </TableCell>
    </TableRow>
  );
};

export default TransactionTableRow;
