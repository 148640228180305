import { Grid } from '@mui/material';
import type { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import FavoritesCard from '../cards/Favorites/FavoritesCard';
import TotalGrantsCard from '../cards/TotalGrants/TotalGrantsCard';
import RecurringGrantsCard from '../cards/RecurringGrants/RecurringGrantsCard';

interface Props {
  data: GetClientDashboardQuery;
}

const StatsSection = ({ data }: Props) => {
  return (
    <Grid container spacing={6}>
      <FavoritesCard data={data} />
      <TotalGrantsCard data={data} />
      <RecurringGrantsCard data={data} />
    </Grid>
  );
};

export default StatsSection;
