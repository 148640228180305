import {
  Table,
  TableBody,
  TableContainer,
  Theme,
  useMediaQuery,
} from '@mui/material';
import GrantTableRow from './GrantTableRow';
import { Grant } from '~/graphql/gen/graphql';
import TableHeader from './TableHeader';

interface Props {
  grants: Grant[];
  setGrants: React.Dispatch<React.SetStateAction<Grant[]>>;
}

const GrantsTable = ({ grants, setGrants }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  // 331 is the height of the header + table controls on mobile
  const height = window.innerHeight - 331;

  return (
    <TableContainer sx={{ minHeight: isMobile ? height : 'auto' }}>
      <Table>
        {!isMobile && <TableHeader />}
        <TableBody>
          {grants.map((grant) => (
            <GrantTableRow key={grant.id} grant={grant} setGrants={setGrants} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GrantsTable;
