import {
  Checkbox as MuiCheckbox,
  Stack,
  useTheme,
  styled,
} from '@mui/material';
import { CSSProperties } from 'react';
import CheckIcon from '~/assets/icons/CheckIcon';

interface CheckboxProps {
  isChecked: boolean;
  padding?: CSSProperties['padding'];
}

const Checkbox = ({ isChecked, padding }: CheckboxProps) => {
  const theme = useTheme();

  return (
    <MuiCheckbox
      icon={<Container />}
      checkedIcon={
        <Container sx={{ background: theme.palette.custom.darkTeal }}>
          <CheckIcon />
        </Container>
      }
      checked={isChecked}
      sx={{ padding: padding ?? theme.spacing(1, 2, 1, 0) }}
    />
  );
};

export default Checkbox;

const Container = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: 24,
  border: `1px solid ${theme.palette.custom.softGray}`,
}));
