import { Stack, Box, styled, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Portfolio } from '~/graphql/gen/graphql';
import {
  TableButtonText,
  TableControlButton,
} from '~/shared/components/StyledComponents';

interface Props {
  portfolios: Portfolio[];
  colors: string[];
}

const TableControls = ({ portfolios, colors }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { spacing } = useTheme();

  const filter = searchParams.get('portfolio');

  return (
    <TableControlsContainer sx={{ overflowX: 'auto' }}>
      <Stack direction="row" alignItems="center" flexWrap="nowrap">
        {portfolios.map((portfolio, index) => (
          <TableControlButton
            key={portfolio.id}
            isActive={filter === portfolio.name}
            variant="tableFilter"
            onClick={() => setSearchParams({ portfolio: portfolio.name })}
            sx={{ flexShrink: 0 }}
            padding={spacing(1.5, 4, 1.5, 2)}
          >
            <ColorCircle
              sx={{ backgroundColor: colors[index % colors.length] }}
            />

            <TableButtonText isActive={filter === portfolio.name}>
              {portfolio.name}
            </TableButtonText>
          </TableControlButton>
        ))}
        <Spacer aria-hidden="true" tabIndex={-1} />
      </Stack>
    </TableControlsContainer>
  );
};

export default TableControls;

const TableControlsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing(5, 4, 6),
  justifyContent: 'space-between',
}));

const Spacer = styled(Box)({
  flexGrow: 1,
  height: '1px',
  minWidth: 14,
});

const ColorCircle = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  marginRight: theme.spacing(2.5),
}));
