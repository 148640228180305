import React from 'react';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from '~/App.tsx';
import { globalStyles } from '~/shared/styles/global';
import { theme } from '~/shared/styles/theme';
import {
  SENTRY_DSN,
  DATA_DOG_CLIENT_TOKEN,
  DATA_DOG_SERVICE_NAME,
} from '~/shared/config';
import { IS_DEV } from './shared/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '~/apollo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

Sentry.init({
  dsn: IS_DEV ? undefined : SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // This sets the sample rate at 0%. You may want to change it to 100% while
  // in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0,
  // If you're not already sampling the entire session, change the sample rate
  // to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 0,
});

datadogLogs.init({
  clientToken: DATA_DOG_CLIENT_TOKEN,
  site: 'us5.datadoghq.com',
  service: DATA_DOG_SERVICE_NAME,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

const enableMocking = async () => {
  if (import.meta.env.MODE !== 'test') {
    return;
  }

  const { worker } = await import('~/tests/mocks/browser');

  return worker.start();
};

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={globalStyles} />
          <CssBaseline enableColorScheme />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </React.StrictMode>,
  );
});
