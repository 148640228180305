import { Stack, styled, Typography, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logo } from '~/assets/images';

interface LogoAndTagProps {
  width?: CSSProperties['width'];
  margin?: CSSProperties['margin'];
}

const LogoAndTag = ({ width = 98, margin = '0 0 24px 0' }: LogoAndTagProps) => {
  const { palette } = useTheme();
  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  const content = (
    <Stack margin={margin}>
      <Logo src={logo} width={width} alt="Brighton Jones Logo" />
      <Typography variant="times" color={palette.custom.teal}>
        Live a richer life.
        <span
          style={{
            fontSize: 8,
            verticalAlign: 'super',
          }}
        >
          {'\u00AE'}
        </span>
      </Typography>
    </Stack>
  );

  if (isLoginPage) {
    return content;
  }

  return (
    <Link to="/dashboard" style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  );
};
export default LogoAndTag;

const Logo = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));
