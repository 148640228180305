const BudgetIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m4.715 11.214 5.571-5.571M5.18 6.571a.464.464 0 1 0 0-.928.464.464 0 0 0 0 .928zM9.822 11.215a.464.464 0 1 0 0-.929.464.464 0 0 0 0 .929z"
        stroke="#3E5B5C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 17.714V2.857A1.857 1.857 0 0 1 2.857 1h9.286A1.857 1.857 0 0 1 14 2.857v14.857l-2.786-1.857-1.857 1.857L7.5 15.857l-1.857 1.857-1.857-1.857L1 17.714z"
        stroke="#3E5B5C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BudgetIcon;
