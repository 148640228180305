import { useReactiveVar } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isLoadingNonProfitsVar } from '~/apollo';
import type {
  AdvisorNonProfit,
  GetAdvisorNonProfitsInput,
} from '~/graphql/gen/graphql';
import BackendService from '~/services/backend.service';
import { SnackbarVariant } from '~/shared/constants';
import useSnackbar from '~/shared/hooks/useSnackbar';
import type { FormInputs } from './GrantModal';

interface Props {
  advisorNonProfits: AdvisorNonProfit[];
  setAdvisorNonProfits: (nonProfits: AdvisorNonProfit[]) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
}

const AdvisorNonProfitAutoComplete = ({
  advisorNonProfits,
  setAdvisorNonProfits,
  inputValue,
  setInputValue,
}: Props) => {
  const [open, setOpen] = useState(false);
  const isLoadingNonProfits = useReactiveVar(isLoadingNonProfitsVar);
  const { createSnackNotice } = useSnackbar();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const handleSearch = async (input: GetAdvisorNonProfitsInput) => {
    try {
      isLoadingNonProfitsVar(true);

      const nonProfitsData = await BackendService.getAdvisorNonProfits(input);

      setAdvisorNonProfits(nonProfitsData.nonProfits ?? []);
    } catch (error) {
      createSnackNotice(
        'An error occurred. Check connection and try again in a few moments.',
        SnackbarVariant.Error,
      );
    } finally {
      isLoadingNonProfitsVar(false);
    }
  };

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        await handleSearch({
          searchTerm: inputValue,
        });
      }
    },
    [inputValue, handleSearch],
  );

  const getErrorMessage = (fieldName: keyof FormInputs) => {
    const error = errors[fieldName];

    if (error && 'message' in error && typeof error.message === 'string') {
      return error.message;
    }

    return '';
  };

  return (
    <Controller
      name="nonProfit"
      control={control}
      rules={{ required: 'Please select a nonprofit' }}
      render={({ field }) => (
        <Autocomplete<AdvisorNonProfit, false, false, false>
          {...field}
          id="nonprofit-autocomplete"
          sx={{ width: '90%', mb: 6 }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option?.EIN === value?.EIN}
          getOptionLabel={(option) => option?.title || ''}
          getOptionKey={(option) => option?.EIN}
          options={advisorNonProfits}
          loading={isLoadingNonProfits}
          onChange={(_, newValue) => {
            field.onChange(newValue);
            setInputValue(newValue?.title || '');
          }}
          value={field.value}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nonprofit"
              error={Boolean(errors.nonProfit)}
              helperText={getErrorMessage('nonProfit')}
              onKeyDown={handleKeyDown}
            />
          )}
        />
      )}
    />
  );
};

export default AdvisorNonProfitAutoComplete;
