import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { shouldNotForwardPropsWithKeys } from '~/shared/utils';
import { ROUTES } from '~/shared/constants';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink = ({ to, children }: NavLinkProps) => {
  const location = useLocation();

  const isAdvisorClientViews =
    to === ROUTES.ADVISOR.CLIENTS &&
    (location.pathname === ROUTES.ADVISOR.CLIENT_TRANSACTIONS ||
      location.pathname === ROUTES.ADVISOR.CLIENT_OVERVIEW ||
      location.pathname === ROUTES.ADVISOR.CLIENT_GRANTS);
  const isActive = location.pathname === to || isAdvisorClientViews;

  return (
    <NavLinkWrapper isActive={isActive}>
      <StyledLink to={to} isActive={isActive}>
        {children}
      </StyledLink>
      {isActive && <EndBlock />}
    </NavLinkWrapper>
  );
};

export default NavLink;

interface CustomProps {
  isActive: boolean;
}
const NavLinkWrapper = styled(Box, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<CustomProps>(['isActive']),
})<CustomProps>(({ theme, isActive }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2, 0, 2, isActive ? 4 : 0),
  border: isActive
    ? `1px solid ${theme.palette.custom.outlineGray}`
    : undefined,
  backgroundColor: isActive ? theme.palette.custom.lightGray : 'transparent',
  borderTopLeftRadius: theme.shape.customBorderRadius['35'],
  borderBottomLeftRadius: theme.shape.customBorderRadius['35'],
}));

const StyledLink = styled(Link, {
  shouldForwardProp: shouldNotForwardPropsWithKeys<CustomProps>(['isActive']),
})<CustomProps>(({ theme, isActive }) => ({
  fontWeight: isActive ? 800 : 400,
  color: theme.palette.text.darkBlue,
  fontSize: 12,
  textDecoration: 'none',
}));

const EndBlock = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  right: -1,
  width: 4,
  height: '36px',
  backgroundColor: theme.palette.common.black,
}));
