import { Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';

const EmptySearch = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      flex={1}
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Typography variant="h3" textAlign="center" mb={4.5} px={4}>
        No Results Found
      </Typography>
      <Typography
        fontSize="14px"
        maxWidth="350px"
        textAlign="center"
        mb={4.5}
        px={4}
      >
        We couldn&apos;t find any clients that match your search criteria.
        Please try again.
      </Typography>
    </Stack>
  );
};

export default EmptySearch;
