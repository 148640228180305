import { gql } from '../gen';

export const GET_TRANSACTIONS_QUERY = gql(/* GraphQL */ `
  query GetTransactions($input: TransactionsInput!) {
    getTransactions(input: $input) {
      totalTransactions
      transactions {
        id
        createdAt
        updatedAt
        transactedAt
        action
        description
        externalId
        totalMajorUnits
      }
    }
  }
`);
