import { Stack, Typography } from '@mui/material';
import { theme } from '~/shared/styles/theme';

const ErrorState = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight={300}
      flex={1}
    >
      <Typography
        variant="h3"
        mb={3}
        lineHeight={1}
        maxWidth="360px"
        textAlign="center"
        color={theme.palette.custom.darkTeal}
      >
        We&apos;re having trouble loading your Investment Overview right now.
      </Typography>
      <Typography
        maxWidth="300px"
        fontSize="15px"
        textAlign="center"
        lineHeight={1}
        color={theme.palette.custom.darkTeal}
      >
        Please reload the page or check back later. If the issue persists,
        please reach out to your advisory team
      </Typography>
    </Stack>
  );
};

export default ErrorState;
