import type { SxProps } from '@mui/material';
import Button from '@mui/material/Button/Button';
import { theme } from '~/shared/styles/theme';

interface Props {
  text: string;
  sx?: SxProps;
}

const CardButton = ({ text, sx }: Props) => {
  return (
    <Button
      variant="outlined"
      sx={{
        borderColor: theme.palette.custom.purple,
        color: theme.palette.custom.purple,
        '&:hover': {
          borderColor: theme.palette.custom.purple,
          color: theme.palette.custom.purple,
        },
        ...sx,
      }}
    >
      {text}
    </Button>
  );
};

export default CardButton;
