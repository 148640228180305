import { activeDAFIdVar, isLoggingInVar, clientVar } from '~/apollo';
import auth0Client from '~/auth0';
import useSnackbar from './useSnackbar';
import DataDogService from '~/services/datadog.service';
import BackendService from '~/services/backend.service';
import { checkIsAuth0Redirect } from '~/shared/utils';
import { LocalStorageKeys, SnackbarVariant } from '~/shared/constants';
import LocalStorageService from '~/services/localStorage.service';

const useAuth0 = () => {
  const { createSnackNotice } = useSnackbar();

  const handleLoggedInUser = async () => {
    const isFromRedirect = checkIsAuth0Redirect();
    if (isFromRedirect) {
      await auth0Client.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    try {
      const isAuthenticated = await auth0Client.isAuthenticated();
      if (isAuthenticated) {
        const storedDAFId = LocalStorageService.get(
          LocalStorageKeys.activeDAFId,
        );

        const client = await BackendService.loginOrRegister();
        const useLocalId = client.DAFAccounts.some(
          (account) => account.id === storedDAFId,
        );

        if (useLocalId) {
          activeDAFIdVar(storedDAFId);
        } else {
          activeDAFIdVar(client.DAFAccounts[0].id);
          LocalStorageService.set(
            LocalStorageKeys.activeDAFId,
            client.DAFAccounts[0].id,
          );
        }

        clientVar(client);
      }
    } catch (error) {
      createSnackNotice(
        'Unable to login. Please try again in a few moments.',
        SnackbarVariant.Error,
      );
      logout();
    } finally {
      isLoggingInVar(false);
    }
  };

  const logout = async () => {
    try {
      await auth0Client.logout({
        logoutParams: { returnTo: window.location.origin },
      });
      clientVar(null);
      activeDAFIdVar(null);
      LocalStorageService.delete(LocalStorageKeys.activeDAFId);
    } catch (error) {
      createSnackNotice(
        'Unable to logout. Please try again in a few moments.',
        SnackbarVariant.Error,
      );
      DataDogService.logError(error);
    }
  };

  return {
    handleLoggedInUser,
    logout,
  };
};

export default useAuth0;
