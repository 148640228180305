import { datadogLogs } from '@datadog/browser-logs';
import { IS_DEV } from '~/shared/constants';

interface ErrorLogOptions {
  context?: Record<string, unknown>;
  message?: string;
}

class DataDogService {
  static logError(error: unknown, options?: ErrorLogOptions) {
    if (IS_DEV) {
      datadogLogs.logger.setHandler('console');
    }

    const isInstanceOfError = error instanceof Error;
    const checkedError = isInstanceOfError ? error : undefined;
    let errorMessage = options?.message;

    if (!errorMessage && isInstanceOfError) {
      errorMessage = error.message;
    } else {
      errorMessage = 'An unknown error occurred';
    }

    datadogLogs.logger.error(errorMessage, options?.context, checkedError);
  }
}

export default DataDogService;
