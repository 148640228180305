const EmployeesIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 4.938a2.438 2.438 0 1 1 4.875 0 2.438 2.438 0 0 1-4.875 0zM6.938.875a4.062 4.062 0 1 0 0 8.125 4.062 4.062 0 0 0 0-8.125zm8.125 4.875a1.625 1.625 0 1 1 3.25 0 1.625 1.625 0 0 1-3.25 0zm1.624-3.25a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5zM.438 13.063a2.437 2.437 0 0 1 2.438-2.438H11a2.438 2.438 0 0 1 2.438 2.438v.181c0 .075-.006.15-.017.223a4.63 4.63 0 0 1-.851 2.18c-.89 1.228-2.58 2.29-5.633 2.29s-4.741-1.062-5.633-2.29a4.632 4.632 0 0 1-.85-2.18 3.248 3.248 0 0 1-.017-.223v-.181zm1.625.146v.011l.007.08c.063.503.252.982.55 1.392.529.728 1.683 1.62 4.319 1.62 2.635 0 3.789-.892 4.319-1.62.297-.41.486-.89.549-1.392l.007-.082v-.155A.812.812 0 0 0 11 12.25H2.875a.812.812 0 0 0-.813.813v.146zm14.626 3.104c-.956 0-1.74-.147-2.373-.387.258-.479.455-.987.587-1.515.435.164 1.014.277 1.786.277 1.818 0 2.564-.621 2.892-1.083a1.95 1.95 0 0 0 .354-.91l.003-.045a.406.406 0 0 0-.406-.4h-4.55a4.062 4.062 0 0 0-.731-1.625h5.281c1.122 0 2.032.91 2.032 2.031v.028a3.574 3.574 0 0 1-.66 1.867c-.686.96-1.971 1.761-4.215 1.761z"
        fill="#3E5B5C"
      />
    </svg>
  );
};

export default EmployeesIcon;
