import { Stack, styled, Typography } from '@mui/material';
import { tealBlobVertical } from '~/assets/images';

const NotFound = () => {
  return (
    <Container>
      <TealBlob src={tealBlobVertical} />
      <Title variant="h2">Something went wrong.</Title>
      <Copy lineHeight={1}>
        We’re having trouble connecting to the server. Please check your
        internet connection or try again later.
      </Copy>
    </Container>
  );
};

export default NotFound;

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  background: theme.palette.custom.lightGray,
  paddingLeft: theme.spacing(13),
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 123px)',
    paddingTop: theme.spacing(13),
    paddingLeft: theme.spacing(0),
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 117.5px)',
    paddingTop: theme.spacing(10),
  },
}));

const TealBlob = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0),
  right: theme.spacing(0),
  width: 417,
  zIndex: theme.zIndex.min,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    maxHeight: '50vh',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginLeft: 0,
    fontSize: 24,
    textAlign: 'center',
  },
}));

const Copy = styled(Typography)(({ theme }) => ({
  width: 430,
  lineHeight: 1,
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    maxWidth: 320,
    width: '90%',
    marginLeft: 0,
    textAlign: 'center',
  },
}));
