import { Box, Stack, styled } from '@mui/material';
import { CategoryType } from '../types';
import Label from './CategoryText';
import { TopLevelSubjectCode } from '~/shared/constants';
import { shouldNotForwardPropsWithKeys } from '~/shared/utils';
import { IoIosArrowDropdown } from 'react-icons/io';
import { useRef, useState } from 'react';
import SubCategories from './SubCategories';

export interface CategoryProps {
  category: CategoryType;
  topLevelSubjectCodes?: TopLevelSubjectCode[] | null;
  handleCategoryClick: (
    selectedSubjectCodes: TopLevelSubjectCode[],
    isDeselectClick: boolean,
  ) => Promise<void>;
}

const Category = ({
  category,
  topLevelSubjectCodes,
  handleCategoryClick,
}: CategoryProps) => {
  const categoryRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isActive =
    !!topLevelSubjectCodes &&
    topLevelSubjectCodes.some((code) => category.subjectCodes.includes(code));
  const showSubCategories = !!category.subCategories;

  return (
    <ImageContainer
      ref={categoryRef}
      isActive={isActive}
      image={category.image}
      onClick={() => handleCategoryClick(category.subjectCodes, isActive)}
      justifyContent={showSubCategories ? 'space-between' : 'center'}
    >
      {showSubCategories && isOpen && (
        <SubCategories
          categoryRef={categoryRef}
          isOpen={isOpen}
          handleClose={handleClose}
          handleCategoryClick={handleCategoryClick}
          subCategories={category.subCategories}
        />
      )}
      {showSubCategories && <SpaceBox />}
      {<Label label={category.label} />}
      {showSubCategories && (
        <SpaceBox onClick={handleDropdownClick}>
          <IoIosArrowDropdown
            size={20}
            style={{ rotate: isOpen ? '180deg' : '0deg' }}
          />
        </SpaceBox>
      )}
    </ImageContainer>
  );
};

export default Category;

const ImageContainer = styled(Box, {
  shouldForwardProp: shouldNotForwardPropsWithKeys(['image', 'isActive']),
})<{ image: string; isActive: boolean }>(({ image, isActive, theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '33%',
    minWidth: 142,
    height: 75,
    background: isActive ? theme.palette.custom.sageGreen : `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
    color: isActive ? theme.palette.common.black : theme.palette.common.white,
    '&:hover': {
      background: theme.palette.custom.sageGreen,
      color: theme.palette.common.black,
    },
    [theme.breakpoints.down('sm')]: {
      height: 52,
    },
  };
});

const SpaceBox = styled(Stack)({
  width: 60,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
