import { Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import type { GetClientDashboardQuery } from '~/graphql/gen/graphql';
import DashboardCard from '../../DashboardCard';
import SectionHeading from '../../SectionHeading';
import { theme } from '~/shared/styles/theme';
import CardButton from '../../CardButton';
import EmptyState from './EmptyState';

interface Props {
  data: GetClientDashboardQuery;
}

const TotalGrantsCard = ({ data }: Props) => {
  if (!data.getClientDashboard.grantsYTDMajorUnits) {
    return <EmptyState />;
  }

  return (
    <Grid item xs={12} md={6}>
      <DashboardCard sx={{ color: theme.palette.custom.darkTeal }}>
        <Stack
          direction="row"
          borderRadius="10px"
          justifyContent="space-between"
          mb={{ xs: 1, md: 8 }}
        >
          <SectionHeading>Total Grants</SectionHeading>
          <Link to="/grants">
            <CardButton text="View All" sx={{ fontSize: '11px' }} />
          </Link>
        </Stack>
        <Typography fontSize="20px" textAlign="center" lineHeight={1}>
          Your causes this year have received a total:
        </Typography>
        <Typography variant="h2" textAlign="center" mb={{ xs: 2 }}>
          {data.getClientDashboard.grantsYTDMajorUnits}
        </Typography>
      </DashboardCard>
    </Grid>
  );
};

export default TotalGrantsCard;
