import { gql } from '../gen';

export const GET_PORTFOLIOS_QUERY = gql(/* GraphQL */ `
  query GetPortfolios($input: PortfoliosInput!) {
    getPortfolios(input: $input) {
      accountBalanceMajorUnits
      portfolios {
        id
        updatedAt
        name
        dafWeight
        totalMajorUnits
        portfolioComponents {
          id
          name
          portfolioWeight
          totalMajorUnits
          portfolioComponentHoldings {
            id
            symbol
            description
            portfolioComponentWeight
            totalMajorUnits
          }
        }
      }
    }
  }
`);
