import { theme } from '~/shared/styles/theme';

const FavoriteIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  height = '28',
  width = '32',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <path
        d="M22.228.691c-2.797 0-5.245 1.234-6.771 3.318C13.93 1.925 11.482.691 8.685.691a8.302 8.302 0 0 0-5.934 2.525A8.733 8.733 0 0 0 .29 9.302c0 9.721 14.056 17.59 14.655 17.915a1.063 1.063 0 0 0 1.026 0c.599-.325 14.655-8.194 14.655-17.915a8.733 8.733 0 0 0-2.463-6.086A8.301 8.301 0 0 0 22.228.691zm-6.771 24.276C12.984 23.49 2.456 16.758 2.456 9.302a6.48 6.48 0 0 1 1.827-4.515 6.16 6.16 0 0 1 4.402-1.874c2.634 0 4.846 1.44 5.77 3.75.081.204.22.378.399.5a1.065 1.065 0 0 0 1.206 0c.178-.122.317-.296.399-.5.924-2.315 3.135-3.75 5.77-3.75a6.16 6.16 0 0 1 4.402 1.874 6.48 6.48 0 0 1 1.827 4.515c0 7.445-10.531 14.186-13.001 15.665z"
        fill={theme.palette.common.white}
      />
    </svg>
  );
};

export default FavoriteIcon;
