import { Components, Theme, tableCellClasses } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  variants: [
    {
      props: { variant: 'head' },
      style: ({ theme }) => {
        return {
          fontSize: '12px',
          color: theme.palette.custom.gray,
          [`&.${tableCellClasses.head}`]: {
            borderBottom: `1px solid ${theme.palette.custom.transparentGray}`,
          },
        };
      },
    },
    {
      props: { variant: 'body' },
      style: () => {
        return {
          fontSize: '12px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        };
      },
    },
  ],
};
