const CheckIcon: React.ElementType<JSX.IntrinsicElements['svg']> = ({
  ...props
}) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1.387 8.45 5.108 4.968 8.669-11.312"
        stroke="#fff"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
