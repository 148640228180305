import { useMutation, useReactiveVar } from '@apollo/client';
import {
  CardMedia,
  keyframes,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import FavoriteIcon from '~/assets/icons/FavoriteIcon';
import FavoriteFilledIcon from '~/assets/icons/FavoriteFilled';
import {
  CREATE_FAVORITE,
  DELETE_FAVORITE,
} from '~/graphql/mutations/nonProfits';
import { CARD_IMAGES, SnackbarVariant } from '~/shared/constants';
import { activeDAFIdVar, clientVar, isAdvisorVar } from '~/apollo';
import { Favorite, NonProfit } from '~/graphql/gen/graphql';
import DataDogService from '~/services/datadog.service';
import useSnackbar from '~/shared/hooks/useSnackbar';

interface HeaderProps {
  nonProfit: NonProfit;
}

const Header = ({ nonProfit }: HeaderProps) => {
  const client = useReactiveVar(clientVar);
  const isAdvisor = useReactiveVar(isAdvisorVar);
  const dafAccount = client?.DAFAccounts?.find(
    ({ id }) => id === activeDAFIdVar(),
  );
  const { createSnackNotice } = useSnackbar();
  const isFavorite = dafAccount?.favorites.some(
    ({ ein }) => ein === nonProfit.EIN,
  );
  const image =
    CARD_IMAGES[nonProfit.cardImage.subjectCode][nonProfit.cardImage.index];

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [createFavorite, { loading: favoriteLoading }] = useMutation(
    CREATE_FAVORITE,
    {
      onCompleted: (data) => {
        if (dafAccount?.id && data.createFavorite) {
          const newFavorite = data.createFavorite;
          const updatedFavorites = [...dafAccount.favorites, newFavorite];

          updateStateVars(updatedFavorites);
        }
      },
      update: (cache) => {
        // To handle resorting favorites and prevent moving favorites onClick,
        // we remove queries which may have the favorited charity in them so that
        // on the next query, they are arranged as expected. Manually updating the
        // parameterized getNonProfits query is not feasible.
        cache.evict({ fieldName: 'getNonProfits' });
        cache.gc();
      },
      onError: (error) => {
        createSnackNotice(
          'An error occurred. Check connection and try again in a few moments.',
          SnackbarVariant.Error,
        );
        DataDogService.logError(error);
      },
    },
  );

  const [deleteFavorite, { loading: deleteLoading }] = useMutation(
    DELETE_FAVORITE,
    {
      onCompleted: (data) => {
        if (dafAccount?.id && data.deleteFavorite) {
          const updatedFavorites = dafAccount.favorites.filter(
            ({ id }) => id !== data.deleteFavorite?.id,
          );
          updateStateVars(updatedFavorites);
        }
      },
      update: (cache) => {
        // Invalidate to present favorites appropriately, see above comment for more
        cache.evict({ fieldName: 'getNonProfits' });
        cache.gc();
      },
      onError: (error) => {
        createSnackNotice(
          'An error occurred. Check connection and try again in a few moments.',
          SnackbarVariant.Error,
        );
        DataDogService.logError(error);
      },
    },
  );

  const handleFavoriteClick = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();

      if (isFavorite) {
        const id = dafAccount?.favorites.find(
          ({ ein }) => ein === nonProfit.EIN,
        )?.id;

        if (!id) {
          return;
        }

        return deleteFavorite({
          variables: { id },
        });
      }

      if (!dafAccount?.id) {
        return;
      }

      const variables = {
        input: {
          ein: nonProfit.EIN,
          dafAccountId: dafAccount?.id,
          categories: nonProfit.categories.map(({ label, subjectCode }) => ({
            subjectCode,
            label,
          })),
        },
      };

      createFavorite({
        variables,
      });
    },
    [nonProfit, dafAccount, createFavorite],
  );

  const updateStateVars = useCallback(
    (updatedFavorites: Favorite[]) => {
      const updatedDAF = {
        ...dafAccount,
        favorites: updatedFavorites,
      };
      if (client && dafAccount) {
        const updatedUser = {
          ...client,
          DAFAccounts: client.DAFAccounts.map((account) =>
            account.id === dafAccount.id ? updatedDAF : account,
          ),
        };
        clientVar(updatedUser);
      }
    },
    [client, dafAccount],
  );

  if (isAdvisor) {
    return <Container image={image} />;
  }

  return (
    <Container image={image}>
      {favoriteLoading || deleteLoading ? (
        <AnimatedIconWrapper sx={{ cursor: 'not-allowed' }}>
          <FavoriteFilledIcon
            onClick={(e) => e.stopPropagation()}
            height={isSmall ? 19 : 28}
            width={isSmall ? 22 : 32}
          />
        </AnimatedIconWrapper>
      ) : (
        <>
          {!isFavorite && (
            <FavoriteIcon
              onClick={handleFavoriteClick}
              height={isSmall ? 19 : 28}
              width={isSmall ? 22 : 32}
            />
          )}
          {isFavorite && (
            <FavoriteFilledIcon
              onClick={handleFavoriteClick}
              height={isSmall ? 19 : 28}
              width={isSmall ? 22 : 32}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Header;

const Container = styled(CardMedia)(({ theme }) => ({
  display: 'flex',
  minHeight: '70px',
  padding: '10px 13px 0',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    minHeight: '45px',
  },
}));

const fadeInOut = keyframes`
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.05;
  }
`;

const AnimatedIconWrapper = styled('div')({
  animation: `${fadeInOut} 2s infinite`,
});
