import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
  Typography,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { PortfolioComponent } from '~/graphql/gen/graphql';
import { theme } from '~/shared/styles/theme';

interface Props {
  component?: PortfolioComponent;
}

const ComponentTable = ({ component }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  if (!component) {
    return null;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
        backgroundColor: isMobile
          ? theme.palette.custom.lightGray
          : theme.palette.common.white,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ padding: 0, ...(isMobile && { fontSize: '10px' }) }}
            >
              Symbol/Description
            </TableCell>
            <TableCell
              sx={{ padding: 0, ...(isMobile && { fontSize: '10px' }) }}
            >
              Value
            </TableCell>
            <TableCell
              sx={{ padding: 0, ...(isMobile && { fontSize: '10px' }) }}
            >
              %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {component.portfolioComponentHoldings.map((holding) => (
            <TableRow key={holding.id}>
              <TableCell>
                <Typography variant="body2" fontSize="11px">
                  {holding.symbol}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize="11px"
                  maxWidth="100px"
                  whiteSpace="normal"
                >
                  {holding.description}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" fontSize="11px">
                  {holding.totalMajorUnits}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" fontSize="11px">
                  {holding.portfolioComponentWeight *
                    component.portfolioWeight *
                    100}
                  %
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComponentTable;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3.5),
  borderBottom: 'none !important',
}));
