import { css } from '@mui/material';
import { theme } from '~/shared/styles/theme';
import {
  GothamLight,
  GothamBook,
  GothamMedium,
  GothamBold,
  GothamBoldItalic,
  GothamBookItalic,
  GothamLightItalic,
  GothamMediumItalic,
} from '~/assets/fonts/';

export const globalStyles = css`
  body,
  html,
  #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${theme.palette.common.white};
    font-family: 'Gotham', Times, 'Times New Roman', serif;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamLight}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBook}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamMedium}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBold}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamLightItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 300;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBookItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamMediumItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 500;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBoldItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
  }
`;
