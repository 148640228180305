import { Stack, CircularProgress, useMediaQuery, Theme } from '@mui/material';

const LoadingSpinner = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width={isMobile ? '100vw' : 'calc(100vw - 240px)'}
    >
      <CircularProgress />
    </Stack>
  );
};

export default LoadingSpinner;
