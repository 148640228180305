/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** Date custom scalar type */
  Date: { input: any; output: any };
};

export type AdvisorClientsInput = {
  advisorId: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AdvisorNonProfit = {
  __typename?: 'AdvisorNonProfit';
  EIN: Scalars['String']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  categories: Array<Category>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type CancelGrant = {
  __typename?: 'CancelGrant';
  id: Scalars['Int']['output'];
};

export type CancelGrantInput = {
  dafAccountId: Scalars['Int']['input'];
  grantId: Scalars['Int']['input'];
};

export type CardImage = {
  __typename?: 'CardImage';
  index: Scalars['Int']['output'];
  subjectCode: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  label: Scalars['String']['output'];
  subjectCode: Scalars['String']['output'];
};

export type Client = {
  __typename?: 'Client';
  DAFAccounts: Array<DafAccount>;
  balanceMajorUnits: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalClientId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type CreateGrantInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  amountMinorUnits: Scalars['BigInt']['input'];
  categories: Array<FavoriteCategory>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  currencyCodeISO4217: Scalars['String']['input'];
  dafAccountId: Scalars['Int']['input'];
  ein: Scalars['String']['input'];
  nonProfitName: Scalars['String']['input'];
  period: PeriodEnum;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  state: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type DafAccount = {
  __typename?: 'DAFAccount';
  accountName?: Maybe<Scalars['String']['output']>;
  externalAccountId?: Maybe<Scalars['String']['output']>;
  favorites: Array<Favorite>;
  id?: Maybe<Scalars['Int']['output']>;
  totalBalanceMajorUnits?: Maybe<Scalars['String']['output']>;
  totalGrantsMajorUnits?: Maybe<Scalars['String']['output']>;
  totalGrantsYTDMajorUnits?: Maybe<Scalars['String']['output']>;
};

export type Favorite = {
  __typename?: 'Favorite';
  ein: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type FavoriteCategory = {
  label: Scalars['String']['input'];
  subjectCode: Scalars['String']['input'];
};

export type FavoriteInput = {
  categories: Array<FavoriteCategory>;
  dafAccountId: Scalars['Int']['input'];
  ein: Scalars['String']['input'];
};

export type Favorites = {
  __typename?: 'Favorites';
  favorites: Array<Favorite>;
};

export type GetAdvisorNonProfitsInput = {
  searchTerm: Scalars['String']['input'];
};

export type GetAdvisorNonProfitsResult = {
  __typename?: 'GetAdvisorNonProfitsResult';
  nonProfits: Array<AdvisorNonProfit>;
};

export type GetClientDashboardInput = {
  dafAccountId: Scalars['Int']['input'];
};

export type GetClientDashboardResponse = {
  __typename?: 'GetClientDashboardResponse';
  accountBalanceMajorUnits?: Maybe<Scalars['String']['output']>;
  favoritesCount?: Maybe<Scalars['Int']['output']>;
  grantChartData: Array<Maybe<GrantChartData>>;
  grantsTotalMajorUnits?: Maybe<Scalars['String']['output']>;
  grantsYTDMajorUnits?: Maybe<Scalars['String']['output']>;
  recurringGrantsCount?: Maybe<Scalars['Int']['output']>;
  topFavoriteCategories: Array<Scalars['String']['output']>;
};

export type GetClientsResponse = {
  __typename?: 'GetClientsResponse';
  clients: Array<Client>;
  matchingClientCount: Scalars['Int']['output'];
};

export type GetClientsTotalsInput = {
  advisorId: Scalars['Int']['input'];
};

export type GetClientsTotalsResponse = {
  __typename?: 'GetClientsTotalsResponse';
  numberOfClients: Scalars['Int']['output'];
  totalAccountsBalanceMajorUnits?: Maybe<Scalars['String']['output']>;
  totalAccountsGrantsMajorUnits?: Maybe<Scalars['String']['output']>;
  totalAccountsGrantsYTDMajorUnits?: Maybe<Scalars['String']['output']>;
};

export type Grant = {
  __typename?: 'Grant';
  amountMajorUnits: Scalars['String']['output'];
  categories: Array<GrantCategory>;
  createdAt: Scalars['Date']['output'];
  currencyCodeISO4217: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  nonProfitName: Scalars['String']['output'];
  period: PeriodEnum;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type GrantCategory = {
  __typename?: 'GrantCategory';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  subjectCode: Scalars['String']['output'];
};

export type GrantChartData = {
  __typename?: 'GrantChartData';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type GrantDafInput = {
  dafAccountId: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelGrant: CancelGrant;
  createFavorite?: Maybe<Favorite>;
  createGrant: Grant;
  deleteFavorite?: Maybe<Favorite>;
  loginOrRegister: Client;
  updateGrant: Grant;
};

export type MutationCancelGrantArgs = {
  input: CancelGrantInput;
};

export type MutationCreateFavoriteArgs = {
  input: FavoriteInput;
};

export type MutationCreateGrantArgs = {
  input: CreateGrantInput;
};

export type MutationDeleteFavoriteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateGrantArgs = {
  input: UpdateGrantInput;
};

export type NonProfit = {
  __typename?: 'NonProfit';
  EIN: Scalars['String']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  ageOfNonProfit?: Maybe<Scalars['Int']['output']>;
  annualBudget?: Maybe<Scalars['BigInt']['output']>;
  cardImage: CardImage;
  categories: Array<Category>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type NonProfitsInput = {
  coordsString?: InputMaybe<Scalars['String']['input']>;
  dafAccountId?: InputMaybe<Scalars['Int']['input']>;
  pageNumber: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  subjectCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NonProfitsResult = {
  __typename?: 'NonProfitsResult';
  nonProfits: Array<NonProfit>;
  pagesRemaining: Scalars['Int']['output'];
};

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum PeriodEnum {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  dafWeight: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  portfolioComponents: Array<PortfolioComponent>;
  totalMajorUnits: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type PortfolioComponent = {
  __typename?: 'PortfolioComponent';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  portfolioComponentHoldings: Array<PortfolioComponentHolding>;
  portfolioWeight: Scalars['Float']['output'];
  totalMajorUnits: Scalars['String']['output'];
};

export type PortfolioComponentHolding = {
  __typename?: 'PortfolioComponentHolding';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  portfolioComponentWeight: Scalars['Float']['output'];
  symbol: Scalars['String']['output'];
  totalMajorUnits: Scalars['String']['output'];
};

export type PortfoliosInput = {
  clientId: Scalars['Int']['input'];
  dafAccountId: Scalars['Int']['input'];
};

export type PortfoliosResult = {
  __typename?: 'PortfoliosResult';
  accountBalanceMajorUnits: Scalars['String']['output'];
  portfolios: Array<Portfolio>;
};

export type Query = {
  __typename?: 'Query';
  getAdvisorNonProfits: GetAdvisorNonProfitsResult;
  getClientDashboard: GetClientDashboardResponse;
  getClients: GetClientsResponse;
  getClientsTotals?: Maybe<GetClientsTotalsResponse>;
  getGrantChartData?: Maybe<Array<GrantChartData>>;
  getGrants: Array<Maybe<Grant>>;
  getNonProfits: NonProfitsResult;
  getPortfolios: PortfoliosResult;
  getTransactions: TransactionsResult;
};

export type QueryGetAdvisorNonProfitsArgs = {
  input: GetAdvisorNonProfitsInput;
};

export type QueryGetClientDashboardArgs = {
  input: GetClientDashboardInput;
};

export type QueryGetClientsArgs = {
  input: AdvisorClientsInput;
};

export type QueryGetClientsTotalsArgs = {
  input: GetClientsTotalsInput;
};

export type QueryGetGrantChartDataArgs = {
  input: GrantDafInput;
};

export type QueryGetGrantsArgs = {
  input: GrantDafInput;
};

export type QueryGetNonProfitsArgs = {
  input: NonProfitsInput;
};

export type QueryGetPortfoliosArgs = {
  input: PortfoliosInput;
};

export type QueryGetTransactionsArgs = {
  input: TransactionsInput;
};

export enum StatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type Transaction = {
  __typename?: 'Transaction';
  action: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  totalMajorUnits: Scalars['String']['output'];
  transactedAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TransactionsInput = {
  dafAccountId: Scalars['Int']['input'];
  orderBy: OrderByEnum;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionsResult = {
  __typename?: 'TransactionsResult';
  totalTransactions: Scalars['Int']['output'];
  transactions: Array<Transaction>;
};

export type UpdateGrantInput = {
  amountMinorUnits?: InputMaybe<Scalars['BigInt']['input']>;
  dafAccountId: Scalars['Int']['input'];
  grantId: Scalars['Int']['input'];
  period?: InputMaybe<PeriodEnum>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginOrRegisterMutationVariables = Exact<{ [key: string]: never }>;

export type LoginOrRegisterMutation = {
  __typename?: 'Mutation';
  loginOrRegister: {
    __typename?: 'Client';
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    externalClientId: string;
    balanceMajorUnits: string;
    DAFAccounts: Array<{
      __typename?: 'DAFAccount';
      id?: number | null;
      accountName?: string | null;
      externalAccountId?: string | null;
      totalBalanceMajorUnits?: string | null;
      totalGrantsMajorUnits?: string | null;
      totalGrantsYTDMajorUnits?: string | null;
      favorites: Array<{ __typename?: 'Favorite'; id: number; ein: string }>;
    }>;
  };
};

export type CreateGrantMutationVariables = Exact<{
  input: CreateGrantInput;
}>;

export type CreateGrantMutation = {
  __typename?: 'Mutation';
  createGrant: {
    __typename?: 'Grant';
    id: number;
    createdAt: any;
    nonProfitName: string;
    startDate?: any | null;
    amountMajorUnits: string;
    currencyCodeISO4217: string;
    period: PeriodEnum;
    categories: Array<{
      __typename?: 'GrantCategory';
      id: number;
      subjectCode: string;
      label: string;
    }>;
  };
};

export type UpdateGrantMutationVariables = Exact<{
  input: UpdateGrantInput;
}>;

export type UpdateGrantMutation = {
  __typename?: 'Mutation';
  updateGrant: {
    __typename?: 'Grant';
    id: number;
    createdAt: any;
    nonProfitName: string;
    startDate?: any | null;
    amountMajorUnits: string;
    currencyCodeISO4217: string;
    period: PeriodEnum;
    categories: Array<{
      __typename?: 'GrantCategory';
      id: number;
      subjectCode: string;
      label: string;
    }>;
  };
};

export type CancelGrantMutationVariables = Exact<{
  input: CancelGrantInput;
}>;

export type CancelGrantMutation = {
  __typename?: 'Mutation';
  cancelGrant: { __typename?: 'CancelGrant'; id: number };
};

export type CreateFavoriteMutationVariables = Exact<{
  input: FavoriteInput;
}>;

export type CreateFavoriteMutation = {
  __typename?: 'Mutation';
  createFavorite?: { __typename?: 'Favorite'; id: number; ein: string } | null;
};

export type DeleteFavoriteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteFavoriteMutation = {
  __typename?: 'Mutation';
  deleteFavorite?: { __typename?: 'Favorite'; id: number } | null;
};

export type GetClientsQueryVariables = Exact<{
  input: AdvisorClientsInput;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  getClients: {
    __typename?: 'GetClientsResponse';
    matchingClientCount: number;
    clients: Array<{
      __typename?: 'Client';
      id: number;
      externalClientId: string;
      firstName: string;
      lastName: string;
      balanceMajorUnits: string;
      email: string;
      DAFAccounts: Array<{
        __typename?: 'DAFAccount';
        id?: number | null;
        accountName?: string | null;
        externalAccountId?: string | null;
        totalBalanceMajorUnits?: string | null;
        totalGrantsMajorUnits?: string | null;
        totalGrantsYTDMajorUnits?: string | null;
        favorites: Array<{ __typename?: 'Favorite'; id: number; ein: string }>;
      }>;
    }>;
  };
};

export type GetClientsTotalsQueryVariables = Exact<{
  input: GetClientsTotalsInput;
}>;

export type GetClientsTotalsQuery = {
  __typename?: 'Query';
  getClientsTotals?: {
    __typename?: 'GetClientsTotalsResponse';
    totalAccountsBalanceMajorUnits?: string | null;
    totalAccountsGrantsMajorUnits?: string | null;
    totalAccountsGrantsYTDMajorUnits?: string | null;
    numberOfClients: number;
  } | null;
};

export type GetClientDashboardQueryVariables = Exact<{
  input: GetClientDashboardInput;
}>;

export type GetClientDashboardQuery = {
  __typename?: 'Query';
  getClientDashboard: {
    __typename?: 'GetClientDashboardResponse';
    accountBalanceMajorUnits?: string | null;
    grantsTotalMajorUnits?: string | null;
    grantsYTDMajorUnits?: string | null;
    favoritesCount?: number | null;
    topFavoriteCategories: Array<string>;
    recurringGrantsCount?: number | null;
    grantChartData: Array<{
      __typename?: 'GrantChartData';
      label: string;
      value: number;
    } | null>;
  };
};

export type GetGrantsQueryVariables = Exact<{
  input: GrantDafInput;
}>;

export type GetGrantsQuery = {
  __typename?: 'Query';
  getGrants: Array<{
    __typename?: 'Grant';
    id: number;
    createdAt: any;
    nonProfitName: string;
    startDate?: any | null;
    amountMajorUnits: string;
    currencyCodeISO4217: string;
    period: PeriodEnum;
    categories: Array<{
      __typename?: 'GrantCategory';
      id: number;
      subjectCode: string;
      label: string;
    }>;
  } | null>;
};

export type GetGrantChartDataQueryVariables = Exact<{
  input: GrantDafInput;
}>;

export type GetGrantChartDataQuery = {
  __typename?: 'Query';
  getGrantChartData?: Array<{
    __typename?: 'GrantChartData';
    label: string;
    value: number;
  }> | null;
};

export type GetNonProfitsQueryVariables = Exact<{
  input: NonProfitsInput;
}>;

export type GetNonProfitsQuery = {
  __typename?: 'Query';
  getNonProfits: {
    __typename?: 'NonProfitsResult';
    pagesRemaining: number;
    nonProfits: Array<{
      __typename?: 'NonProfit';
      EIN: string;
      imageUrl?: string | null;
      title: string;
      description?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      employeeNumber?: string | null;
      annualBudget?: any | null;
      ageOfNonProfit?: number | null;
      link?: string | null;
      categories: Array<{
        __typename?: 'Category';
        subjectCode: string;
        label: string;
      }>;
      cardImage: {
        __typename?: 'CardImage';
        subjectCode: string;
        index: number;
      };
    }>;
  };
};

export type GetAdvisorNonProfitsQueryVariables = Exact<{
  input: GetAdvisorNonProfitsInput;
}>;

export type GetAdvisorNonProfitsQuery = {
  __typename?: 'Query';
  getAdvisorNonProfits: {
    __typename?: 'GetAdvisorNonProfitsResult';
    nonProfits: Array<{
      __typename?: 'AdvisorNonProfit';
      EIN: string;
      title: string;
      description?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      categories: Array<{
        __typename?: 'Category';
        subjectCode: string;
        label: string;
      }>;
    }>;
  };
};

export type GetPortfoliosQueryVariables = Exact<{
  input: PortfoliosInput;
}>;

export type GetPortfoliosQuery = {
  __typename?: 'Query';
  getPortfolios: {
    __typename?: 'PortfoliosResult';
    accountBalanceMajorUnits: string;
    portfolios: Array<{
      __typename?: 'Portfolio';
      id: number;
      updatedAt: any;
      name: string;
      dafWeight: number;
      totalMajorUnits: string;
      portfolioComponents: Array<{
        __typename?: 'PortfolioComponent';
        id: number;
        name: string;
        portfolioWeight: number;
        totalMajorUnits: string;
        portfolioComponentHoldings: Array<{
          __typename?: 'PortfolioComponentHolding';
          id: number;
          symbol: string;
          description: string;
          portfolioComponentWeight: number;
          totalMajorUnits: string;
        }>;
      }>;
    }>;
  };
};

export type GetTransactionsQueryVariables = Exact<{
  input: TransactionsInput;
}>;

export type GetTransactionsQuery = {
  __typename?: 'Query';
  getTransactions: {
    __typename?: 'TransactionsResult';
    totalTransactions: number;
    transactions: Array<{
      __typename?: 'Transaction';
      id: number;
      createdAt: any;
      updatedAt: any;
      transactedAt: any;
      action: string;
      description: string;
      externalId: string;
      totalMajorUnits: string;
    }>;
  };
};

export const LoginOrRegisterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginOrRegister' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginOrRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalClientId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balanceMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DAFAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalAccountId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalBalanceMajorUnits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalGrantsMajorUnits' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'totalGrantsYTDMajorUnits',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'favorites' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ein' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginOrRegisterMutation,
  LoginOrRegisterMutationVariables
>;
export const CreateGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonProfitName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCodeISO4217' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subjectCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGrantMutation, CreateGrantMutationVariables>;
export const UpdateGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonProfitName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCodeISO4217' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subjectCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGrantMutation, UpdateGrantMutationVariables>;
export const CancelGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CancelGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelGrantMutation, CancelGrantMutationVariables>;
export const CreateFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FavoriteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ein' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFavoriteMutation,
  CreateFavoriteMutationVariables
>;
export const DeleteFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFavorite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFavoriteMutation,
  DeleteFavoriteMutationVariables
>;
export const GetClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdvisorClientsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalClientId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'balanceMajorUnits' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'DAFAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'externalAccountId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'favorites' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ein' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'totalBalanceMajorUnits',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'totalGrantsMajorUnits',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'totalGrantsYTDMajorUnits',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingClientCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientsTotalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientsTotals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetClientsTotalsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getClientsTotals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalAccountsBalanceMajorUnits',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalAccountsGrantsMajorUnits',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'totalAccountsGrantsYTDMajorUnits',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfClients' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientsTotalsQuery,
  GetClientsTotalsQueryVariables
>;
export const GetClientDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetClientDashboardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getClientDashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountBalanceMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grantsTotalMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grantsYTDMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favoritesCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topFavoriteCategories' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringGrantsCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grantChartData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientDashboardQuery,
  GetClientDashboardQueryVariables
>;
export const GetGrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GrantDAFInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonProfitName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCodeISO4217' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subjectCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGrantsQuery, GetGrantsQueryVariables>;
export const GetGrantChartDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGrantChartData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GrantDAFInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGrantChartData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGrantChartDataQuery,
  GetGrantChartDataQueryVariables
>;
export const GetNonProfitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNonProfits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NonProfitsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getNonProfits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonProfits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'EIN' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjectCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cardImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjectCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'index' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employeeNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annualBudget' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageOfNonProfit' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagesRemaining' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNonProfitsQuery, GetNonProfitsQueryVariables>;
export const GetAdvisorNonProfitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdvisorNonProfits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetAdvisorNonProfitsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAdvisorNonProfits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonProfits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'EIN' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressLine2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjectCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAdvisorNonProfitsQuery,
  GetAdvisorNonProfitsQueryVariables
>;
export const GetPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfoliosInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountBalanceMajorUnits' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dafWeight' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalMajorUnits' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolioComponents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'portfolioWeight' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalMajorUnits' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'portfolioComponentHoldings',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'symbol' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'portfolioComponentWeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'totalMajorUnits',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfoliosQuery, GetPortfoliosQueryVariables>;
export const GetTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TransactionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalTransactions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalMajorUnits' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionsQuery,
  GetTransactionsQueryVariables
>;
