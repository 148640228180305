import { SxProps, Card, CardContent } from '@mui/material';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

const DashboardCard = ({ children, sx }: Props) => {
  return (
    <Card elevation={0} sx={{ height: '100%', ...sx }}>
      <CardContent sx={{ height: '100%' }}>{children}</CardContent>
    </Card>
  );
};

export default DashboardCard;
